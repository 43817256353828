import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import "../corePoints/CorePoints.scss";
import effortIcon from "../../../assets/images/corePoints/effortIcon.svg";
import levelIcon from "../../../assets/images/corePoints/levelIcon.svg";
import backpack from "../../../assets/images/corePoints/backpack.svg";
import streamingIcon from "../../../assets/images/corePoints/streamingIcon.svg";
import verticalIcon from "../../../assets/images/corePoints/line.svg";

const CorePoints = ({courseContent}) => {
  
  const data = [
    {
      icon: streamingIcon,
      tag: "Mode",
      desc: courseContent?.mode,
    },
    {
      icon: levelIcon,
      tag: "Skill Level",
      desc:  courseContent?.level,
    },
    {
      icon: effortIcon,
      tag: "Efforts",
      desc:  courseContent?.efforts,
    },
    {
      icon: backpack,
      tag: "Guided Projects",
      desc:  courseContent?.projectsGuided
    },
  ];

  return (
    <Box className="corePoints">
      <Box className="frameWrapper" sx={{m:{xs:"0px 16px 14px 16px",md:"0 32px 26px 32px"}}}>
        <Grid container spacing={1}>
          {data?.map((item, index) => (
            <Grid item xs={6} md={3}>
              <Box key={index} className="sectionWrapper">
                <Box className="section">
                  <Box component="img" src={item?.icon} sx={{ pr: "16px", width:{sm:"48px", xs:"32px"} }} />
                  <Box>
                    <Typography className="subtitle1">{item?.tag}</Typography>
                    <Typography className="subtitle2">{item?.desc}</Typography>
                  </Box>
                </Box>
                {index !== data?.length - 1 && (
                  <Box
                    component="img"
                    src={verticalIcon}
                    className="verticalIcon"
                  />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CorePoints;
