import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  // MenuItem,
  // Select,
  TextField,
  Typography,
} from "@mui/material";
import Cancel from "../../../assets/images/coursePreview/Cancel.svg";
import React, { useState, useRef } from "react";
import "./ApplyNowDialog.scss";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

function ApplyNowDialog(props) {
  const navigate = useNavigate();
  const recaptchaRef = useRef();

  const onClickHandler = (event, navigationPath) => {
    event.preventDefault();
    navigate(navigationPath);
    window.scrollTo(0, 0);
  };
  const { handleCloseDialog, courseContent, handleOpenDialog, source } = props;
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  // const [course, setCourse] = useState("");
  // const coursesOptions = [
  //   "Data Science",
  //   "Data Analytics",
  //   "Data Visualization",
  // ];
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [value, setValue] = React.useState("professional");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const getPageName = async () => {
    const { hostname, pathname } = window.location;

    // Extract the first part of the hostname and capitalize it
    const siteName = hostname.split('.')[0].charAt(0).toUpperCase() + hostname.split('.')[0].slice(1);

    // Process the pathname to create a descriptive format with "page" appended
    const pathSegments = pathname.split('/').filter(Boolean);
    
    if (pathSegments.length === 0) {
      return `The user clicked on the ${siteName}  Main page for talking to expert`;
    }

    const pageDescriptions = pathSegments.map(segment => {
      // Check if the segment is defined and not an empty string
      if (segment && typeof segment === 'string') {
        return segment.replace(/-/g, ' ').charAt(0).toUpperCase() + segment.slice(1) + " Page";
      }
      return ""; // Return an empty string if segment is invalid
    }).filter(Boolean); // Filter out any empty strings

    return `The user clicked through to the ${siteName} ${pageDescriptions.join(' -> ')} for talking to the expert`;

  };



  const handleDownload = async () => {
    const currentUrl = window.location.href;
    // console.log(currentUrl);
    const pageDescription = await getPageName();
    console.log("It is clicked");
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_LINK}/api/enrollNowForm`,
        {
          fullName: name,
          emailId: emailId,
          number: mobileNum,
          profession: value,
          course: courseContent.course || courseContent,
          captchaToken: recaptchaRef.current.getValue(),
          source: source,
          currentUrl,pageDescription
        }
      );
      setName("");
      setEmailId("");
      setMobileNum("");
      // setCourse('');
      handleCloseDialog();
      if (handleOpenDialog) handleOpenDialog();

      setTimeout(() => {
        setMessage("");
      }, 3000);
    } catch (error) {
      console.error(
        "Error subscribing:",
        error.response?.data?.message || error.message
      );
      setMessage(error.response.data.message);
    } finally {
      setLoading(false);
    }

    console.log(value, name, emailId, mobileNum);
  };

  const validation = () => {
    const mobileNumRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (isEmpty(name)) {
      setMessage("Please enter Full name");
      setLoading(false);
    } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
      setMessage("Please enter valid Email Id");
      return false;
    } else if (
      isEmpty(mobileNum) ||
      mobileNum.length !== 10 ||
      !mobileNumRegex.test(mobileNum)
    ) {
      setMessage("Please enter valid mobile number");
      return false;
    }
    // else if (isEmpty(course)) {
    //   setMessage("Please select course");
    //   return false;
    // }
    else {
      setMessage("");
      console.log("handleDownload");
      handleDownload();
      return true;
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }} className="curriculumWrapper">
        <Box
          className="curriculumContent"
          sx={{
            borderRadius: { md: "25px 25px 0px 0px", xs: "12px" },
          }}
        >
          <Box
            component="img"
            src={Cancel}
            sx={{ p: "16px 16px 0px", cursor: "pointer" }}
            onClick={handleCloseDialog}
          />
          <Box
            className="contentform"
            sx={{ p: { xs: "0 16px", md: "0 48px 32px" }, mt: "-10px" }}
          >
            <Typography className="curriculumHeader" sx={{ mb: "4px" }}>
              Contact us
            </Typography>
            <Typography className="curriculumSubHeader">
              Get in touch for guidance and offers
            </Typography>
            <Box className="formInput">
              <Typography className="formInputHeader">Full Name*</Typography>
              <TextField
                id="outlined-basic"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                className="formText"
              />
            </Box>
            <Box className="formInput">
              <Typography className="formInputHeader">Email ID*</Typography>
              <TextField
                id="outlined-basic"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                variant="outlined"
                fullWidth
                className="formText"
              />
            </Box>
            <Box className="formInput">
              <Typography className="formInputHeader">
                Mobile Number*
              </Typography>
              <TextField
                id="outlined-basic"
                value={mobileNum}
                onChange={(e) => setMobileNum(e.target.value)}
                variant="outlined"
                className="formText"
              />
            </Box>
            {/* <Box className="formInput">
              <Typography className="formInputHeader">
                Select Courses*
              </Typography>
              <Select
                id="demo-simple-select"
                fullWidth
                displayEmpty
                className="formText selectForm"
                renderValue={(selected) => {
                  if (selected === "") {
                    return <>--Select--</>;
                  }
                  return selected;
                }}
                value={course}
                onChange={(e) => setCourse(e.target.value)}
              >
                <MenuItem disabled value="">
                  --Select--
                </MenuItem>
                {coursesOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Box> */}
            <RadioGroup
              aria-label="occupation"
              name="occupation"
              value={value}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="professional"
                control={<Radio />}
                label="Working Professional"
              />
              <FormControlLabel
                value="student"
                control={<Radio />}
                label="Student"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
            {message && (
              <Typography className="freeTagline" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
            {/* // */}
            <Box paddingTop="10px">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                ref={recaptchaRef}
              />
            </Box>
            {/* // */}
            <Button
              variant="contained"
              className="contactButton"
              onClick={validation}
              disabled={loading}
            >
              {/* <span style={{ color: 'white' }}>{loading ? "Downloading..." : "Download"}</span> */}
              <span style={{ color: "white" }}>Sumbit</span>
            </Button>
            <Typography className="tagline">
              By filling this form, you agree to our{" "}
              <a
                className="terms"
                href="/terms"
                onClick={(e) => onClickHandler(e, "/terms")}
              >
                Terms and conditions
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ApplyNowDialog;
