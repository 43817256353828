import React, { useState } from 'react';
import "../questions/Questions.scss";
import arrowDropDown from "../../../assets/images/arrowDropdown.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import faqsData from "../data/faqs.json"; // Adjust the path as needed

const Questions = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      className="questionsSection fade-up"
      sx={{
        p: { md: "60px 16px 50px 16px", xs: "10px 16px" },
        backgroundColor: "black",
        color: "white",
      }}
    >
      <Typography
        className="title"
        sx={{ mb: "30px", fontSize: { md: "36px", xs: "20px" }, color: "#BD89FF" }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", m: "auto", alignItems: "center" }}>
        {faqsData.faqs.map((item, index) => (
          <Accordion
            key={index}
            elevation={0}
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
              border: "1px solid #A3A3A3",
              borderRadius: "10px",
              padding: "16px 26px",
              width: "100%",
              marginBottom: { xs: "10px", md: "16px" },
              maxWidth: "1000px",
              backgroundColor: "black",
            }}
          >
            <AccordionSummary
              expandIcon={
                <Box
                  component="img"
                  sx={{
                    height: { xs: "24px", md: "36px" },
                    mb: "9px",
                    filter: "brightness(0) invert(1)",
                  }}
                  src={arrowDropDown}
                  alt="expand icon"
                />
              }
              aria-controls={`panel${index}-content`}
              sx={{
                fontFamily: `"Poppins", sans-serif`,
                cursor: "pointer",
                height: "36px",
                padding: { xs: "0px 10px", sm: "0px 0px" },
                fontSize: { xs: "12px", md: "16px" },
                fontWeight: "600",
                color: "white",
              }}
            >
              {item.question}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                fontFamily: `"Outfit", sans-serif`,
                fontSize: { xs: "12px", md: "16px" },
                fontWeight: "400",
                lineHeight: "1.75",
                letterSpacing: "normal",
                textAlign: "left",
                color: "white",
              }}
            >
              {item.answer}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default Questions;