import { useState } from "react";
import { Box, Typography } from "@mui/material";
import "../frame/Frame.scss";

const Frame = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      className="frame"
      sx={{borderRadius:{md:"20px",xs:"12px"}}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box className="imageContainer">
        {isHovered ? (
          <img src={data.gif} alt="gif" className="image" />
        ) : (
          <img src={data.icon} alt="icon" className="image" />
        )}
      </Box>
      <Typography
        className="label"
        sx={{ fontSize: { xs: "16px", md: "20px" } }}
      >
        {data.label}
      </Typography>
      <Typography className="subText">{data.subText}</Typography>
      <Box
        component="img"
        src={data.icon}
        alt="icon"
        sx={{
          position: "absolute",
          bottom: {md:"-18px",xs:"-20px"},
          right: {md:"0px",xs:"-10px"},
          width: "96px",
          opacity: "0.2",
        }}
      />
    </Box>
  );
};

export default Frame;
