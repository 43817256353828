// Lower.jsx
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Logo1 from "../../../assets/images/ProjectsPage/ok.svg";
import Logo2 from "../../../assets/images/ProjectsPage/bulb.svg";
import Logo3 from "../../../assets/images/ProjectsPage/menu.svg";
import Logo4 from "../../../assets/images/ProjectsPage/shield.svg";
import Logo5 from "../../../assets/images/ProjectsPage/doc.svg";
import Logo1w from "../../../assets/images/ProjectsPage/ok_white.svg";
import Logo2w from "../../../assets/images/ProjectsPage/bulb_white.svg";
import Logo3w from "../../../assets/images/ProjectsPage/menu_white.svg";
import Logo4w from "../../../assets/images/ProjectsPage/shield_white.svg";
import Logo5w from "../../../assets/images/ProjectsPage/doc_white.svg";

import Meet1 from "../../../assets/images/ProjectsPage/meet1.png";
import Meet2 from "../../../assets/images/ProjectsPage/meet2.png";
import Meet3 from "../../../assets/images/ProjectsPage/meet3.png";
import Meet4 from "../../../assets/images/ProjectsPage/meet4.png";
// import Meet5 from "../../../assets/images/ProjectsPage/meet.svg";

const logos = [
  {
    id: 1,
    src: Logo1,
    hover: Logo1w,
    text: "Collaborative Community",
    image: Meet1,
  },
  { id: 2, src: Logo2, hover: Logo2w, text: "Expert Guidance", image: Meet2 },
  {
    id: 3,
    src: Logo3,
    hover: Logo3w,
    text: "Real-World Applications",
    image: Meet3,
  },
  {
    id: 4,
    src: Logo4,
    hover: Logo4w,
    text: "Recognition and Exposure",
    image: Meet4,
  },
  // {
  //   id: 5,
  //   src: Logo5,
  //   hover: Logo5w,
  //   text: "Comprehensive Learning Resources",
  //   image: Meet5,
  // },
];

function Lower() {
  const [activeId, setActiveId] = useState(1);

  const handleLogoClick = (id) => {
    setActiveId(id);
  };

  const activeLogo = logos.find((logo) => logo.id === activeId);

  return (
    <Box
      sx={{
        background: "white",
        padding: { xs: "20px", md: "10px" },
        display: "flex",

        flexDirection: "column",
        justifyContent: "center",
        justifyitems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          marginTop: "20px",
          paddingTop: { md: "20px" },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontWeight: "400",
            textAlign: "center",
            fontSize: { xs: "20px !important", md: "30px" },
          }}
        >
          Why Choose{" "}
          <span
            style={{
              color: "#FE7465",
              marginLeft: "5px",
              fontWeight: "600",
            }}
          >
             CourseVita for Your Projects
          </span>
        </Typography>
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontWeight: "300",
            textAlign: "center",
            fontSize: { xs: "14px", md: "18px" },
          }}
        >
          Find interesting projects and get started with an execution plan
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          padding: { xs: "0px", md: "25px" },
          marginLeft: { xs: "0px", md: "90px" },
          width: { xs: "100%", md: "90%" },
        }}
      >
        <Box
          sx={{
            marginTop: { xs: "10px", md: "0px" },
            padding: { xs: "5px", md: "20px" },
            borderRadius: "8px",
            marginRight: "20px",
            width: { xs: "90%", md: "80%" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          {logos.map((logo) => (
            <Box
              key={logo.id}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 2,
                paddingTop: { xs: "15px", md: "11px" },
                paddingBottom: { xs: "15px", md: "11px" },
                padding: "11px",
                width: "105%",
                borderRadius: "8px",
                transition: "background-color 0.3s",
                cursor: "pointer",
                backgroundColor: activeId === logo.id ? "#7234F7" : "white",
                "&:hover": {
                  backgroundColor: activeId === logo.id ? "#7234F7" : "white",
                },
              }}
              onClick={() => handleLogoClick(logo.id)}
            >
              <Box
                component="img"
                src={activeId === logo.id ? logo.hover : logo.src}
                alt={`Logo ${logo.id}`}
                sx={{
                  width: "10%",
                  height: 24,
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontWeight: "300",
                  marginLeft: 2,
                  color: activeId === logo.id ? "white" : "black",
                  transition: "color 0.3s",
                }}
              >
                {logo.text}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: { xs: "100%", md: "100%" },
            height: "auto",
          }}
        >
          {activeLogo && (
            <Box
              component="img"
              src={activeLogo.image}
              sx={{
                width: { xs: "100%", md: "90%" },
                // height: { xs: "250px", md: "83%" },
                marginBottom: "15px",
                borderRadius: "10px",
                marginRight: { xs: "5px", md: "0px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // backgroundImage: `url(${activeLogo.image})`,
                position: "relative",
                padding: "5px",
              }}
            >
              {/* <Typography
                sx={{
                  color: "white",
                  padding: { xs: "15px", md: "20px" },
                  textAlign: "center",
                  fontFamily: "Outfit",
                  fontWeight: "400",
                  width: { xs: "90%", md: "100%" },
                  position: "absolute",
                  bottom: "0",

                  fontSize: { xs: "14px", md: "16px" },
                }}
              >
                Receive insights and mentorship from industry experts and
                experienced professionals.
              </Typography> */}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Lower;
