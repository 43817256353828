// import {
//   Box,
//   Button,
//   Typography,
//   Dialog,
//   IconButton,
//   TextField,
// } from "@mui/material";
// import { useState } from "react";
// import share from "../../../assets/images/findYourCareer/share.svg";
// import "./CourseDescription.scss";
// import ShareDialogBox from "../../commonComponents/shareDialogBox/ShareDialogBox";

// import ApplyNowDialog from "../ApplynowDialog.jsx/ApplyNowDialog";


// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";

// const CourseDescription = ({ course }) => {
//   const [openShare, setOpenShare] = useState(false);
//   const [dialogOpen, setDialogOpen] = useState(false);


//   const tableContents = [
//     { key: "Duration", value: course?.duration },
//     { key: "Total fee", value: course?.totalFee },
//     { key: "Mode of study", value: course?.modeOfStudy },
//     { key: "Credential", value: course?.credential },
//     { key: "Location", value: course?.location },
//     { key: "Category", value: course?.category },
//     { key: "", value: "" },
//     { key: "Eligibility", value : course?.eligibilityCriteria},
    
//   ];


//   const handleKnowmore = (url) => {
//     // window.open(url, "_blank");
//     setDialogOpen(true);
//   };

//   const handleCloseDialog = () => {
//     setDialogOpen(false);
//   };

//   const handleShareClose = () => {
//     setOpenShare(false);
//   };

//   const handleShare = () => {
//     if (navigator.share) {
//       navigator
//         .share({
//           title: course.title,
//           text: `Check out this course: ${course.title} at ${course.instituteName}`,
//           url: `${window.location.origin}/findyourcareer/${course._id}`,
//         })
//         .catch(console.error);
//     } else {
//       setOpenShare(true);
//     }
//   };
  

//   return (
//     <Box className="courseDescription">
//       <Box
//         className="header"
//         sx={{
//           justifyContent: { md: "space-between" },
//           flexDirection: { xs: "column", md: "row" },
//           p: { md: "0px 23px 28px 48px", xs: "0px 26px 31px 26px" },
//         }}
//       >
//         <Box
//           sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
//         >
//           <Box
//             sx={{
//               p: { md: "25px", xs: "7px" },
//               mr: { md: "17px" },
//               width: { xs: "62px", md: "150px" },
//               height: { xs: "62px", md: "150px" },
//               mt: { md: "36px", xs: "20px" },
//               border: "1px solid #F1F1F1",
//               borderRadius: "8px",
//               display: { xs: "inline-block" },
//               backgroundColor: "#FFFFFF",
//               alignSelf: { xs: "center", md: "flex-start" },
//             }}
//           >
//             <Box
//               component="img"
//               src={course?.logoUrl}
//               sx={{
//                 height: { md: "100px", xs: "48px" },
//                 width: { md: "100px", xs: "48px" },
//               }}
//             />
//           </Box>
//           <Box
//             sx={{ textAlign: { xs: "center", md: "left" }, pt: { md: "87px" } }}
//           >
//             <Typography
//               className="course"
//               sx={{ fontSize: { xs: "14px", md: "20px" } }}
//             >
//               {course?.title}
//             </Typography>
//             <Typography
//               className="institute"
//               sx={{ fontSize: { xs: "12px", md: "14px" } }}
//             >
//               {course?.instituteName}
//             </Typography>
//           </Box>
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             gap: "12px",
//             flexDirection: { xs: "row", md: "column" },
//             alignSelf: { md: "flex-end", xs: "center" },
//             pt: { xs: "28px", sm: "0px" },
//           }}
//         >
//           <Button
//             onClick={() => handleKnowmore(course?.url)}
//             variant="contained"
//             className="knowmoreBtn"
//             sx={{ width: { xs: "115px", md: "192px" }, height: "36px" }}
//           >
//             Apply Now
//           </Button>

//           <Dialog
//               onClose={handleCloseDialog}
//               open={dialogOpen}
//               PaperProps={{
//                 sx: {
//                   width: { xs: "100%", md: "40%" },
//                   m: { xs: "16px", md: "0px" },
//                   borderRadius: "24px",
//                   backgroundColor: "#FFF",
//                   boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.08)",
//                   minHeight: "380px",
//                   fontFamily: '"Outfit", sans-serif !important',
//                   border: "1px solid #C7C7C7",
//                   pb: { xs: "10px", md: "10px" },
//                 },
//               }}
//             >
//               <DialogContent sx={{ p: "0" }}>
//                 <ApplyNowDialog
//                   handleCloseDialog={handleCloseDialog}
//                   courseTitle={course.title}
//                   courseLink={course.url}
//                 />
//               </DialogContent>
//             </Dialog>
//           <Button
//             variant="outlined"
//             className="shareBtn"
//             onClick={handleShare}
//             sx={{
//               width: "192px",
//               height: "36px",
//               display: { xs: "flex" },
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             share
//             <Box
//               component="img"
//               style={{ width: "18px", height: "18px", marginLeft: "10px" }}
//               src={share}
//               alt="share-icon"
//             />
//           </Button>
//         </Box>
//       </Box>
//       <Box
//         className="courseDescriptionContent"
//         sx={{ p: { md: "0px 23px 46px 48px", xs: "0px 26px 27px 26px" } }}
//       >
//         <Typography className="title">Overview</Typography>
        
//         <Typography className="text" sx={{ pb: { xs: "24px", md: "26px" } }}>
//           {course?.courseDescription}
//         </Typography>
//         <Typography className="title">Eligibility Criteria</Typography>
//         <Typography className="text" sx={{ pb: { xs: "26px", md: "20px" } }}>
//           {course?.eligibilityCriteria}
          
//         </Typography>
        

//         <Box
//           sx={{
//             border: " 1px solid #D0D0D0",
//             width: { xs: "100%", md: "650px" },
//             p: { md: "26px", xs: "22px 15px" },
//             borderRadius: { md: "20px", xs: "16px" },
//           }}
//         >
//           <table
//             style={{ width: "100%", borderCollapse: "collapse" }}
//             className="border-none "
//           >
//             <tbody>
//               {tableContents.map((item, index) => (
                
//                 <tr key={index}>
//                   <td style={{ padding: "10px" }} className="key">
//                     {item.key}
//                   </td>
//                   <td style={{ padding: "10px" }} className="value">
//                     {item.value}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </Box>
//       </Box>
//       <ShareDialogBox open={openShare} handleClose={handleShareClose} endpoint="" />
//     </Box>
//   );
// };

// export default CourseDescription;
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useState } from "react";
import share from "../../../assets/images/findYourCareer/share.svg";
import "./CourseDescription.scss";
import ShareDialogBox from "../../commonComponents/shareDialogBox/ShareDialogBox";
import ApplyNowDialog from "../ApplynowDialog.jsx/ApplyNowDialog";

const CourseDescription = ({ course }) => {
  const [openShare, setOpenShare] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Table contents for rendering course details
  const tableContents = [
    { key: "Duration", value: course?.duration },
    { key: "Total fee", value: course?.totalFee },
    { key: "Mode of study", value: course?.modeOfStudy },
    { key: "Credential", value: course?.credential },
    { key: "Location", value: course?.location },
    { key: "Category", value: course?.category },
    { key: "Eligibility", value: course?.eligibilityCriteria },
  ];

  // Handlers
  const handleKnowmore = (url) => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleShareClose = () => {
    setOpenShare(false);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: course?.title,
          text: `Check out this course: ${course?.title} at ${course?.instituteName}`,
          url: `${window.location.origin}/findyourcareer/${course?._id}`,
        })
        .catch(console.error);
    } else {
      setOpenShare(true);
    }
  };

  return (
    <Box className="courseDescription">
      {/* Header Section */}
      <Box
        className="header"
        sx={{
          justifyContent: { md: "space-between" },
          flexDirection: { xs: "column", md: "row" },
          p: { md: "0px 23px 28px 48px", xs: "0px 26px 31px 26px" },
        }}
      >
        {/* Logo and Title */}
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
          <Box
            sx={{
              p: { md: "25px", xs: "7px" },
              mr: { md: "17px" },
              width: { xs: "62px", md: "150px" },
              height: { xs: "62px", md: "150px" },
              mt: { md: "36px", xs: "20px" },
              border: "1px solid #F1F1F1",
              borderRadius: "8px",
              display: { xs: "inline-block" },
              backgroundColor: "#FFFFFF",
              alignSelf: { xs: "center", md: "flex-start" },
            }}
          >
            <Box
              component="img"
              src={course?.logoUrl}
              sx={{
                height: { md: "100px", xs: "48px" },
                width: { md: "100px", xs: "48px" },
              }}
              alt="Course Logo"
            />
          </Box>
          <Box sx={{ textAlign: { xs: "center", md: "left" }, pt: { md: "87px" } }}>
            <Typography
              className="course"
              sx={{ fontSize: { xs: "14px", md: "20px" } }}
            >
              {course?.title}
            </Typography>
            <Typography
              className="institute"
              sx={{ fontSize: { xs: "12px", md: "14px" } }}
            >
              {course?.instituteName}
            </Typography>
          </Box>
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            flexDirection: { xs: "row", md: "column" },
            alignSelf: { md: "flex-end", xs: "center" },
            pt: { xs: "28px", sm: "0px" },
          }}
        >
          <Button
            onClick={() => handleKnowmore(course?.url)}
            variant="contained"
            className="knowmoreBtn"
            sx={{ width: { xs: "115px", md: "192px" }, height: "36px" }}
          >
            Apply Now
          </Button>
          <Dialog
            onClose={handleCloseDialog}
            open={dialogOpen}
            PaperProps={{
              sx: {
                width: { xs: "100%", md: "40%" },
                m: { xs: "16px", md: "0px" },
                borderRadius: "24px",
                backgroundColor: "#FFF",
                boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.08)",
                minHeight: "380px",
                border: "1px solid #C7C7C7",
                pb: "10px",
              },
            }}
          >
            <DialogContent sx={{ p: "0" }}>
              <ApplyNowDialog
                handleCloseDialog={handleCloseDialog}
                courseTitle={course?.title}
                courseLink={course?.url}
              />
            </DialogContent>
          </Dialog>
          <Button
            variant="outlined"
            className="shareBtn"
            onClick={handleShare}
            sx={{
              width: "192px",
              height: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Share
            <Box
              component="img"
              style={{ width: "18px", height: "18px", marginLeft: "10px" }}
              src={share}
              alt="share-icon"
            />
          </Button>
        </Box>
      </Box>

      {/* Course Description Content */}
      <Box
        className="courseDescriptionContent"
        sx={{ p: { md: "0px 23px 46px 48px", xs: "0px 26px 27px 26px" } }}
      >
        <Typography className="title">Overview</Typography>
        <Typography className="text" sx={{ pb: { xs: "24px", md: "26px" } }}>
          {course?.courseDescription}
        </Typography>
        <Typography className="title">Eligibility Criteria</Typography>
        <Typography className="text" sx={{ pb: { xs: "26px", md: "20px" } }}>
          {course?.eligibilityCriteria}
        </Typography>

        {/* Course Details Table */}
        <Box
          sx={{
            border: "1px solid #D0D0D0",
            width: { xs: "100%", md: "650px" },
            p: { md: "26px", xs: "22px 15px" },
            borderRadius: { md: "20px", xs: "16px" },
          }}
        >
          <table
            style={{ width: "100%", borderCollapse: "collapse" }}
            className="border-none"
          >
            <tbody>
              {tableContents.map((item, index) => (
                <tr key={index}>
                  <td style={{ padding: "10px" }} className="key">
                    {item.key}
                  </td>
                  <td style={{ padding: "10px" }} className="value">
                    {item.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>

      {/* Share Dialog */}
      <ShareDialogBox open={openShare} handleClose={handleShareClose} endpoint="" />
    </Box>
  );
};

export default CourseDescription;
