import { Box, Typography, Button } from "@mui/material";
import "./lastLayer.scss";
import clockBlack from "../../../assets/images/workshop/clockBlack.svg";
import google from "../../../assets/images/workshop/google-icon logo.svg";
import star from "../../../assets/images/workshop/Star.svg";
import user from "../../../assets/images/workshop/User.svg";
import tick from "../../../assets/images/workshop/tick.svg";

const LastLayer = ({ data }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography width="700px" className="header-text-2-secondlayer-workshop">
        Register Before {data.registrationDeadline} To Unlock{" "}
        <span className="header-text-1-secondlayer-workshop">
          Bonuses Worth ₹{data.bonusesWorth}
        </span>
      </Typography>

      <Box className="box-wrapper-1-lastlayer-workshop">
        {data.bonuses.map((item, index) => (
          <Box className="box-wrapper-2-lastlayer-workshop" key={index}>
            <Box flex="2">
              <Typography className="text-1-lastlayer-workshop">
                Bonus#{index + 1}
              </Typography>
              <Typography className="text-2-lastlayer-workshop">
                {item.title}
              </Typography>
              <Typography className="text-3-lastlayer-workshop">
                {item.content}
              </Typography>
            </Box>
            {item.img && (
              <Box flex="1">
                <Box width="200px" component="img" src={item.img} alt={item.title}></Box>
              </Box>
            )}
          </Box>
        ))}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Button sx={{ width: "300px" }} className="btn-3-workshop-topLayer">
          Join Now
        </Button>
        <Typography sx={{ marginBottom: "5px" }} className="text-5-workshop">
          Offer ends in
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="3px"
      >
        <Box component="img" src={clockBlack}></Box>
        <Typography>{data.offerEndsIn}</Typography>
      </Box>

      <Typography className="header-text-2-secondlayer-workshop">
        <span className="header-text-1-secondlayer-workshop">
          Why we are best
        </span>{" "}
        at your learning Journey?
      </Typography>
      <Typography className="text-4-lastlayer-workshop">
        {data.description}
      </Typography>

      <Box display="flex" padding="20px 40px" gap="20px">
        <Box className="wrapper-1-lastlayer-workshop">
          <Box width="24px" component="img" src={google}></Box>
          <Box>
            <Typography className="text-5-lastlayer-workshop">
              Community Reviews
            </Typography>
            <Box display="flex" gap="3px">
              <Box width="15px" component="img" src={star}></Box>
              <Typography className="text-6-lastlayer-workshop">
                {data.reviews.rating} | {data.reviews.count} Ratings
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="wrapper-1-lastlayer-workshop">
          <Box width="24px" component="img" src={user}></Box>
          <Box>
            <Typography className="text-5-lastlayer-workshop">
              Learners
            </Typography>
            <Box display="flex" gap="3px">
              <Typography className="text-6-lastlayer-workshop">
                {data.learnersCount}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Typography className="header-text-2-secondlayer-workshop">
        Master In{" "}
        <span className="header-text-1-secondlayer-workshop">{data.masteryField}</span>
      </Typography>

      <Box className="card-wrapper-lastlayer-workshop">
        <Typography className="text-7-lastlayer-workshop">₹{data.price}</Typography>
        <Typography className="text-8-lastlayer-workshop">
          <span style={{ textDecorationLine: "line-through", color: "black" }}>
            ₹{data.originalPrice}
          </span>{" "}
          ({data.discount}% OFF)
        </Typography>
        <Typography className="text-9-lastlayer-workshop">
          {data.callToAction}
        </Typography>
        <Typography className="text-10-lastlayer-workshop">
          {data.exclusiveContent}
        </Typography>
        {data.features.map((item, index) => (
          <Box
            display="flex"
            alignSelf="start"
            gap="10px"
            key={index}
            marginTop="5px"
          >
            <Box component="img" src={tick}></Box>
            <Typography className="text-11-lastlayer-workshop">
              {item}
            </Typography>
          </Box>
        ))}
        <Button
          sx={{ width: "300px", alignSelf: "center" }}
          className="btn-3-workshop-topLayer"
        >
          Join Now
        </Button>
      </Box>
    </Box>
  );
};

export default LastLayer;
