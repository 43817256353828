import React from "react";
import { Box, Typography } from "@mui/material";
import Image2 from "../../../assets/images/ProjectsPage/2nd.svg";
import Image3 from "../../../assets/images/ProjectsPage/3rd.svg";




function Preend() {
  

  return (
    <Box
      sx={{
        marginBottom: "20px",
        marginTop: "20px",
        padding: { xs: "15px", md: "50px" },
        background: "white",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        gap: { xs: "5px", md: "20px" },
        "@media (max-width: 600px)": {
          "& img": {
            height: "auto", // Set a fixed height for mobile
            width: "20%",
          },
        },
      }}
    >
      <Typography
        sx={{
          fontFamily: "Outfit",
          fontWeight: "600",
          fontSize: { xs: "20px", md: "50px" },
          color: "#AEAEAE",
        }}
      >
        Innovate
      </Typography>
      <img src={Image2} alt="" />
      <Typography
        sx={{
          fontFamily: "Outfit",
          fontWeight: "600",
          fontSize: { xs: "20px", md: "50px" },
          color: "#AEAEAE",
        }}
      >
        Create
      </Typography>

      <img src={Image3} alt="" />
      <Typography
        sx={{
          fontFamily: "Outfit",
          fontWeight: "600",
          fontSize: { xs: "20px", md: "50px" },
          color: "#AEAEAE",
        }}
      >
        Shine
      </Typography>
    </Box>
  );
}

export default Preend;
