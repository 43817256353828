
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import StartLearning from "../blog/startLearning/StartLearning";
import Footer from "../commonComponents/footer/Footer";
import Navbar from "../commonComponents/navbar/Navbar";
import BlogsPreview from "./BlogsPreview";
import { Helmet } from "react-helmet";


const BlogsPreviewPage = (props) => {
  const { pathname } = useLocation();
  const { blogsContent } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
    {/* <Helmet>
          <title>{blogsContent.title}</title>
          <meta name="description" content={blogsContent.subtitle} />
          <meta name="keywords" content={ blogsContent.keywords ? blogsContent.keywords.join(", ") : "blog, trending, learning, courses, AI" }/>
    </Helmet> */}
       <Navbar/>
       <BlogsPreview />
       <StartLearning/>
       <Footer/>
    </>
  );
};

export default BlogsPreviewPage;
