// import { Box, Button, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import leftIcon from "../../assets/images/blogPosts/leftIcon.svg";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import "../blog/blogPosts/BlogPosts.scss";

// function BlogsPreview({ blogsContent }) {
//   const { pathname } = useLocation();
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [htmlContent, setHtmlContent] = useState("");
//   const [suggestedData, setSuggestedData] = useState([]);
//   const [blog, setBlog] = useState();

//   useEffect(()=>{
//     async function getBlog(){
//       try {
//         const response = await axios.get(`http://localhost:5002/api/blogs/${id}`);
//         if (response.status === 200) {
//           const data = response.data;
//           // setBlogs(response.data.results || response.data);
//           setBlog(data);
//           console.log(data);
//         }
//       } catch (error) {
//         console.error(error.message);
//       }
//     }
//     getBlog();
//   }, [])

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   // const decodedBlogTitle = decodeURIComponent(blogPreview);
//   const blogContent = blogsContent.find(
//     (blog) => blog._id 
//   );

//   useEffect(() => {
//     const fetchHtmlContent = async () => {
//       try {
//         const response = await fetch(blogContent.fullBlog);
//         const text = await response.text();
//         setHtmlContent(text);
//       } catch (error) {
//         console.error("Error fetching HTML content:", error);
//       }
//     };

//     fetchHtmlContent();
//   }, [blogContent]);

//   useEffect(() => {
//     setSuggestedData(
//       blogsContent.filter((item) => item !== blogContent).slice(0, 3)
//     );
//   }, [blogsContent, blogContent]);

//   const onClickHandler = (event, navigationPath) => {
//     event.preventDefault();
//     navigate(navigationPath);
//   };

//   const handleReadMore = (selected) => {
//     navigate(`/blogs/${selected._id}}`);
//   };

//   const truncate = (text, maxLength) => {
//     if (text.length > maxLength) {
//       return text.slice(0, maxLength) + "...";
//     }
//     return text;
//   };

//   return (
//     <>
//     {
//       blog!==null?
//       <div>
//         <Box
//         className="blogContentWrapper"
//         sx={{ m: { md: "36px 120px", xs: "24px 16px" }, color: "#000", backgroundColor: "#fff" }}
//       >
//         <Typography
//           className="blogHeader"
//           sx={{
//             fontSize: { md: "16px", xs: "14px" },
//             fontFamily: "Outfit, sans-serif",
//           }}
//         >
//           <span
//             className="blogHeaderContent"
//             onClick={(e) => onClickHandler(e, "/")}
//           >
//             Home
//           </span>
//           &gt;&gt;
//           <span
//             className="blogHeaderContent"
//             onClick={(e) => onClickHandler(e, "/blogs")}
//           >
//             Blogs
//           </span>
//           &gt;&gt;
//           {blog.title}
//         </Typography>
//         <Typography className="blogHeaderTech">
//           {/* {blogContent.blogCategory} */}
//           {blog?.blogCategory}
//         </Typography>
//         <Box
//           className="blogContent"
//           sx={{ p: { md: "32px 70px", xs: "24px" } }}
//         >
//           <Typography className="blogContentTitle">
//             {blog.title}
//           </Typography>
//           <Typography
//             className="blogContentTitle"
//             sx={{
//               color: { md: "#0F002E", xs: "#FF7262" },
//               fontSize: { xs: "14px", md: "16px" },
//               pb: "6px",
//             }}
//           >
//             {blogContent.subtitle}
//           </Typography>
//           <Typography
//             className="blogContentDate"
//             sx={{ mb: { md: "24px", xs: "18px" } }}
//           >
//             {blogContent.date}
//           </Typography>

//           <Typography className="content">{blogContent.blogDescription}</Typography>
//           <Box
//             component="img"
//             src={blogContent.headerImageUrl}
//             sx={{
//               width: "100%",
//               m: { md: "24px 0", xs: "20px 0" },
//               borderRadius: { xs: "8px", md: "12px" },
//             }}
//           />
//           <Box
//             className="dangerhtml"
//             dangerouslySetInnerHTML={{ __html: htmlContent }}
//           />
//         </Box>
//       </Box>
//       </div>:
//       <div>
//         <h1>blog not found</h1>
//       </div>
//     }
//       {/* <Box
//         className="blogContentWrapper"
//         sx={{ m: { md: "36px 120px", xs: "24px 16px" }, color: "#000", backgroundColor: "#fff" }}
//       >
//         <Typography
//           className="blogHeader"
//           sx={{
//             fontSize: { md: "16px", xs: "14px" },
//             fontFamily: "Outfit, sans-serif",
//           }}
//         >
//           <span
//             className="blogHeaderContent"
//             onClick={(e) => onClickHandler(e, "/")}
//           >
//             Home
//           </span>
//           &gt;&gt;
//           <span
//             className="blogHeaderContent"
//             onClick={(e) => onClickHandler(e, "/blogs")}
//           >
//             Blogs
//           </span>
//           &gt;&gt;
//           {blog.title}
//         </Typography>
//         <Typography className="blogHeaderTech">
//           {/* {blogContent.blogCategory} */}
//           {/* {blog?.blogCategory}
//         </Typography>
//         <Box
//           className="blogContent"
//           sx={{ p: { md: "32px 70px", xs: "24px" } }}
//         >
//           <Typography className="blogContentTitle">
//             {blog.title}
//           </Typography>
//           <Typography
//             className="blogContentTitle"
//             sx={{
//               color: { md: "#0F002E", xs: "#FF7262" },
//               fontSize: { xs: "14px", md: "16px" },
//               pb: "6px",
//             }}
//           >
//             {blogContent.subtitle}
//           </Typography>
//           <Typography
//             className="blogContentDate"
//             sx={{ mb: { md: "24px", xs: "18px" } }}
//           >
//             {blogContent.date}
//           </Typography>

//           <Typography className="content">{blogContent.blogDescription}</Typography>
//           <Box
//             component="img"
//             src={blogContent.headerImageUrl}
//             sx={{
//               width: "100%",
//               m: { md: "24px 0", xs: "20px 0" },
//               borderRadius: { xs: "8px", md: "12px" },
//             }}
//           />
//           <Box
//             className="dangerhtml"
//             dangerouslySetInnerHTML={{ __html: htmlContent }}
//           />
//         </Box>
//       </Box> */} 
//       <>
//         {suggestedData?.length === 0 ? (
//           <>New Blogs Coming soon</>
//         ) : (
//           <>
//             <Typography
//               className="header"
//               sx={{
//                 fontSize: { xs: "24px", md: "36px" },
//                 lineHeight: { xs: "30px", md: "54px" },
//                 color: "#FF7262",
//               }}
//             >
//               You may also like
//             </Typography>
//             <Typography
//               className="subheader"
//               sx={{ m: { md: 0, xs: "0 16px" } }}
//             >
//               Thoughtfully compiled information aimed at enriching your
//               understanding and broadening your scope of knowledge
//             </Typography>
//             {suggestedData.map((item, index) => {
//               return (
//                 <Box
//                   key={index} // Added key here
//                   className="blogWrapper"
//                   sx={{
//                     m: {
//                       md: "26px auto 26px auto",
//                       xs: "16px",
//                     },
//                     width: { md: "80%" },
//                   }}
//                 >
//                   <Box
//                     className="cardWrapper"
//                     sx={{
//                       flexDirection: { xs: "column-reverse", md: "row" },
//                       p: { sm: "20px 26px", xs: "16px" },
//                     }}
//                   >
//                     <Box
//                       className="contentWrapper"
//                       sx={{
//                         width: { md: "65%", xs: "100%" },
//                         pt: { xs: "16px", md: "0px" },
//                         display: "flex",
//                         flexDirection: "column",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <Box>
//                         <Typography
//                           className="title"
//                           sx={{
//                             color: { md: "#0F002E", xs: "#FF7262" },
//                             fontSize: { xs: "16px", md: "24px" },
//                           }}
//                         >
//                           {item.title}
//                         </Typography>
//                         <Typography
//                           sx={{
//                             color: { md: "#0F002E", xs: "#FF7262" },
//                             fontSize: { xs: "14px", md: "16px" },
//                             pb: "6px",
//                           }}
//                         >
//                           {item.subtitle}
//                         </Typography>
//                         <Typography className="date">{item.date}</Typography>
//                         <Typography
//                           className="blog"
//                           sx={{ fontSize: { xs: "14px", md: "16px" } }}
//                         >
//                           {truncate(item.blog, 200)}
//                         </Typography>
//                       </Box>
//                       <Button
//                         className="readMoreBtn"
//                         sx={{
//                           alignSelf: { md: "flex-start", xs: "center" },
//                           mt: { xs: "50px", md: "20px" },
//                         }}
//                         onClick={() => handleReadMore(item)}
//                         endIcon={<img src={leftIcon} alt="Read More Icon" />}
//                       >
//                         Read full blog
//                       </Button>
//                     </Box>
//                     <Box
//                       component="img"
//                       src={item.image}
//                       sx={{
//                         width: { md: "30%", xs: "100%" },
//                         minWidth: { lg: "442px", md: "390px" },
//                         maxHeight: "230px",
//                         borderRadius: { xs: "12px", md: "20px" },
//                         m: "auto",
//                       }}
//                     />
//                   </Box>
//                 </Box>
//               );
//             })}
//           </>
//         )}
//       </>
//     </>
//   );
// }

// export default BlogsPreview;



// import { Box, Button, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import leftIcon from "../../assets/images/blogPosts/leftIcon.svg";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import "../blog/blogPosts/BlogPosts.scss";

// function BlogsPreview({ blogsContent }) {
//   const { id } = useParams(); // Get blog ID from URL
//   const [blog, setBlog] = useState(null); // State to hold the blog data
//   const [suggestedData, setSuggestedData] = useState([]); // Suggested blogs
//   const navigate = useNavigate();
//   const { pathname } = useLocation();

//   // Fetch the blog by ID
//   useEffect(() => {
//     async function fetchBlogById() {
//       try {
//         // const id = blogId;
//         const response = await axios.get(`http://localhost:5002/api/blogs/${id}`);
//         if (response.status === 200) {
//           setBlog(response.data.results || response.data); // Set the blog data
//           console.log(response);
//         } else {
//           console.error("Error fetching the blog:", response.status);
//         }
//       } catch (error) {
//         console.error("Error fetching the blog:", error.message);
//       }
//     }
//     fetchBlogById();
//   }, []);

//   // Scroll to top when the path changes
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   // Set suggested blogs, excluding the current blog
//   useEffect(() => {
//     setSuggestedData(
//       blogsContent.filter((item) => item._id !== id).slice(0, 3)
//     );
//   }, [blogsContent, id]);

//   // Navigation handler for "Read Full Blog"
//   const handleReadMore = (blog) => {
//     navigate(`/blogs/${blog._id}`);
//   };

//   // Helper function to truncate text
//   // const truncate = (text, maxLength) => {
//   //   if (text.length > maxLength) {
//   //     return text.slice(0, maxLength) + "...";
//   //   }
//   //   return text;
//   // };

//   return (
//     <>
//       {blog !==null ? (
//         <Box
//           className="blogContentWrapper"
//           sx={{ m: { md: "36px 120px", xs: "24px 16px" }, color: "#000", backgroundColor: "#fff" }}
//         >
//           {/* Breadcrumb */}
//           <Typography
//             className="blogHeader"
//             sx={{
//               fontSize: { md: "16px", xs: "14px" },
//               fontFamily: "Outfit, sans-serif",
//             }}
//           >
//             <span onClick={() => navigate("/")}>Home</span> &gt;&gt;{" "}
//             <span onClick={() => navigate("/blogs")}>Blogs</span> &gt;&gt; {blog.title}
//           </Typography>

//           {/* Blog Content */}
//           <Typography className="blogHeaderTech">{blog.blogCategory}</Typography>
//           <Box
//             className="blogContent"
//             sx={{ p: { md: "32px 70px", xs: "24px" } }}
//           >
//             <Typography className="blogContentTitle">{blog.title}</Typography>
//             <Typography
//               className="blogContentDate"
//               sx={{ mb: { md: "24px", xs: "18px" } }}
//             >
//               {blog.date}
//             </Typography>
//             <Box
//               component="img"
//               src={blog.headerImageUrl}
//               sx={{
//                 width: "100%",
//                 m: { md: "24px 0", xs: "20px 0" },
//                 borderRadius: { xs: "8px", md: "12px" },
//               }}
//             />
//             <Typography className="content">{blog.blogDescription}</Typography>
//           </Box>

//           {/* Suggested Blogs */}
//           <Box>
//             <Typography
//               className="header"
//               sx={{
//                 fontSize: { xs: "24px", md: "36px" },
//                 lineHeight: { xs: "30px", md: "54px" },
//                 color: "#FF7262",
//               }}
//             >
//               You may also like
//             </Typography>
//             <Typography
//               className="subheader"
//               sx={{ m: { md: 0, xs: "0 16px" } }}
//             >
//               Thoughtfully compiled information aimed at enriching your
//               understanding and broadening your scope of knowledge
//             </Typography>
//             {suggestedData.map((item) => (
//               <Box
//                 key={item._id}
//                 className="blogWrapper"
//                 sx={{
//                   m: {
//                     md: "26px auto 26px auto",
//                     xs: "16px",
//                   },
//                   width: { md: "80%" },
//                 }}
//               >
//                 <Box
//                   className="cardWrapper"
//                   sx={{
//                     flexDirection: { xs: "column-reverse", md: "row" },
//                     p: { sm: "20px 26px", xs: "16px" },
//                   }}
//                 >
//                   <Box
//                     className="contentWrapper"
//                     sx={{
//                       width: { md: "65%", xs: "100%" },
//                       pt: { xs: "16px", md: "0px" },
//                       display: "flex",
//                       flexDirection: "column",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Box>
//                       <Typography
//                         className="title"
//                         sx={{
//                           color: { md: "#0F002E", xs: "#FF7262" },
//                           fontSize: { xs: "16px", md: "24px" },
//                         }}
//                       >
//                         {item.title}
//                       </Typography>
//                       <Typography
//                         sx={{
//                           color: { md: "#0F002E", xs: "#FF7262" },
//                           fontSize: { xs: "14px", md: "16px" },
//                           pb: "6px",
//                         }}
//                       >
//                         {item.subtitle}
//                       </Typography>
//                       <Typography className="date">{item.date}</Typography>
//                       <Typography
//                         className="blog"
//                         sx={{ fontSize: { xs: "14px", md: "16px" } }}
//                       >
//                         {/* {truncate(item.blogDescription, 200)} */}
//                       </Typography>
//                     </Box>
//                     <Button
//                       className="readMoreBtn"
//                       sx={{
//                         alignSelf: { md: "flex-start", xs: "center" },
//                         mt: { xs: "50px", md: "20px" },
//                       }}
//                       onClick={() => handleReadMore(item)}
//                       endIcon={<img src={leftIcon} alt="Read More Icon" />}
//                     >
//                       Read full blog
//                     </Button>
//                   </Box>
//                   <Box
//                     component="img"
//                     src={item.headerImageUrl}
//                     sx={{
//                       width: { md: "30%", xs: "100%" },
//                       minWidth: { lg: "442px", md: "390px" },
//                       maxHeight: "230px",
//                       borderRadius: { xs: "12px", md: "20px" },
//                       m: "auto",
//                     }}
//                   />
//                 </Box>
//               </Box>
//             ))}
//           </Box>
//         </Box>
//       ) : (
//         <Box>
//           <Typography>Loading blog content...</Typography>
//         </Box>
//       )}
//     </>
//   );
// }

// export default BlogsPreview;

import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import leftIcon from "../../assets/images/blogPosts/leftIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../blog/blogPosts/BlogPosts.scss";

function BlogsPreview() {
  const { id } = useParams(); // Get blog ID from URL
  const [blog, setBlog] = useState(null); // State to hold the blog data
  const [suggestedData, setSuggestedData] = useState([]); // Suggested blogs
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [blogsContent, setBlogsContent] = useState([]);

  const truncate = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };


  // Fetch the blog by ID
  useEffect(() => {
    async function fetchBlogById() {
      try {
        // Fetch all blogs from the backend
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_LINK}/api/blogs/getallblogs`);
        if (response.status === 200) {
          const { data } = response.data;
  
          // Find the specific blog by ID
          const specificBlog = data.find((blog) => blog._id === id);
          const remainingBlogs = data.filter((blog) => blog._id !== id);
  
          // Update states
          setBlog(specificBlog);
          setBlogsContent(remainingBlogs);
        }
      } catch (error) {
        console.error("Error fetching the blog:", error.message);
      }
    }
    fetchBlogById();
  }, [id]); // Added 'id' as a dependency to re-run if 'id' changes
  

  // Scroll to top when the path changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Set suggested blogs, excluding the current blog
  useEffect(() => {
    setSuggestedData(
      blogsContent?.filter((item) => item._id !== id).slice(0, 3)
    );
  }, [blogsContent, id]);

  // Navigation handler for "Read Full Blog"
  const handleReadMore = (blog) => {
    navigate(`/blogs/${blog._id}`);
  };

  return (
    <>
      {blog !== null ? (
        <Box
          className="blogContentWrapper"
          sx={{ m: { md: "36px 120px", xs: "24px 16px" }, color: "#000", backgroundColor: "#fff" }}
        >
          {/* Breadcrumb */}
          <Typography
            className="blogHeader"
            sx={{
              fontSize: { md: "16px", xs: "14px" },
              fontFamily: "Outfit, sans-serif",
            }}
          >
            <span onClick={() => navigate("/")}>Home</span> &gt;&gt;{" "}
            <span onClick={() => navigate("/blogs")}>Blogs</span> &gt;&gt; {blog.title}
          </Typography>

          {/* Blog Content */}
          <Typography className="blogHeaderTech">{blog.blogCategory}</Typography>
          <Box
            className="blogContent"
            sx={{ p: { md: "32px 70px", xs: "24px" } }}
          >
            <Typography className="blogContentTitle" >{blog.title}</Typography>
            <Typography
              className="blogContentDate"
              sx={{ mb: { md: "24px", xs: "18px" } }}
            >
              {blog.date}
            </Typography>
            {/* <Box
              component="img"
              src={blog.headerImageUrl}
              sx={{
                width: "100%",
                m: { md: "24px 0", xs: "20px 0" },
                borderRadius: { xs: "8px", md: "12px" },
              }}
            /> */}
            <Box
              component="img"
              src={blog.headerImageUrl}
              sx={{
                width: "100%",
                height: "600px",
                objectFit: "fill",
                m: { md: "24px 0", xs: "20px 0" },
                borderRadius: { xs: "8px", md: "12px" },
                height:{xs:"300px",md:"660px" },

              }}
            />


            {/* Render blogDescription HTML */}
            <Box
  className="content"
  dangerouslySetInnerHTML={{ __html: blog.blogDescription }}
  sx={{
    "& *": {
      marginBottom: "16px", // Consistent spacing for inner elements
    },
  
  }}
/>

          </Box>

          {/* Suggested Blogs */}
          <Box>
            <Typography
              className="header"
              sx={{
                fontSize: { xs: "20px !important", md: "36px" },
                lineHeight: { xs: "30px", md: "54px" },
                color: "#FF7262",
                marginTop:{xs:"14px"}
              }}
            >
              You may also like
            </Typography>
            <Typography
              className="subheader"
              sx={{ m: { md: 0, xs: "0 16px" } }}
            >
              Thoughtfully compiled information aimed at enriching your
              understanding and broadening your scope of knowledge
            </Typography>
            {suggestedData.map((item) => (
              <Box
                key={item._id}
                className="blogWrapper"
                sx={{
                  m: {
                    md: "26px auto 26px auto",
                    xs: "16px",
                  },
                  width: { md: "80%" },
                }}
              >
                <Box
                  className="cardWrapper"
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column-reverse", md: "row" },
                    p: { sm: "20px 26px", xs: "16px" },
                    maxWidth: "1200px", // Set a larger maximum width for the card
                    width: "100%",      // Ensure it takes up available space
                    m: "auto",
                  }}
                >
                  <Box
                    className="contentWrapper"
                    sx={{
                      width: { md: "70%", xs: "100%" }, // Increase width for content
                      pt: { xs: "16px", md: "0px" },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        className="title"
                        sx={{
                          color: { md: "#0F002E", xs: "#0F002E" },
                          fontSize: { xs: "16px", md: "24px" },
                          textAlign: 'left',
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: { md: "#0F002E", xs: "#0F002E" },
                          fontSize: { xs: "14px", md: "16px" },
                          pb: "6px",
                          
                        }}
                      >
                        {item.subTitle}
                      </Typography>
                      <Typography className="date">{item.date}</Typography>
                      <Typography
                        className="blog"
                        sx={{
                          fontSize: { xs: "14px", md: "16px" },
                          mb: { xs: "16px", md: "0px" },
                          paddingRight: "15px"
                        }}
                      >
                        {truncate(item.blogDemo, 200)}
                      </Typography>
                    </Box>
                    <Button
                      className="readMoreBtn"
                      sx={{
                        alignSelf: { md: "flex-start", xs: "center" },
                        mt: { xs: "50px", md: "20px" },
                      }}
                      onClick={() => handleReadMore(item)}
                      endIcon={<img src={leftIcon} alt="Read More Icon" />}
                    >
                      Read full blog
                    </Button>
                  </Box>
                  <Box
                    component="img"
                    src={item.headerImageUrl}
                    sx={{
                      width: { md: "40%", xs: "100%" }, 
                       // Adjust image container width
                      maxHeight: "230px",
                      objectFit: "cover",                 // Maintain aspect ratio for the image
                      borderRadius: { xs: "12px", md: "20px" },
                      m: "auto",
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography>Loading blog content...</Typography>
        </Box>
      )}
    </>
  );
}

export default BlogsPreview;
