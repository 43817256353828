// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   Tabs,
//   Tab,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import Cancel from "../../../assets/images/coursePreview/Cancel.svg";
// import React, { useState } from "react";
// import "./project-form.scss";
// import { useNavigate } from "react-router-dom";
// import { isEmpty } from "lodash";
// import axios from "axios";

// function Projectsform(props) {
//   const navigate = useNavigate();

//   const onClickHandler = (event, navigationPath) => {
//     event.preventDefault();
//     navigate(navigationPath);
//     window.scrollTo(0, 0);
//   };

//   const { handleCloseDialog, title } = props;
//   const [name, setName] = useState("");
//   const [emailId, setEmailId] = useState("");
//   const [mobileNum, setMobileNum] = useState("");
//   const [teamname, setTeamname] = useState("");
//   const [teamsize, setTeamsize] = useState(1);
//   const [message, setMessage] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [successMessage, setSuccessMessage] = useState(""); // State for success message
//   const [activeTab, setActiveTab] = useState(0);

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//     setMessage("");
//   };

//   const handleDownload = async () => {
//     setLoading(true);
//     try {
//       await axios.post(`${process.env.REACT_APP_BACKEND_LINK}/forms/projects`, {
//         projecttitle: title,
//         fullname: name,
//         email: emailId,
//         phone: mobileNum,
//         ...(activeTab === 1 && { teamname, teamsize }),
//       });
//       setName("");
//       setEmailId("");
//       setMobileNum("");
//       setTeamname("");
//       setTeamsize(1);
//       setSuccessMessage("Form submitted successfully!");
//       // delay for 3 seconds

//       setTimeout(() => {
//         setSuccessMessage(""); // Clear the success message after 3 seconds
//         handleCloseDialog();
//       }, 3000);
//     } catch (error) {
//       console.error(
//         "Error submitting form:",
//         error.response?.data?.message || error.message
//       );
//     } finally {
//       setLoading(false);
//     }
//   };

//   const validation = () => {
//     const mobileNumRegex = /^\d{10}$/;
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (isEmpty(name)) {
//       setMessage("Please enter Full name");
//       setLoading(false);
//     } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
//       setMessage("Please enter valid Email Id");
//       return false;
//     } else if (
//       isEmpty(mobileNum) ||
//       mobileNum.length !== 10 ||
//       !mobileNumRegex.test(mobileNum)
//     ) {
//       setMessage("Please enter valid mobile number");
//       return false;
//     } else if (activeTab === 1 && isEmpty(teamname)) {
//       setMessage("Please enter team name");
//       return false;
//     } else {
//       setMessage("");
//       handleDownload();
//       return true;
//     }
//   };

//   return (
//     <>
//       <Box
//         sx={{
//           maxWidth: "400px", // Adjust form width
//           margin: "0 auto", // Center the form
//         }}
//         className="curriculumWrapper"
//       >
//         <Box
//           className="curriculumContent"
//           sx={{
//             borderRadius: { md: "25px 25px 0px 0px", xs: "12px" },
//           }}
//         >
//           <Box
//             component="img"
//             src={Cancel}
//             sx={{ p: "16px 16px 0px", cursor: "pointer" }}
//             onClick={handleCloseDialog}
//           />
//           <Box
//             className="contentform"
//             sx={{ p: { xs: "0 16px", md: "0 48px 32px" }, mt: "-10px" }}
//           >
//             <Typography className="curriculumHeader" sx={{ mb: "4px" }}>
//               Join Waitlist Application
//             </Typography>

//             {/* Tabs for Individual and Team */}
//             <Tabs
//               value={activeTab}
//               onChange={handleTabChange}
//               centered
//               sx={{ marginBottom: "16px" }}
//             >
//               <Tab label="Individual" />
//               <Tab label="Team" />
//             </Tabs>

//             {/* Form Fields */}
//             <Box className="formInput">
//               <Typography className="formInputHeader">
//                 {activeTab === 0 ? "Full Name*" : "Full Name (Leader Name)*"}
//               </Typography>
//               <TextField
//                 id="outlined-basic"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 variant="outlined"
//                 className="formText"
//                 fullWidth
//               />
//             </Box>
//             <Box className="formInput">
//               <Typography className="formInputHeader">Email ID*</Typography>
//               <TextField
//                 id="outlined-basic"
//                 value={emailId}
//                 onChange={(e) => setEmailId(e.target.value)}
//                 variant="outlined"
//                 className="formText"
//                 fullWidth
//               />
//             </Box>
//             <Box className="formInput">
//               <Typography className="formInputHeader">
//                 Mobile Number*
//               </Typography>
//               <TextField
//                 id="outlined-basic"
//                 value={mobileNum}
//                 onChange={(e) => setMobileNum(e.target.value)}
//                 variant="outlined"
//                 className="formText"
//                 fullWidth
//               />
//             </Box>

//             {/* Conditional fields for Team */}
//             {activeTab === 1 && (
//               <>
//                 <Box className="formInput">
//                   <Typography className="formInputHeader">
//                     Team Name*
//                   </Typography>
//                   <TextField
//                     id="outlined-basic"
//                     value={teamname}
//                     onChange={(e) => setTeamname(e.target.value)}
//                     variant="outlined"
//                     className="formText"
//                     fullWidth
//                   />
//                 </Box>
//                 <Box className="formInput">
//                   <Typography className="formInputHeader">
//                     Team Size* (2-6)
//                   </Typography>
//                   <Select
//                     value={teamsize}
//                     onChange={(e) => setTeamsize(e.target.value)}
//                     className="formText"
//                     fullWidth
//                   >
//                     {[2, 3, 4, 5, 6].map((size) => (
//                       <MenuItem key={size} value={size}>
//                         {size}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </Box>
//               </>
//             )}

//             {message && (
//               <Typography className="freeTagline" sx={{ color: "red" }}>
//                 {message}
//               </Typography>
//             )}

//             {successMessage && (
//               <Typography className="freeTagline" sx={{ color: "green" }}>
//                 {successMessage}
//               </Typography>
//             )}

//             <Button
//               variant="contained"
//               className="contactButton"
//               onClick={validation}
//               disabled={loading}
//               fullWidth
//             >
//               <span style={{ color: "white" }}>
//                 {loading ? "Processing..." : "Join Now"}
//               </span>
//             </Button>
//           </Box>
//         </Box>
//       </Box>
//     </>
//   );
// }

// export default Projectsform;
import {
  Box,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  MenuItem,
  Select,
} from "@mui/material";
import Cancel from "../../../assets/images/coursePreview/Cancel.svg";
import React, { useState } from "react";
import "./project-form.scss";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import axios from "axios";

function Projectsform(props) {
  const navigate = useNavigate();

  const onClickHandler = (event, navigationPath) => {
    event.preventDefault();
    navigate(navigationPath);
    window.scrollTo(0, 0);
  };

  const { handleCloseDialog, title } = props;
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [teamname, setTeamname] = useState("");
  const [teamsize, setTeamsize] = useState(1);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setMessage("");
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_LINK}/forms/projects`, {
        projecttitle: title,
        fullname: name,
        email: emailId,
        phone: mobileNum,
        ...(activeTab === 1 && { teamname, teamsize }),
      });
      setName("");
      setEmailId("");
      setMobileNum("");
      setTeamname("");
      setTeamsize(1);
      setSuccessMessage("Form submitted successfully!");
      // delay for 3 seconds

      setTimeout(() => {
        setSuccessMessage(""); // Clear the success message after 3 seconds
        handleCloseDialog();
      }, 3000);
    } catch (error) {
      console.error(
        "Error submitting form:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const validation = () => {
    const mobileNumRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (isEmpty(name)) {
      setMessage("Please enter Full name");
      setLoading(false);
    } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
      setMessage("Please enter valid Email Id");
      return false;
    } else if (
      isEmpty(mobileNum) ||
      mobileNum.length !== 10 ||
      !mobileNumRegex.test(mobileNum)
    ) {
      setMessage("Please enter valid mobile number");
      return false;
    } else if (activeTab === 1 && isEmpty(teamname)) {
      setMessage("Please enter team name");
      return false;
    } else {
      setMessage("");
      handleDownload();
      return true;
    }
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: "400px", // Adjust form width
          margin: "0 auto", // Center the form
        }}
        className="curriculumWrapper"
      >
        <Box
          className="curriculumContent"
          sx={{
            borderRadius: { md: "25px 25px 0px 0px", xs: "12px" },
          }}
        >
          <Box
            component="img"
            src={Cancel}
            sx={{ p: "16px 16px 0px", cursor: "pointer" }}
            onClick={handleCloseDialog}
          />
          <Box
            className="contentform"
            sx={{ p: { xs: "0 16px", md: "0 48px 32px" }, mt: "-10px" }}
          >
            <Typography className="curriculumHeader" sx={{ mb: "4px" }}>
              Join Waitlist Application
            </Typography>

            {/* Tabs for Individual and Team */}
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              centered
              sx={{ marginBottom: "16px" }}
            >
              <Tab label="Individual" />
              <Tab label="Team" />
            </Tabs>

            {/* Form Fields */}
            <Box className="formInput">
              <Typography className="formInputHeader">
                {activeTab === 0 ? "Full Name*" : "Full Name (Leader Name)*"}
              </Typography>
              <TextField
                id="outlined-basic"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                className="formText"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#7233f7', // Set border color when focused
                    },
                  },
                }}
              />
            </Box>
            <Box className="formInput">
              <Typography className="formInputHeader">Email ID*</Typography>
              <TextField
                id="outlined-basic"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                variant="outlined"
                className="formText"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#7233f7', // Set border color when focused
                    },
                  },
                }}
              />
            </Box>
            <Box className="formInput">
              <Typography className="formInputHeader">
                Mobile Number*
              </Typography>
              <TextField
                id="outlined-basic"
                value={mobileNum}
                onChange={(e) => setMobileNum(e.target.value)}
                variant="outlined"
                className="formText"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#7233f7', // Set border color when focused
                    },
                  },
                }}
              />
            </Box>

            {/* Conditional fields for Team */}
            {activeTab === 1 && (
              <>
                <Box className="formInput">
                  <Typography className="formInputHeader">
                    Team Name*
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    value={teamname}
                    onChange={(e) => setTeamname(e.target.value)}
                    variant="outlined"
                    className="formText"
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#7233f7', // Set border color when focused
                        },
                      },
                    }}
                  />
                </Box>
                <Box className="formInput">
                  <Typography className="formInputHeader">
                    Team Size* (2-6)
                  </Typography>
                  <Select
                    value={teamsize}
                    onChange={(e) => setTeamsize(e.target.value)}
                    className="formText"
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#7233f7', // Set border color when focused
                        },
                      },
                    }}
                  >
                    {[2, 3, 4, 5, 6].map((size) => (
                      <MenuItem key={size} value={size}>
                        {size}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}

            {message && (
              <Typography className="freeTagline" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}

            {successMessage && (
              <Typography className="freeTagline" sx={{ color: "green" }}>
                {successMessage}
              </Typography>
            )}

            <Button
              variant="contained"
              className="contactButton"
              onClick={validation}
              disabled={loading}
              fullWidth
            >
              <span style={{ color: "white" }}>
                {loading ? "Processing..." : "Join Now"}
              </span>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Projectsform;
