import "./tips.scss";
import { useRef, useState, useEffect } from "react";
import ad1 from "../../../assets/images/mockGroupDiscussion/ad1.png";
import ad2 from "../../../assets/images/mockGroupDiscussion/ad2.png";
import icon1 from "../../../assets/images/mockGroupDiscussion/step1-icon.svg";
import icon2 from "../../../assets/images/mockGroupDiscussion/step2-icon.svg";
import icon3 from "../../../assets/images/mockGroupDiscussion/step3-icon.svg";
import icon4 from "../../../assets/images/mockGroupDiscussion/step4-icon.svg";
import icon5 from "../../../assets/images/mockGroupDiscussion/step5-icon.svg";
import icon6 from "../../../assets/images/mockGroupDiscussion/step6-icon.svg";
import icon7 from "../../../assets/images/mockGroupDiscussion/step7-icon.svg";
import icon8 from "../../../assets/images/mockGroupDiscussion/step8-icon.svg";
import icon9 from "../../../assets/images/mockGroupDiscussion/step9-icon.svg";
import icon10 from "../../../assets/images/mockGroupDiscussion/step9-icon.svg";
import tipImage1 from "../../../assets/images/mockGroupDiscussion/tipImage1.png";
import tipImage2 from "../../../assets/images/mockGroupDiscussion/tipImage2.png";
import tipImage3 from "../../../assets/images/mockGroupDiscussion/tipImage3.png";

const { Box, Typography, Button } = require("@mui/material");

const Tips = () => {
  const [activeSection, setActiveSection] = useState(null);

  //

  //

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  const section8Ref = useRef(null);
  const section9Ref = useRef(null);
  const section10Ref = useRef(null);

  useEffect(() => {
    const targetDiv = document.getElementById("secondSection");

    if (!targetDiv) return;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        threshold: 0, // 1 means need to complete visible
        //0 means any part need to be visible

        rootMargin: "-40% 0px -55% 0px",
        // root: targetDiv, // parent div in which the spyscroll is needed
      }
    );

    const sections = [
      section1Ref.current,
      section2Ref.current,
      section3Ref.current,
      section4Ref.current,
      section5Ref.current,
      section6Ref.current,
      section7Ref.current,
      section8Ref.current,
      section9Ref.current,
      section10Ref.current,
    ];

    sections.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  const handleClick = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box padding="20px 10px">
      <Typography className="header-text-1-tip">Tips To Improve</Typography>
      <Typography className="header-text-2-tip">
        Your Group Discussion Skills
      </Typography>
      <Box className="wrapper-tip">
        <Box className="section-1-tip">
          <Typography className="header-tip">Table of Contents</Typography>
          <Box>
            <ol class="content-tips">
              <li
                className={`content-tip ${
                  activeSection === "section1" ? "active-heading" : ""
                }`}
                onClick={() => handleClick(section1Ref)}
              >
                Practice Before the Discussion
              </li>
              <li
                className={`content-tip ${
                  activeSection === "section2" ? "active-heading" : ""
                }`}
                onClick={() => handleClick(section2Ref)}
              >
                Aware of Topics
              </li>
              <li
                className={`content-tip ${
                  activeSection === "section3" ? "active-heading" : ""
                }`}
                onClick={() => handleClick(section3Ref)}
              >
                Listen Actively
              </li>
              <li
                className={`content-tip ${
                  activeSection === "section4" ? "active-heading" : ""
                }`}
                onClick={() => handleClick(section4Ref)}
              >
                Engage with Others
              </li>
              <li
                className={`content-tip ${
                  activeSection === "section5" ? "active-heading" : ""
                }`}
                onClick={() => handleClick(section5Ref)}
              >
                Speak Clearly and Confidently
              </li>
              <li
                className={`content-tip ${
                  activeSection === "section6" ? "active-heading" : ""
                }`}
                onClick={() => handleClick(section6Ref)}
              >
                Respect Others' Opinions
              </li>
              <li
                className={`content-tip ${
                  activeSection === "section7" ? "active-heading" : ""
                }`}
                onClick={() => handleClick(section7Ref)}
              >
                Show Confidence
              </li>
              <li
                className={`content-tip ${
                  activeSection === "section8" ? "active-heading" : ""
                }`}
                onClick={() => handleClick(section8Ref)}
              >
                Manage Time
              </li>
              <li
                className={`content-tip ${
                  activeSection === "section9" ? "active-heading" : ""
                }`}
                onClick={() => handleClick(section9Ref)}
              >
                Do’s of the Group Discussion
              </li>
              <li
                className={`content-tip ${
                  activeSection === "section10" ? "active-heading" : ""
                }`}
                onClick={() => handleClick(section10Ref)}
              >
                Don’t’s of the Group Discussion
              </li>
            </ol>
          </Box>
        </Box>
        <Box position="relative" id="secondSection" className="section-2-tip">
          <Box className="progressLine"></Box>
          <Box ref={section1Ref} id="section1" className="steps-tip ">
            <Box position="relative">
              <Box
                className={`icon-container ${
                  activeSection === "section1" ? "active" : ""
                }`}
              >
                <Box component="img" src={icon1}></Box>
              </Box>
            </Box>
            <Box className="tip-content">
              <Typography className="step">Step-1</Typography>
              <Typography className="tip-heading">
                Practice Before the Discussion
              </Typography>
              <Typography className="tip-normal">
                Possessing quality knowledge in your domain and the ability to
                articulate that expertise are both equally important aspects of
                acing a group discussion. Delivering pre-learned knowledge
                demands a lot of practice in terms of speech presentation, using
                the right keywords, and being humble while countering
                conflicting perspectives. 
              </Typography>
              <Box width="100%" height="auto">
                <Box width="100%" component="img" src={tipImage1}></Box>
              </Box>
            </Box>
          </Box>
          <Box ref={section2Ref} id="section2" className="steps-tip">
            <Box position="relative">
              <Box
                className={`icon-container ${
                  activeSection === "section2" ? "active" : ""
                }`}
              >
                <Box component="img" src={icon2}></Box>
              </Box>
            </Box>
            <Box className="tip-content">
              <Typography className="step">Step-2</Typography>
              <Typography className="tip-heading">
                Awareness of Topics
              </Typography>
              <Typography className="tip-normal">
                Core subject knowledge is important. But often, the topics in a
                group discussion steer you away from your field of expertise to
                bring their applications into the foreground. So, let’s say, in
                a Machine Learning (ML) discussion, the topic could be the
                economic and sociological impact of self-driving cars. This
                would entail an understanding of self-driving cars in different
                contexts, such as ecology, politics, and culture, and linking it
                with technical knowledge about the subject.
              </Typography>
              <Typography className="tip-heading">
                Common Group Discussion Topics for Interviews:
              </Typography>
              <ul>
                <li>Role of Technology in Today's World</li>
                <li>Impact of Social Media</li>
                <li>Climate Change and Environmental Conservation</li>
                <li>Leadership Styles</li>
                <li>Remote Work</li>
                <li>Ethical Dilemmas in Business</li>
                <li>Gender Equality in the Workplace</li>
                <li>Education System Reforms</li>
                <li>Healthcare Reforms</li>
                <li>Artificial Intelligence and Job Displacement</li>
                <li>The impact of Coronavirus on the world economy</li>
                <li>Mental Health Awareness in the Workplace</li>
                <li>Diversity and Inclusion in Corporate Culture</li>
              </ul>
            </Box>
          </Box>
          <Box ref={section3Ref} id="section3" className="steps-tip">
            <Box position="relative">
              <Box
                className={`icon-container ${
                  activeSection === "section3" ? "active" : ""
                }`}
              >
                <Box component="img" src={icon3}></Box>
              </Box>
            </Box>
            <Box className="tip-content">
              <Typography className="step">Step-3</Typography>
              <Typography className="tip-heading">Listen Actively</Typography>
              <Typography className="tip-normal">
                Pay close attention to what others are saying. This not only
                shows respect but also helps you build on or counter their
                arguments effectively.
              </Typography>
              <Box width="100%" height="auto">
                <Box width="100%" component="img" src={tipImage2}></Box>
              </Box>
            </Box>
          </Box>
          <Box ref={section4Ref} id="section4" className="steps-tip">
            <Box position="relative">
              <Box
                className={`icon-container ${
                  activeSection === "section4" ? "active" : ""
                }`}
              >
                <Box component="img" src={icon4}></Box>
              </Box>
            </Box>
            <Box className="tip-content">
              <Typography className="step">Step-4</Typography>
              <Typography className="tip-heading">
                Engage with Others
              </Typography>
              <Typography className="tip-normal">
                Engage with other participants by asking questions, providing
                feedback, and building on their ideas. This can help demonstrate
                your active participation and engagement in the discussion.
              </Typography>
              <Box width="100%" height="auto">
                <Box width="100%" component="img" src={tipImage3}></Box>
              </Box>
            </Box>
          </Box>
          <Box ref={section5Ref} id="section5" className="steps-tip">
            <Box position="relative">
              <Box
                className={`icon-container ${
                  activeSection === "section5" ? "active" : ""
                }`}
              >
                <Box component="img" src={icon5}></Box>
              </Box>
            </Box>
            <Box className="tip-content">
              <Typography className="step">Step-5</Typography>
              <Typography className="tip-heading">
                Speak Clearly and Confidently
              </Typography>
              <Typography className="tip-normal">
                Speaking clearly and confidently can help you make your points
                effectively and be heard by other participants. Be concise and
                articulate your thoughts in a way that is easy to understand. 
              </Typography>
            </Box>
          </Box>
          <Box ref={section6Ref} id="section6" className="steps-tip">
            <Box position="relative">
              <Box
                className={`icon-container ${
                  activeSection === "section6" ? "active" : ""
                }`}
              >
                <Box component="img" src={icon6}></Box>
              </Box>
            </Box>
            <Box className="tip-content">
              <Typography className="step">Step-6</Typography>
              <Typography className="tip-heading">
                Respect Others' Opinions
              </Typography>
              <Typography className="tip-normal">
                Being respectful and professional can help build a positive
                atmosphere for the discussion. Respect other participants’
                opinions, avoid interrupting or talking over them and maintain a
                positive tone throughout the discussion.
              </Typography>
            </Box>
          </Box>
          <Box ref={section7Ref} id="section7" className="steps-tip">
            <Box position="relative">
              <Box
                className={`icon-container ${
                  activeSection === "section7" ? "active" : ""
                }`}
              >
                <Box component="img" src={icon7}></Box>
              </Box>
            </Box>
            <Box className="tip-content">
              <Typography className="step">Step-7</Typography>
              <Typography className="tip-heading">Show Confidence</Typography>
              <Typography className="tip-normal">
                Speak with confidence. Maintain good posture, make eye contact,
                and use a steady voice to convey your points assertively.
              </Typography>
            </Box>
          </Box>
          <Box ref={section8Ref} id="section8" className="steps-tip">
            <Box position="relative">
              <Box
                className={`icon-container ${
                  activeSection === "section8" ? "active" : ""
                }`}
              >
                <Box component="img" src={icon8}></Box>
              </Box>
            </Box>
            <Box className="tip-content">
              <Typography className="step">Step-8</Typography>
              <Typography className="tip-heading">Manage Time</Typography>
              <Typography className="tip-normal">
                Managing time effectively can help ensure that the discussion
                stays on track and that all important points are covered. Be
                mindful of the remaining time and encourage other participants
                to stay focused on the topic.
              </Typography>
            </Box>
          </Box>
          <Box ref={section9Ref} id="section9" className="steps-tip">
            <Box position="relative">
              <Box
                className={`icon-container ${
                  activeSection === "section9" ? "active" : ""
                }`}
              >
                <Box component="img" src={icon9}></Box>
              </Box>
            </Box>
            <Box className="tip-content">
              <Typography className="step">Step-9</Typography>
              <Typography className="tip-heading">
                Do’s of the Group discussion
              </Typography>
              <Typography className="tip-normal">
                The successful “do’s” of a GD include being prepared, listening
                actively, speaking clearly and confidently, respecting others,
                collaborating and building on ideas, taking notes, and
                summarising and concluding.
                <br />
                <br />
                Here are some successful “do’s” that can help make a group
                discussion more effective
              </Typography>
              <ul className="Do-tip">
                <li style={{ fontWeight: "600" }}>Listen Actively</li>
                <p>
                  A good listener is an essential soft skill to help you clear
                  the GD successfully. Listening actively means paying attention
                  to what others are saying, understanding their perspectives,
                  and asking questions for clarification. This will help you
                  better understand the topic and the points of view of the
                  other participants.
                </p>
                <li style={{ fontWeight: "600" }}>Initiate the Discussion</li>
                <p>
                  The first move is to start the discussion when you know the
                  topic and are confident. There are various forms of group
                  discussion, such as case studies, opinion-based, factual, etc.
                  Therefore, ensure you understand the type and begin it
                  confidently.
                </p>
                <li style={{ fontWeight: "600" }}>
                  Collaborate and Build on Ideas
                </li>
                <p>
                  Group discussions are meant to be collaborative, so try to
                  build on the ideas of others. If someone presents an idea, ask
                  questions or suggest ways to expand on it.
                </p>
              </ul>
            </Box>
          </Box>
          <Box ref={section10Ref} id="section10" className="steps-tip">
            <Box position="relative">
              <Box
                className={`icon-container ${
                  activeSection === "section10" ? "active" : ""
                }`}
              >
                <Box component="img" src={icon10}></Box>
              </Box>
            </Box>
            <Box className="tip-content">
              <Typography className="step">Step-10</Typography>
              <Typography className="tip-heading">
                Dont’s of the Group discussion
              </Typography>
              <Typography className="tip-normal">
                Along with the successful “tips” of a group discussion, there
                are also some “don’ts” that should be avoided. The successful
                “don’ts” include not dominating the conversation, not dismissing
                or belittling others’ opinions, not getting personal or
                emotional, not making assumptions, not ignoring non-verbal cues,
                not straying off-topic, and not interrupting or talking over
                others. Let’s understand some “don’ts” that can help make a
                group discussion more effective
              </Typography>
              <ul className="Dont-tip">
                <li style={{ fontWeight: "600" }}>
                  Don’t Dominate the Conversation
                </li>
                <p>
                  It’s important to allow everyone to have an opportunity to
                  speak and share their thoughts. Avoid dominating the
                  conversation or interrupting others.
                </p>
                <li style={{ fontWeight: "600" }}>
                  Don’t Dismiss or Disrespect other’s Opinions
                </li>
                <p>
                  The participant should respect everyone’s opinions and
                  perspectives, even if they disagree. Avoid dismissing or
                  belittling others’ opinions, as this can make them feel
                  undervalued and unimportant.
                </p>
                <li style={{ fontWeight: "600" }}>
                  Don’t Get Personal or Emotional
                </li>
                <p>
                  Keep the discussion professional and focused on the topic.
                  Avoid getting personal or emotional, leading to arguments and
                  unproductive discussions.
                </p>
                <li style={{ fontWeight: "600" }}>
                  Don’t Interrupt or Talk Over Others
                </li>
                <p>
                  Interrupting or talking over others can be seen as
                  disrespectful and can prevent productive discussion. Wait for
                  your turn to speak and allow others to finish their thoughts.
                  By avoiding these successful “dont’s” can ensure you that the
                  group discussion is productive, respectful, and effective.
                </p>
              </ul>
            </Box>
          </Box>
        </Box>
        <Box className="section-3-tip">
          <Box className="header-tip-ad">Blog Articles</Box>
          {[
            { id: 1, img: ad1, p: "Careers In VR Technology" },
            { id: 2, img: ad2, p: "AI in Data science" },
          ].map((item) => (
            <Box
              display="flex"
              gap="10px"
              marginTop="10px"
              padding="10px"
              backgroundColor="white"
            >
              <Box
                width="90px"
                height="60px"
                component="img"
                src={item.img}
              ></Box>
              <Box>
                <Typography className="text-ad">{item.p}</Typography>
                <Button className="ad-btn">Read Blog &gt;</Button>
              </Box>
            </Box>
          ))}
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button className="ad-btn-2">View More &gt;</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Tips;
