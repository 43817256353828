import "../mid1/mid1.scss";
import { Box, Typography } from "@mui/material";
import Image1 from "../../../assets/images/mockInterview/boy.png";
import Image2 from "../../../assets/images/mockInterview/girl.png";
import Image3 from "../../../assets/images/mockInterview/symbol1.svg";
import Image4 from "../../../assets/images/mockInterview/symbol2.svg";
import waterMark from "../../../assets/images/mockInterview/watermark-mid.svg";
import waterMark2 from "../../../assets/images/mockInterview/watermark-mid2.svg";
const Mid1 = () => {
  return (
    <Box>
      <Box position="relative" padding="107px 34px" className="top-wrapper">
        <Box
          className="watermark-mid w1"
          position="absolute"
          component="img"
          src={waterMark}
          width="269px"
          height="188px"
          right="0"
          top="0"
        ></Box>
        <Box
          className="card"
          sx={{ display: "flex", gap: "25px", alignItems: "center" }}
        >
          <Box position="relative">
            <Box>
              <Box
                className="img-mid"
                sx={{ position: "relative", maxWidth: "400px", zIndex: 1 }}
                component="img"
                src={Image1}
              ></Box>
            </Box>
            <Box
              className="sy s1"
              bottom="-20px"
              right="-42px"
              position="absolute"
              component="img"
              src={Image3}
            ></Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="26px"
          >
            <Typography className="header-text">
              Career Success Coaching
            </Typography>
            <Typography className="normal-text">
              Receive personalized guidance tailored to your career goals with
              our one-on-one career counseling. Get expert advice, actionable
              insights, and a clear path to success.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box position="relative" padding="107px 34px" className="">
        <Box
          className="watermark-mid w2"
          position="absolute"
          component="img"
          src={waterMark2}
          width="269px"
          height="188px"
          left="0"
          top="0"
        ></Box>
        <Box
          className="card"
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: "25px",
            alignItems: "center",
          }}
        >
          <Box position="relative">
            <Box>
              <Box
                className="img-mid"
                sx={{ position: "relative", maxWidth: "400px", zIndex: 1 }}
                component="img"
                src={Image2}
              ></Box>
            </Box>
            <Box
              className="sy s2"
              bottom="-45px"
              left="-56px"
              position="absolute"
              component="img"
              src={Image4}
            ></Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="26px"
          >
            <Typography className="header-text">
              Practice Interview Sessions
            </Typography>
            <Typography className="normal-text">
              Don’t waste countless hours searching for the right resources.
              Practice mock interviews and get personalized feedback directly
              from experienced interviewers.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Mid1;
