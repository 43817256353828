// import { Box, Modal, Pagination, Typography } from "@mui/material";
// import "./CoursesCatalog.scss"
// import ProgramCard from "../programCard/ProgramCard";
// import AddWrapper from "../AdWrapper";
// import { useCallback, useEffect, useState } from "react";
// import { paginate } from "../../../utils/utils";
// import CourseDescriptionPage from "../courseDescription";

// const CoursesCatalog = ({ courses }) => {
// 	const totalCount = courses.length;
// 	const cardsPerPage = 6;
// 	const totalPages = Math.ceil(totalCount / cardsPerPage);
// 	const [currentPage, setCurrentPage] = useState(1);
// 	const [selectedCourse, setSelectedCourse] = useState({ show: false, data: undefined });
// 	const [paginatedResults, setPaginatedResults] = useState([]);

// 	useEffect(() => {
// 		setPaginatedResults(paginate(courses, currentPage, cardsPerPage));
// 	}, [currentPage, courses]);

// 	useEffect(() => {
// 		window.addEventListener("resetPagination", () => setCurrentPage(1));
// 		return () => {
// 			window.removeEventListener("resetPagination", () => setCurrentPage(1));
// 		}
// 	}, [])

// 	const RenderCatalog = useCallback(() => {

// 		return (
// 			<Box className="coursesCatalogWrapper" id="coursesCatalog">
// 				<Box className="sectionWrapper helperText" sx={{px: { xs: "16px", md: "32px" }, gap: "25px"}}>{totalCount ? <div>{`Showing ${totalCount} result(s) matching your search`}</div> : <></>}</Box>
			
// 				<Box className="sectionWrapper" sx={{ flexDirection: { xs: "column", md: "row" }, px: { xs: "16px", md: "32px" }, gap: "25px", justifyContent: { xs: "center", md: "space-between" } }}>
// 					{courses?.length !== 0 ? <Box className="cardsDiv" sx={{ width: { xs: "100%", md: "72%" }, gap: { xs: "16px", md: "20px" } }}>
// 						{paginatedResults?.map((course) => {
// 							return (
// 								<ProgramCard key={course._id} course={course} />
// 							)
// 						})
// 						}
// 						{totalCount > cardsPerPage && (
// 							<Box
// 								sx={{
// 									display: "flex",
// 									justifyContent: "center",
// 									marginTop: "24px",
// 									marginBottom: { xs: "40px", md: "52px" }
// 								}}
// 							>
// 								<Pagination
// 									count={totalPages}
// 									page={currentPage}
// 									siblingCount={0}
// 									variant="outlined"
// 									shape="rounded"
// 									onChange={(_, page) => setCurrentPage(page)}
// 									sx={{
// 										"& .MuiPaginationItem-root": {
// 											backgroundColor: "#FFFFFF",
// 											color: "#000000",
// 										},
// 										"& .Mui-selected": {
// 											border: "1px #0F002E solid",
// 											backgroundColor: "#FFFFFF !important",
// 											color: "#000000 !important"
// 										},
// 										"& .MuiPaginationItem-previousNext.Mui-disabled": {
// 											backgroundColor: "#FFFFFF",
// 											color: "#000000",
// 										},
// 										"& .MuiPaginationItem-previousNext:not(.Mui-disabled)": {
// 											backgroundColor: "#000000",
// 											color: "#FFFFFF",
// 										},
// 									}}
// 								/>
// 							</Box>
// 						)}

// 					</Box> : <Box sx={{ width: "100%", textAlign: "center" }}>
// 						<Typography>Oops ! no courses matching your search</Typography>
// 					</Box>

// 					}
// 					<Box className="AddsDiv " sx={{ width: { xs: "100%", md: "28%" }, fontSize: "30px" }}>
// 						<AddWrapper />
// 					</Box>
// 				</Box>
// 			</Box>
// 		)
// 	}, [paginatedResults, currentPage, courses]);

// 	return (
// 		<Box>
// 			<Modal
// 				open={selectedCourse.show}
// 				onClose={() => setSelectedCourse({ show: false })}
// 				aria-labelledby="parent-modal-title"
// 				aria-describedby="parent-modal-description"
// 				style={{ overflowY: 'scroll', scroll: 'body' }}
// 			>
// 				<Box>
// 					<CourseDescriptionPage content={selectedCourse.data} handleClose={() => setSelectedCourse({ show: false })} />
// 				</Box>
// 			</Modal>
// 			<RenderCatalog></RenderCatalog>
// 		</Box>
// 	)
// }

// export default CoursesCatalog;
import { Box, Modal, Pagination, Typography } from "@mui/material";
import "./CoursesCatalog.scss";
import ProgramCard from "../programCard/ProgramCard";
import AddWrapper from "../AdWrapper";
import { useCallback, useEffect, useState } from "react";
import { paginate } from "../../../utils/utils";
import CourseDescriptionPage from "../courseDescription";

const CoursesCatalog = ({ courses }) => {
	const totalCount = courses.length;
	const cardsPerPage = 6;
	const totalPages = Math.ceil(totalCount / cardsPerPage);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedCourse, setSelectedCourse] = useState({ show: false, data: undefined });
	const [paginatedResults, setPaginatedResults] = useState([]);

	useEffect(() => {
		setPaginatedResults(paginate(courses, currentPage, cardsPerPage));
	}, [currentPage, courses]);

	useEffect(() => {
		window.addEventListener("resetPagination", () => setCurrentPage(1));
		return () => {
			window.removeEventListener("resetPagination", () => setCurrentPage(1));
		};
	}, []);

	const RenderCatalog = useCallback(() => {
		return (
			<Box className="coursesCatalogWrapper" id="coursesCatalog">
				<Box className="sectionWrapper helperText" sx={{ px: { xs: "16px", md: "32px" }, gap: "25px" }}>
					{totalCount ? <div>{`Showing ${totalCount} result(s) matching your search`}</div> : <></>}
				</Box>

				<Box
					className="sectionWrapper"
					sx={{ flexDirection: { xs: "column", md: "row" }, px: { xs: "16px", md: "32px" }, gap: "25px", justifyContent: { xs: "center", md: "space-between" } }}
				>
					{courses?.length !== 0 ? (
						<Box className="cardsDiv" sx={{ width: { xs: "100%", md: "72%" }, gap: { xs: "16px", md: "20px" } }}>
							{paginatedResults?.map((course) => {
								return <ProgramCard key={course._id} course={course} />;
							})}
							{totalCount > cardsPerPage && (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										marginTop: "24px",
										marginBottom: { xs: "40px", md: "52px" },
									}}
								>
									<Pagination
										count={totalPages}
										page={currentPage}
										siblingCount={0}
										variant="outlined"
										shape="rounded"
										onChange={(_, page) => setCurrentPage(page)}
										sx={{
											"& .MuiPaginationItem-root": {
												backgroundColor: "#FFFFFF",
												color: "#000000",
											},
											"& .Mui-selected": {
												border: "1px #0F002E solid",
												backgroundColor: "#FFFFFF !important",
												color: "#000000 !important"
												 
											},
											"& .MuiPaginationItem-previousNext.Mui-disabled": {
												backgroundColor: "#FFFFFF",
												color: "#000000",
											},
											"& .MuiPaginationItem-previousNext:not(.Mui-disabled)": {
												backgroundColor: "#000000",
												color: "#FFFFFF",
											},
										}}
									/>
								</Box>
							)}
						</Box>
					) : (
						<Box sx={{ width: "100%", textAlign: "center" }}>
							<Typography>Oops! No courses matching your search</Typography>
						</Box>
					)}
					<Box className="AddsDiv" sx={{ width: { xs: "100%", md: "28%" }, fontSize: "30px" }}>
						<AddWrapper />
					</Box>
				</Box>
			</Box>
		);
	}, [paginatedResults, currentPage, courses]);

	return (
		<Box>
			<Modal
				open={selectedCourse.show}
				onClose={() => setSelectedCourse({ show: false })}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
				style={{ overflowY: "scroll", scroll: "body" }}
			>
				<Box>
					<CourseDescriptionPage content={selectedCourse.data} handleClose={() => setSelectedCourse({ show: false })} />
				</Box>
			</Modal>
			<RenderCatalog />
		</Box>
	);
};

export default CoursesCatalog;
