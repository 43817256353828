// NotFoundPage.js
import React from "react";
import { useNavigate } from "react-router-dom";
import errorImage from "../../assets/images/Not-Found/404 Error page.png";
import HomeIcon from "./HomeIcon";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Redirect to the homepage
  };

  return (
    <div style={styles.container}>
      <img src={errorImage} alt="404 Error" style={styles.image} />
      <h1 style={styles.title}>Oops! Looks like you're lost.</h1>
      <p style={styles.message}>
        We couldn't find the page you're looking for.<br></br> But don't worry, let's get you back on track.
      </p>
      <button onClick={handleGoHome} style={styles.button}>
        <HomeIcon color="#fff" width={20} height={20} />
        <span style={styles.goBackText}>Back To Home Page</span>
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    padding: "2rem",
    textAlign: "center",
    backgroundColor: "#f0f8ff",
  },
  image: {
    width: "100%",
    maxWidth: "500px",
    height: "auto",
    marginBottom: "1rem",
  },
  title: {
    fontSize: "2.5rem",
    color: "#000",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  message: {
    fontSize: "1.2rem",
    color: "#2f2f2f",
    marginBottom: "2rem",
    maxWidth: "500px",
    padding: "0 1rem",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: "0.8rem 2rem",
    fontSize: "1rem",
    backgroundColor: "#7234f7",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  goBackText: {
    marginLeft: "8px", // Margin applied to separate the icon and text
  },
};

export default NotFoundPage;
