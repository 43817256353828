import "./join.scss";
import Image1 from "../../../assets/images/mockInterview/1st-join.png";
import a1 from "../../../assets/images/mockInterview/a1.svg";
import a2 from "../../../assets/images/mockInterview/a2.svg";
import a3 from "../../../assets/images/mockInterview/a3.svg";
import a4 from "../../../assets/images/mockInterview/a4.svg";
import Image2 from "../../../assets/images/mockInterview/2nd-join.png";
import { Box, Button, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { useState } from "react";

const Join = ({ targetJoinNow }) => {
  const [isIOnToOneframeVisible, setisIOnToOneframeVisible] = useState(false);

  const handleOneToOneButtonClick = () => {
    setisIOnToOneframeVisible(!isIOnToOneframeVisible);
  };

  const [isIMockInterviewframeVisible, setisIMockInterviewframeVisible] =
    useState(false);

  const handleMockInterviewButtonClick = () => {
    setisIMockInterviewframeVisible(!isIMockInterviewframeVisible);
  };

  const handleCloseClick = () => {
    setisIMockInterviewframeVisible(false);
    setisIOnToOneframeVisible(false);
  };
  return (
    <Box
      sx={{
        backgroundColor: "#FFF",
        margin: "0 20px",
        position: "relative",
        zIndex: 2,
      }}
    >
      <Typography
        ref={targetJoinNow}
        style={{ scrollMarginTop: "60px" }}
        className="header-join"
      >
        <span style={{ color: "#FE7465" }}>Join </span> Sessions
      </Typography>
      <Box
        className="wrapper-1"
        sx={{
          alignItems: "center",
          borderRadius: "20px",
          border: "1px solid #EDEDED",
          display: "flex",
          padding: "24px",
          gap: "24px",
          justifyContent: "space-between",
          margin: "5px 50px",

        }}
      >
        <Box
          className="img-join"
          sx={{ width: "326px", height: "200px" }}
          component="img"
          src={Image1}
        ></Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              className="free"
              sx={{
                width: "117px",
                padding: "5px 0",
                fontFamily: "Outfit",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                color: "black",
                borderRadius: "20px",
                border: "0.5px solid #000",
                textAlign: "center",
              }}
            >
              Free
            </Box>
            <AvatarGroup total={10} spacing="small" className="avg-G">
              <Avatar alt="a1" src={a1} className="avg-i" />
              <Avatar alt="a2" src={a2} className="avg-i" />
              <Avatar alt="a3" src={a3} className="avg-i" />
              <Avatar alt="a4" src={a4} className="avg-i" />
            </AvatarGroup>
          </Box>
          <Box className="text-content">
            <Typography
              fontFamily="Outfit"
              fontSize="24px "
              fontWeight="500"
              lineHeight="normal"
              paddingBottom="10px"
              sx={{
                paddingTop: "24px",
                fontSize: { xs: "18px !important" }
              }}

            >
              1 : 1 Career counselling
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              lineHeight="normal"
            >
              Our one-to-one career counseling offers personalized guidance to
              help you achieve your career goals, whether you're starting out,
              changing fields, or advancing in your current path.
            </Typography>
          </Box>
          <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={handleMockInterviewButtonClick}
              sx={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                color: "white",
                fontFamily: "Outfit",
                borderRadius: "8px",
                background: "#7234F7",
                padding: "10px 56px",
                marginTop: "5px",
                whiteSpace: "nowrap",  // Prevent text wrapping
                minWidth: "150px",  // Ensure the button has a minimum width to accommodate the text
                "&:hover": {
                  background: "rgba(114, 52, 247)",
                },
                textTransform: "none",
              }}
            >
              Register Now
            </Button>

            {isIOnToOneframeVisible && (
              <div className="iframe-popup">
                <span className="iframe-popup-close" onClick={handleCloseClick}>
                  &times;
                </span>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://hemanth-coursevita94.zohobookings.in/portal-embed#/237817000000034228"
                  frameborder="0"
                  allowfullscreen=""
                >
                  {" "}
                </iframe>
              </div>
            )}
          </Box>
        </Box>
      </Box>
      {/* ----------------- */}
      <Box
        className="wrapper-1"
        sx={{
          alignItems: "center",
          borderRadius: "20px",
          border: "1px solid #EDEDED",
          display: "flex",
          padding: "24px",
          gap: "24px",
          justifyContent: "space-between",
          margin: "5px 50px",

        }}
      >
        <Box
          className="img-join"
          sx={{ width: "326px", height: "200px" }}
          component="img"
          src={Image2}
        ></Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              className="free"
              sx={{
                width: "117px",
                padding: "5px 0",
                fontFamily: "Outfit",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                color: "black",
                borderRadius: "20px",
                border: "0.5px solid #000",
                textAlign: "center",
              }}
            >
              Free
            </Box>
            <AvatarGroup total={10} spacing="small" className="avg-G">
              <Avatar alt="a1" src={a1} className="avg-i" />
              <Avatar alt="a2" src={a2} className="avg-i" />
              <Avatar alt="a3" src={a3} className="avg-i" />
              <Avatar alt="a4" src={a4} className="avg-i" />
            </AvatarGroup>
            {/* <Box component="img" src={sideImage}></Box> */}
          </Box>
          <Box className="text-content">
            <Typography
              fontFamily="Outfit"
              fontSize="25px"
              fontWeight="500"
              lineHeight="normal"
              paddingBottom="10px"
              sx={{ paddingTop: "20px",
                fontSize:{xs:"18px !important"}
               }}
            >
            Mock Interview
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              lineHeight="normal"
            >
              Our mock interviews provide realistic practice to help you prepare
              for job interviews, offering feedback and strategies to improve
              your performance and boost your confidence.
            </Typography>
          </Box>
          <Box className="btn" sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={handleMockInterviewButtonClick}
              sx={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                color: "white",
                fontFamily: "Outfit",
                borderRadius: "8px",
                background: "#7234F7",
                padding: "10px 56px",
                marginTop: "5px",
                whiteSpace: "nowrap",  // Prevent text wrapping
                minWidth: "150px",  // Ensure the button has a minimum width to accommodate the text
                "&:hover": {
                  background: "rgba(114, 52, 247)",
                },
                textTransform: "none",
              }}
            >
              Register Now
            </Button>

            {isIMockInterviewframeVisible && (
              <div className="iframe-popup">
                <span className="iframe-popup-close" onClick={handleCloseClick}>
                  &times;
                </span>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://hemanth-coursevita94.zohobookings.in/portal-embed#/237817000000044008"
                  frameborder="0"
                  allowfullscreen=""
                >
                  {" "}
                </iframe>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Join;
