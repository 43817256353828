import Privacy from "./Privacy";

import Footer from "../commonComponents/footer/Footer";
import Navbar from "../commonComponents/navbar/Navbar";
import Helmet from "react-helmet";
const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Privacy Policy" />
        <meta name="keywords" content="privacy, policy" />
      </Helmet>

      <Navbar />
      <Privacy />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
