import "./interest.scss";
import { Box, Button, Typography, Dialog, DialogContent } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import calendar from "../../../assets/images/ProjectsPage/calendar.svg";
import thubmsUp from "../../../assets/images/ProjectsPage/ThumbsUp.svg";
import cancel from "../../../assets/images/ProjectsPage/cancel.svg";

import ApplyNowDialog from "../../commonComponents/ApplynowDialog.jsx/ApplyNowDialog";
import Navbar from "../../commonComponents/navbar/Navbar";
import Footer from "../../commonComponents/footer/Footer";
import { Helmet } from "react-helmet";

import dataNew from "../../../global/projects.json";

// console.log(dataNew);

const Interest = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);

  const { interest_type } = useParams();

  // const dataToDisplayfn = (interest_type, dataNew) => {
  //   return dataNew.filter((item) => item.id === interest_type);
  // };

  let dataToDisplay = dataNew.filter((item) => {
    // console.log(item.id);
    // console.log(Number(interest_type));
    return item.id === Number(interest_type);
  });

  dataToDisplay = dataToDisplay[0];

  // ----------------
  console.log(dataToDisplay);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/projects");
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog2 = () => {
    setDialogOpen2(true);
  };

  const handleCloseDialog2 = () => {
    setDialogOpen2(false);
  };

  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>{`Project - ${dataToDisplay.title}`}</title>
        <meta name="description" content="Interest" />
        <meta name="keywords" content="interest" />
      </Helmet>

      <Box>
        <Navbar />
        <Box className="topWrapper-interest">
          {/* ///--------------Desktop Screen --------------------// */}
          <Button
            onClick={handleBackClick}
            className="backBtn-interest desktop-interest"
          >
            &lt; Back To Project
          </Button>
          <Box
            className="desktop-interest"
            display="flex "
            justifyContent="space-between"
            alignItems="flex-end"
          >
            {/* <Box component="img" src={dataToDisplay.logo}></Box> */}
            <Button onClick={handleOpenDialog} className="mainBtn-interest">
              Start Project
            </Button>
          </Box>
          <Typography className="header-text-interest desktop-interest">
            {dataToDisplay.title}
          </Typography>
          <Box className="calendarBoxWrapper-interest desktop-interest">
            <Box component="img" src={calendar}></Box>
            <Typography className="durationText-interest">
              Duration | {`${dataToDisplay.duration}`}
            </Typography>
          </Box>
          {/* ////-----------------------------------------------//// */}
          {/* //---------------------Mobile Screen----------------------//// */}

          {/* <Box
            className="mobile-interest"
            component="img"
            src={dataToDisplay.logo}
          ></Box> */}
          <Box className="calendarBoxWrapper-interest mobile-interest">
            <Box component="img" src={calendar}></Box>
            <Typography className="durationText-interest">
              Duration | {`${dataToDisplay.duration}`}
            </Typography>
          </Box>
          <Typography
            marginTop="10px"
            className="header-text-interest mobile-interest"
          >
            Location Analytics for Delivery Optimisation
          </Typography>

          {/* //------------------------------------------------------// */}
          <Typography className="subheader-text-interest">
            Objective :
          </Typography>
          <Typography className="normal-text-interest">
            {dataToDisplay.objective}
          </Typography>
          <Typography className="subheader-text-interest">
            Project Description :
          </Typography>
          <Typography className="normal-text-interest">
            {dataToDisplay.projectDescription}
          </Typography>

          {/* //---------------Mobile Screen ----------------// */}
          <Box
            display="flex"
            marginTop="10px"
            justifyContent="space-between"
            alignItems="center"
            className="mobile-interest"
          >
            <Button
              onClick={handleBackClick}
              className="backBtn-mobile-interest"
            >
              &lt; Back to Project
            </Button>
            <Button onClick={handleOpenDialog} className="mainBtn-interest">
              Start Project
            </Button>
          </Box>
          {/* //----------// */}
          <Typography className="subheader-text-interest">
            Prerequisites :
          </Typography>
          <ul>
            {dataToDisplay["prerequisites"]?.map((item, index) => {
              console.log(item);
              console.log(dataToDisplay);

              return (
                <li className="normal-text-interest" key={`${item}-${index}`}>
                  {item}
                </li>
              );
            })}
          </ul>
          <Typography className="subheader-text-interest">
            Skills Required :
          </Typography>
          <ul>
            {dataToDisplay["skillsRequired"]?.map((item, index) => (
              <li className="normal-text-interest" key={index}>
                {item}
              </li>
            ))}
          </ul>

          {/* ////-----------------Popup-Before-Registration-------------//// */}

          <Dialog
            onClose={handleCloseDialog}
            open={dialogOpen}
            PaperProps={{
              sx: {
                width: { xs: "100%", md: "40%" },
                m: { xs: "16px", md: "0px" },
                borderRadius: "24px",
                backgroundColor: "#FFF",
                boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.08)",
                minHeight: "380px",
                fontFamily: '"Outfit", sans-serif !important',
                border: "1px solid #C7C7C7",
                pb: { xs: "10px", md: "10px" },
              },
            }}
          >
            <DialogContent sx={{ p: "0" }}>
              <ApplyNowDialog
                handleCloseDialog={handleCloseDialog}
                // courseContent={courseContent}
                courseContent="python"
                handleOpenDialog={handleOpenDialog2}
                source="Project_Interest"
              />
            </DialogContent>
          </Dialog>

          {/* //---------------Popup-After-Registration-----------// */}

          <Dialog onClose={handleCloseDialog2} open={dialogOpen2}>
            <DialogContent>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                handleCloseDialog={handleCloseDialog2}
              >
                <Box component="img" src={thubmsUp}></Box>
                <Typography className="registration-header-interest">
                  Registration Successful
                </Typography>
                <Typography className="registration-normal-interest">
                  Thank you for your interest in collaborating on our projects!
                  We'll be in touch soon with the next steps.
                </Typography>
                <Box
                  position="absolute"
                  right="10px "
                  top="10px"
                  component="img"
                  src={cancel}
                  onClick={handleCloseDialog2}
                ></Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Interest;
