// import React, { useState, useEffect } from "react";
// import {
//   TextField,
//   Button,
//   Select,
//   MenuItem,
//   Drawer,
//   IconButton,
// } from "@mui/material";
// import search from "../../../assets/images/ProjectsPage/Search.svg";
// import Filter from "../../../assets/images/ProjectsPage/Filter.svg";
// import CloseIcon from "@mui/icons-material/Close";
// import "./projectfilters.scss";
// import { getUniqueFilterValues } from "./filterUtils";

// const ProjectFilters = ({ onFilterChange, data1 = [] }) => {
//   const [filterSelections, setFilterSelections] = useState({
//     searchQuery: "",
//     category: "",
//     skill: "",
//     difficulty: "",
//   });

//   const [isFilterOpen, setIsFilterOpen] = useState(false);

//   // State to track if the Apply button has been clicked
//   const [applyClicked, setApplyClicked] = useState(false);

//   const toggleFilter = () => {
//     setIsFilterOpen(!isFilterOpen);
//   };

//   const handleSelectionChange = (event) => {
//     const { name, value } = event.target;
//     setFilterSelections({ ...filterSelections, [name]: value });
//   };

//   const handleSearchChange = (event) => {
//     setFilterSelections({
//       ...filterSelections,
//       searchQuery: event.target.value,
//     });
//   };

//   const applyFilters = () => {
//     setApplyClicked(true);  // Mark that Apply button was clicked
//     onFilterChange(filterSelections); // Pass the filters back to parent
//   };

//   const clearFilters = () => {
//     const resetFilters = {
//       searchQuery: "",
//       category: "",
//       skill: "",
//       difficulty: "",
//     };
//     setFilterSelections(resetFilters);
//     setApplyClicked(false);  // Reset the Apply button state
//     onFilterChange(resetFilters);
//   };

//   useEffect(() => {
//     // Only apply filters if the Apply button was clicked
//     if (applyClicked) {
//       applyFilters();
//       setApplyClicked(false);  // Reset Apply button click state after applying filters
//     }
//   }, [applyClicked, data1]);

//   // Categories you provided
//   const uniqueCategories = [
//     "Web Development", "App Development", "AI & ML", "Data Science",
//     "Cloud Computing", "Cybersecurity", "Blockchain & Cryptocurrency",
//     "IoT", "AR/VR", "Game Development", "Software Development",
//     "DevOps & Automation", "Data Engineering"
//   ];

//   // Full list of skills
//   const allSkills = [
//     "HTML", "CSS", "JavaScript", "React", "Vue.js", "Angular", "SASS", "SCSS",
//     "Bootstrap", "Tailwind CSS", "jQuery", "TypeScript", "GraphQL", "Redux",
//     "Next.js", "Gatsby", "Webpack", "Babel", "Figma", "Adobe XD", "MongoDB",
//     "MySQL", "PostgreSQL", "SQLite", "Redis", "Firebase", "Cassandra", "Docker",
//     "Kubernetes", "AWS", "Azure", "Heroku", "CI/CD", "Terraform", "Nginx",
//     "Apache", "Netlify", "Scikit-learn", "TensorFlow", "PyTorch", "Keras", 
//     "XGBoost", "LightGBM", "Matplotlib", "Seaborn", "Plotly", "Bokeh", "Altair",
//     "D3.js", "Hadoop", "Spark", "Kafka", "Hive", "MapReduce", "NLP", "Deep Learning",
//     "Neural Networks", "Computer Vision", "Reinforcement Learning", "Clustering", 
//     "Ensemble Methods"
//   ];

//   // Difficulty options
//   const difficultyOptions = [
//     "Beginner", "Intermediate", "Advanced"
//   ];

//   // Extract unique values for skills using the utility function
//   const { uniqueSkills = [] } = 
//     data1.length ? getUniqueFilterValues(data1) : {};

//   // Combine both skills from the data and the full list (ensuring no duplicates)
//   const skillsList = [...new Set([...allSkills, ...uniqueSkills])];

//   const handleFilterChange = (event) => {
//     const { name, value } = event.target;
//     setFilterSelections({ ...filterSelections, [name]: value });
//   };

//   return (
//     <div className="filters-container">
//       {/* Search bar */}
//       <div className="search-bar-wrapper">
//         <img src={search} alt="search" className="img-search" />
//         <TextField
//           placeholder="Search projects"
//           value={filterSelections.searchQuery}
//           onChange={handleSearchChange}
//           sx={{
//             fontFamily: "Poppins",
//             "& .MuiOutlinedInput-notchedOutline": {
//               border: "none",
//             },
//           }}
//         />
//         <button className="filter-icon" onClick={toggleFilter}>
//           <img src={Filter} alt="filter" className="image-filter" />
//         </button>
//       </div>

//       {/* Filters */}
//       <div className="filter-group">
//         <Select
//           name="category"
//           value={filterSelections.category}
//           onChange={handleSelectionChange}
//           size="small"
//           displayEmpty
//           sx={{
//             display: "flex",
//             width: "157px",
//             height: "50px",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             flexShrink: "0",
//             backgroundColor: "white",
//             fontFamily: "Poppins",
//             fontWeight: "400",
//             color: "var(--Neautral-600, #78716C)",
//             borderRadius: "8px",
//             padding: "16px 21px",
//             fontSize: "14px",
//             fontStyle: "normal",
//             lineHeight: "normal",
//             "& .MuiOutlinedInput-notchedOutline": {
//               border: "none",
//             },
//           }}
//         >
//           <MenuItem value="" disabled>Categories</MenuItem>
//           {uniqueCategories.map((category) => (
//             <MenuItem key={category} value={category}>
//               {category}
//             </MenuItem>
//           ))}
//         </Select>
//         <Select
//           name="skill"
//           value={filterSelections.skill}
//           onChange={handleSelectionChange}
//           size="small"
//           displayEmpty
//           sx={{
//             display: "flex",
//             width: "157px",
//             height: "50px",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             flexShrink: "0",
//             backgroundColor: "white",
//             fontFamily: "Poppins",
//             fontWeight: "400",
//             color: "var(--Neautral-600, #78716C)",
//             borderRadius: "8px",
//             padding: "16px 21px",
//             fontSize: "14px",
//             fontStyle: "normal",
//             lineHeight: "normal",
//             "& .MuiOutlinedInput-notchedOutline": {
//               border: "none",
//             },
//           }}
//         >
//           <MenuItem value="" disabled>Skills</MenuItem>
//           {skillsList.map((skill) => (
//             <MenuItem key={skill} value={skill}>
//               {skill}
//             </MenuItem>
//           ))}
//         </Select>
//         <Select
//           name="difficulty"
//           value={filterSelections.difficulty}
//           onChange={handleSelectionChange}
//           size="small"
//           displayEmpty
//           sx={{
//             display: "flex",
//             width: "157px",
//             height: "50px",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             flexShrink: "0",
//             backgroundColor: "white",
//             fontFamily: "Poppins",
//             fontWeight: "400",
//             color: "var(--Neautral-600, #78716C)",
//             borderRadius: "8px",
//             padding: "16px 21px",
//             fontSize: "14px",
//             fontStyle: "normal",
//             lineHeight: "normal",
//             "& .MuiOutlinedInput-notchedOutline": {
//               border: "none",
//             },
//           }}
//         >
//           <MenuItem value="" disabled>Difficulty Level</MenuItem>
//           {difficultyOptions.map((difficulty) => (
//             <MenuItem key={difficulty} value={difficulty}>
//               {difficulty}
//             </MenuItem>
//           ))}
//         </Select>
//         <Button onClick={applyFilters} sx={{
//             fontFamily: "Poppins",
//             borderRadius: "8px",
//             backgroundColor: "#333",
//             color: "white",
//             padding: "8px 16px",
//             width: "120px",
//             height: "50px",
//             textTransform: "none",
//             "&:hover": {
//               backgroundColor: "#444",
//             },
//           }}>Apply</Button>
//         <Button onClick={clearFilters} sx={{
//             fontFamily: "Poppins",
//             borderRadius: "8px",
//             backgroundColor: "white",
//             width: "120px",
//             height: "50px",
//             color: "black",
//             border: "1px solid black",
//             padding: "8px 16px",
//             textTransform: "none",
//             "&:hover": {
//               backgroundColor: "transparent",
//             },
//           }}>Clear</Button>
//       </div>

//       {/* Mobile Drawer */}
//       <Drawer
//         anchor="bottom"
//         open={isFilterOpen}
//         onClose={toggleFilter}
//         variant="temporary"  // Changes Drawer to be modal-like on mobile
//         PaperProps={{ sx: { height: "70%", width: "100%" } }}  // Full width
//       >
//         <div className="drawer-header">
//           <IconButton onClick={toggleFilter}>
//             <CloseIcon />
//           </IconButton>
//           <h3>Filter Projects</h3>
//         </div>

//         <div className="filter-content">
//           <Select
//             name="category"
//             value={filterSelections.category}
//             onChange={handleSelectionChange}
//             className="filter-dropdown"
//             size="small"
//             displayEmpty
//             sx={{
//               display: "flex",
//               width: "100%",
//               height: "50px",
//               backgroundColor: "white",
//               fontFamily: "Poppins",
//               fontWeight: "400",
//               color: "var(--Neautral-600, #78716C)",
//               borderRadius: "8px",
//               padding: "16px 21px",
//               fontSize: "14px",
//               fontStyle: "normal",
//               lineHeight: "normal",
//               "& .MuiOutlinedInput-notchedOutline": {
//                 border: "none",
//               },
//             }}
//           >
//             <MenuItem value="">Categories</MenuItem>
//             {uniqueCategories.map((category) => (
//               <MenuItem key={category} value={category}>
//                 {category}
//               </MenuItem>
//             ))}
//           </Select>
//           <Select
//             name="skill"
//             value={filterSelections.skill}
//             onChange={handleSelectionChange}
//             className="filter-dropdown"
//             size="small"
//             displayEmpty
//             sx={{
//               display: "flex",
//               width: "100%",
//               height: "50px",
//               backgroundColor: "white",
//               fontFamily: "Poppins",
//               fontWeight: "400",
//               color: "var(--Neautral-600, #78716C)",
//               borderRadius: "8px",
//               padding: "16px 21px",
//               fontSize: "14px",
//               fontStyle: "normal",
//               lineHeight: "normal",
//               "& .MuiOutlinedInput-notchedOutline": {
//                 border: "none",
//               },
//             }}
//           >
//             <MenuItem value="">Skills</MenuItem>
//             {skillsList.map((skill) => (
//               <MenuItem key={skill} value={skill}>
//                 {skill}
//               </MenuItem>
//             ))}
//           </Select>
//         </div>
//       </Drawer>
//     </div>
//   );
// };

// export default ProjectFilters;

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Drawer,
  IconButton,
} from "@mui/material";
import search from "../../../assets/images/ProjectsPage/Search.svg";
import Filter from "../../../assets/images/ProjectsPage/Filter.svg";
import CloseIcon from "@mui/icons-material/Close";
import "./projectfilters.scss";
import { getUniqueFilterValues } from "./filterUtils";

const ProjectFilters = ({ onFilterChange, data1 = [] }) => {
  const [filterSelections, setFilterSelections] = useState({
    searchQuery: "",
    category: "",
    skill: "",
    difficulty: "",
  });

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // State to track if the Apply button has been clicked
  const [applyClicked, setApplyClicked] = useState(false);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleSelectionChange = (event) => {
    const { name, value } = event.target;
    setFilterSelections({ ...filterSelections, [name]: value });
  };

  const handleSearchChange = (event) => {
    setFilterSelections({
      ...filterSelections,
      searchQuery: event.target.value,
    });
  };

  const applyFilters = () => {
    setApplyClicked(true); // Mark that Apply button was clicked
    onFilterChange(filterSelections); // Pass the filters back to parent
    setIsFilterOpen(false); // Close the drawer after applying filters
  };

  const clearFilters = () => {
    const resetFilters = {
      searchQuery: "",
      category: "",
      skill: "",
      difficulty: "",
    };
    setFilterSelections(resetFilters);
    setApplyClicked(false); // Reset the Apply button state
    onFilterChange(resetFilters);
    setIsFilterOpen(false); // Close the drawer after clearing filters
  };

  useEffect(() => {
    // Only apply filters if the Apply button was clicked
    if (applyClicked) {
      applyFilters();
      setApplyClicked(false); // Reset Apply button click state after applying filters
    }
  }, [applyClicked, data1]);

  // Categories you provided
  const uniqueCategories = [
    "Web Development", "App Development", "AI & ML", "Data Science",
    "Cloud Computing", "Cybersecurity", "Blockchain & Cryptocurrency",
    "IoT", "AR/VR", "Game Development", "Software Development",
    "DevOps & Automation", "Data Engineering",
  ];

  // Full list of skills
  const allSkills = [
    "HTML", "CSS", "JavaScript", "React", "Vue.js", "Angular", "SASS", "SCSS",
    "Bootstrap", "Tailwind CSS", "jQuery", "TypeScript", "GraphQL", "Redux",
    "Next.js", "Gatsby", "Webpack", "Babel", "Figma", "Adobe XD", "MongoDB",
    "MySQL", "PostgreSQL", "SQLite", "Redis", "Firebase", "Cassandra", "Docker",
    "Kubernetes", "AWS", "Azure", "Heroku", "CI/CD", "Terraform", "Nginx",
    "Apache", "Netlify", "Scikit-learn", "TensorFlow", "PyTorch", "Keras", 
    "XGBoost", "LightGBM", "Matplotlib", "Seaborn", "Plotly", "Bokeh", "Altair",
    "D3.js", "Hadoop", "Spark", "Kafka", "Hive", "MapReduce", "NLP", "Deep Learning",
    "Neural Networks", "Computer Vision", "Reinforcement Learning", "Clustering", 
    "Ensemble Methods",
  ];

  // Difficulty options
  const difficultyOptions = [
    "Beginner", "Intermediate", "Advanced",
  ];

  // Extract unique values for skills using the utility function
  const { uniqueSkills = [] } =
    data1.length ? getUniqueFilterValues(data1) : {};

  // Combine both skills from the data and the full list (ensuring no duplicates)
  const skillsList = [...new Set([...allSkills, ...uniqueSkills])];

  return (
    <div className="filters-container">
      {/* Search bar */}
      <div className="search-bar-wrapper">
        <img src={search} alt="search" className="img-search" />
        <TextField
          placeholder="Search projects"
          value={filterSelections.searchQuery}
          onChange={handleSearchChange}
          sx={{
            fontFamily: "Poppins",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
        <button className="filter-icon" onClick={toggleFilter}>
          <img src={Filter} alt="filter" className="image-filter" />
        </button>
      </div>

      {/* Filters */}
      <div className="filter-group">
        <Select
          name="category"
          value={filterSelections.category}
          onChange={handleSelectionChange}
          size="small"
          displayEmpty
          sx={{
            display: "flex",
            width: "157px",
            height: "50px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: "0",
            backgroundColor: "white",
            fontFamily: "Poppins",
            fontWeight: "400",
            color: "var(--Neautral-600, #78716C)",
            borderRadius: "8px",
            padding: "16px 21px",
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "normal",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="" disabled>Categories</MenuItem>
          {uniqueCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
        <Select
          name="skill"
          value={filterSelections.skill}
          onChange={handleSelectionChange}
          size="small"
          displayEmpty
          sx={{
            display: "flex",
            width: "157px",
            height: "50px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: "0",
            backgroundColor: "white",
            fontFamily: "Poppins",
            fontWeight: "400",
            color: "var(--Neautral-600, #78716C)",
            borderRadius: "8px",
            padding: "16px 21px",
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "normal",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="" disabled>Skills</MenuItem>
          {skillsList.map((skill) => (
            <MenuItem key={skill} value={skill}>
              {skill}
            </MenuItem>
          ))}
        </Select>
        <Select
          name="difficulty"
          value={filterSelections.difficulty}
          onChange={handleSelectionChange}
          size="small"
          displayEmpty
          sx={{
            display: "flex",
            width: "157px",
            height: "50px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: "0",
            backgroundColor: "white",
            fontFamily: "Poppins",
            fontWeight: "400",
            color: "var(--Neautral-600, #78716C)",
            borderRadius: "8px",
            padding: "16px 21px",
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "normal",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="" disabled>Difficulty Level</MenuItem>
          {difficultyOptions.map((difficulty) => (
            <MenuItem key={difficulty} value={difficulty}>
              {difficulty}
            </MenuItem>
          ))}
        </Select>
        <Button onClick={applyFilters} sx={{
            fontFamily: "Poppins",
            borderRadius: "8px",
            backgroundColor: "#333",
            color: "white",
            padding: "8px 16px",
            width: "120px",
            height: "50px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#444",
            },
          }}>Apply</Button>
        <Button onClick={clearFilters} sx={{
            fontFamily: "Poppins",
            borderRadius: "8px",
            backgroundColor: "white",
            width: "120px",
            height: "50px",
            color: "black",
            border: "1px solid black",
            padding: "8px 16px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}>Clear</Button>
      </div>

      {/* Mobile Drawer */}
      <Drawer
        anchor="bottom"
        open={isFilterOpen}
        onClose={toggleFilter}
        PaperProps={{ sx: { height: "70%" } }}
      >
        <div className="drawer-header">
          <IconButton onClick={toggleFilter}>
            <CloseIcon />
          </IconButton>
          <h3>Filter Projects</h3>
        </div>

        <div className="filter-content">
          <Select
            name="category"
            value={filterSelections.category}
            onChange={handleSelectionChange}
            displayEmpty
          >
            <MenuItem value="">Categories</MenuItem>
            {uniqueCategories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
          <Select
            name="skill"
            value={filterSelections.skill}
            onChange={handleSelectionChange}
            displayEmpty
          >
            <MenuItem value="">Skills</MenuItem>
            {skillsList.map((skill) => (
              <MenuItem key={skill} value={skill}>
                {skill}
              </MenuItem>
            ))}
          </Select>
          <Select
          name="difficulty"
          value={filterSelections.difficulty}
          onChange={handleSelectionChange}
        
          displayEmpty
         
        >
          <MenuItem value="" disabled>Difficulty Level</MenuItem>
          {difficultyOptions.map((difficulty) => (
            <MenuItem key={difficulty} value={difficulty}>
              {difficulty}
            </MenuItem>
          ))}
        </Select>
          <Button className="apply-button" onClick={applyFilters} sx={{
            fontFamily: "Poppins",
            borderRadius: "8px",
            backgroundColor: "#333",
            color: "white",
            padding: "8px 16px",
            width: "100%",
            height: "50px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#444",
            },
          }}>Apply</Button>
        <Button  className="clear-button" onClick={clearFilters} sx={{
            fontFamily: "Poppins",
            borderRadius: "8px",
            backgroundColor: "white",
            width: "100%",
            height: "50px",
            color: "black",
            border: "1px solid black",
            padding: "8px 16px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}>Clear</Button>
        </div>
      </Drawer>
    </div>
  );
};

export default ProjectFilters;



