import "./mid2.scss";
import c1 from "../../../assets/images/mockInterview/c1.png";
import c2 from "../../../assets/images/mockInterview/c2.svg";
import c3 from "../../../assets/images/mockInterview/c3.svg";
import c4 from "../../../assets/images/mockInterview/c4.png";
import c5 from "../../../assets/images/mockInterview/c5.png";
import c6 from "../../../assets/images/mockInterview/c6.png";
import c7 from "../../../assets/images/mockInterview/c7.svg";
import c8 from "../../../assets/images/mockInterview/c8.svg";
import c9 from "../../../assets/images/mockInterview/c9.svg";
import first from "../../../assets/images/mockInterview/1stNum.svg";
import second from "../../../assets/images/mockInterview/2ndNum.svg";
import third from "../../../assets/images/mockInterview/3rdNum.svg";
import r1 from "../../../assets/images/mockInterview/r1.svg";
import r2 from "../../../assets/images/mockInterview/r2.svg";
import r3 from "../../../assets/images/mockInterview/r3.svg";

const { Box, Typography } = require("@mui/material");

const Mid2 = () => {
  return (
    <Box>
      <Typography className="head-text">
        Our Interviewers And Mentors
      </Typography>
      <Typography color="black !important" className="head-text ">
        Have Worked At
      </Typography>
      
      <Box className="top-wrapper-mid2">
        <Box
          className="company-wrapper"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="95px"
        >
           <Box component="img" className="company-image" src={c1}></Box>
          <Box component="img" className="company-image" src={c2}></Box>
          <Box component="img" className="company-image" src={c3}></Box>
          <Box component="img" className="company-image" src={c4}></Box>
          <Box component="img" className="company-image" src={c5}></Box>
          <Box component="img" className="company-image" src={c6}></Box>
          <Box component="img" className="company-image" src={c7}></Box>
          <Box component="img" className="company-image" src={c8}></Box>
          <Box component="img" className="company-image" src={c9}></Box>
          <Box component="img" className="company-image" src={c1}></Box>
          <Box component="img" className="company-image" src={c2}></Box>
          <Box component="img" className="company-image" src={c3}></Box>
          <Box component="img" className="company-image" src={c4}></Box>
          <Box component="img" className="company-image" src={c5}></Box>
          <Box component="img" className="company-image" src={c6}></Box>
          <Box component="img" className="company-image" src={c7}></Box>
          <Box component="img" className="company-image" src={c8}></Box>
          <Box component="img" className="company-image" src={c9}></Box>
          <Box component="img" className="company-image" src={c1}></Box>
          <Box component="img" className="company-image" src={c2}></Box>
          <Box component="img" className="company-image" src={c3}></Box>
          <Box component="img" className="company-image" src={c4}></Box>
          <Box component="img" className="company-image" src={c5}></Box>
          <Box component="img" className="company-image" src={c6}></Box>
          <Box component="img" className="company-image" src={c7}></Box>
          <Box component="img" className="company-image" src={c8}></Box>
          <Box component="img" className="company-image" src={c9}></Box>
           <Box component="img" className="company-image" src={c1}></Box>
          <Box component="img" className="company-image" src={c2}></Box>
          <Box component="img" className="company-image" src={c3}></Box>
          <Box component="img" className="company-image" src={c4}></Box>
          <Box component="img" className="company-image" src={c5}></Box>
          <Box component="img" className="company-image" src={c6}></Box>
          <Box component="img" className="company-image" src={c7}></Box>
          <Box component="img" className="company-image" src={c8}></Box>
          <Box component="img" className="company-image" src={c9}></Box>
        </Box>
        
      </Box>
      <Box className="bg-register">
        <Typography className="head-text">
          Steps <span style={{ color: "black" }}>to Register</span>{" "}
        </Typography>
        <Box
          display="flex"
          padding="50px 10px"
          gap="10px"
          justifyContent="center"
          className="wrapper-reg"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            flexDirection="column"
            gap="10px"
            width="350px"
          >
            <Box className="circle" component="img" src={first}></Box>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              gap="5px"
            >
              <Box
                marginTop="15px"
                gap="10px"
                display="flex"
                alignItems="center"
              >
                <Box component="img" src={r1}></Box>
                <Typography className="reg">Register</Typography>
              </Box>
              <Typography className="normal-text">
                Fill the corresponding details check your availability
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            flexDirection="column"
            gap="10px"
            width="350px"
          >
            <Box className="circle" component="img" src={second}></Box>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              gap="5px"
            >
              <Box
                marginTop="15px"
                gap="10px"
                display="flex"
                alignItems="center"
              >
                <Box component="img" src={r2}></Box>
                <Typography className="reg">Schedule</Typography>
              </Box>
              <Typography className="normal-text">
                Set a date and time for your mock interview and one-on-one
                career counseling session.
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            flexDirection="column"
            gap="10px"
            width="350px"
          >
            <Box className="circle" component="img" src={third}></Box>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              gap="5px"
            >
              <Box
                marginTop="15px"
                gap="10px"
                display="flex"
                alignItems="center"
              >
                <Box component="img" src={r3}></Box>
                <Typography className="reg">Confirm</Typography>
              </Box>
              <Typography className="normal-text">
                Confirm your Registration
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Mid2;
