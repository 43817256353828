
import ec2 from '../assets/images/workshop/ec2.png';
import book from "../assets/images/workshop/Book.gif";
import qa from "../assets/images/workshop/QA.gif";
import girlImg from "../assets/images/workshop/girl.png";


const data=[{
    "Workshop-id": "74b7935a-f8c4-4655-8684-d8b2360eca80",
    "Workshop-name":"AI-Workshop-10",
    "toplayer": {
  
      "header-text-1": "Don't let AI over-power you! Become an",
      "header-text-2": "AI-powered professional",
      "text-1-workshop": "Learn groundbreaking secrets to save 1000s of dollars, streamline work processes & supercharge your growth in 2024 with the GenAI 8.0 Workshop",
      "day": "5-day",
      "instructorImg":girlImg,
      "workshop-name": "Aws Devops Workshop",
      "instructor-name": "by Anita Shetty",
      "experience": "6+ Yrs Exp in Data Industry, Ex-ISB",
      "workshop-outcome": [
        "5+ Industry Projects",
        "Industry ready certification",
        "Career guidance",
        "Hands on experience"
      ],
      "registernow-price": "200",
      "actual-price": "3000",
      "offer-end-time": "00:00",
    },
    "secondLayer": {
      "heading1": {
        "plainText": "What will you learn",
        "highlightedText": "in the workshop?"
      },
      "learningPoints": [
        {
          "text": "Gain Practical Skills Through Interactive Sessions And Real-World Examples",
        },
        {
          "text": "Learn From Industry Experts With Extensive Experience In AWS and DevOps",
        },
        {
          "text": "Connect With Like-Minded Professionals And Expand Your Network​",
        },
        {
          "text": "Receive A Certificate To Showcase Your New Skills",
        },
      ],
      "heading2": {
        "plainText": "Who",
        "highlightedText": "This Masterclass Is For"
      },
      "audience": [
        "Students",
        "IT operation professionals",
        "Early Professionals",
        "Tech Entrepreneurs",
        "System administrators and developers"
      ]
    },
    "thirdLayer": {
        "tools": [
          { "name": "AWS EC2", "img": ec2 },
          { "name": "AWS Lambda", "img": ec2 },
          { "name": "AWS S3", "img": ec2 },
          { "name": "AWS RDS", "img": ec2 },
          { "name": "AWS CloudFormation", "img": ec2 },
          { "name": "AWS IAM", "img": ec2 },
          { "name": "AWS IAM", "img": ec2 },
          { "name": "AWS IAM", "img": ec2 }
        ],
        "learningModules": [
          "Setting Up AWS Infrastructure",
          "CI/CD Pipeline with Jenkins and AWS",
          "Container Management with Docker and AWS",
          "Infrastructure as Code with Terraform",
          "Monitoring and Logging with CloudWatch",
          "Security Best Practices in AWS"
        ],
        "headerText": {
          "before": "Here’s ",
          "highlight": "What You’ll Learn",
          "after": "in the 3-Hour Workshop"
        }
      }
  ,
    "lastLayer":{
      "registrationDeadline": "4th September 2024",
      "bonusesWorth": 5000,
      "bonuses": [
        {
          "title": "Extended Learning Access",
          "content": "Dive deeper and at your own pace! Get one-year access to all course materials, including videos and . Keep learning long after the course ends.",
          "img": book
        },
        {
          "title": "Monthly Expert Q&A Sessions",
          "content": "Stay connected and supported. Join our exclusive monthly live Q&A sessions for a whole year. Bring your questions, discuss challenges, and explore advanced topics with the experts and your peers.",
          "img": qa
        },
        {
          "title": "Professional Certification",
          "content": "Boost your professional profile. Complete the course and earn a Certificate of Completion to showcase your advanced skills in AWS DevOps on your resume and LinkedIn.",
          "img": ""
        }
      ],
      "description": "At CourseVita, we've upskilled over 2k+ students with expert-led workshops and courses in Product, Growth, Design, Tech, Data, and Business, all guided by top experts. Take your first step with us for your tomorrow's future.",
      "reviews": {
        "rating": "4.8/5",
        "count": "3k+"
      },
      "learnersCount": "20k+",
      "price": 299,
      "originalPrice": 1999,
      "discount": 85,
      "features": [
        "Hands-on projects",
        "Exclusive study materials",
        "Personalized guidance",
        "Gamified Learning",
        "Get certified by Industry experts"
      ],
      "offerEndsIn": "00:00",
      "masteryField": "AWS DevOps",
      "callToAction": "Don't miss out! Secure your spot today",
      "exclusiveContent": "Exclusive content awaiting for you"
    }
    
  }
  


,
{
  "Workshop-id": "9b046c14-1fde-4a92-a03b-318c9c68364d",
  "Workshop-name":"Cloud-Computing-Workshop-11",
  "toplayer": {
    "header-text-1": "Harness the Power of Cloud! Become an",
    "header-text-2": "Expert in Cloud Technologies",
    "text-1-workshop": "Unlock the secrets to enhance your cloud skills, optimize processes & accelerate your career in 2024 with the Cloud Mastery Workshop",
    "day": "5-day",
    "instructorImg": girlImg,
    "workshop-name": "Cloud Mastery Workshop",
    "instructor-name": "by Rahul Verma",
    "experience": "8+ Yrs Exp in Cloud Computing, Ex-Google",
    "workshop-outcome": [
      "7+ Real-World Projects",
      "Industry-recognized certification",
      "Career mentorship",
      "Hands-on training"
    ],
    "registernow-price": "250",
    "actual-price": "3500",
    "offer-end-time": "12:00",
    
  },
  "secondLayer": {
    "heading1": {
      "plainText": "What will you gain",
      "highlightedText": "from this workshop?"
    },
    "learningPoints": [
      {
        "text": "Acquire Practical Skills with Interactive Learning and Case Studies",
      },
      {
        "text": "Learn from Leading Experts in Cloud Technologies",
      },
      {
        "text": "Network with Professionals in the Cloud Industry",
      },
      {
        "text": "Receive a Certification to Validate Your Skills",
      }
    ],
    "heading2": {
      "plainText": "Who",
      "highlightedText": "Should Attend This Workshop?"
    },
    "audience": [
      "Students",
      "IT professionals",
      "Career Changers",
      "Entrepreneurs",
      "Software Developers"
    ]
  },
  "thirdLayer": {
    "tools": [
      { "name": "AWS EC2", "img": ec2 },
      { "name": "AWS Lambda", "img": ec2 },
      { "name": "AWS S3", "img": ec2 },
      { "name": "AWS RDS", "img": ec2 },
      { "name": "AWS CloudFormation", "img": ec2 },
      { "name": "AWS IAM", "img": ec2 }
    ],
    "learningModules": [
      "Building Scalable Cloud Solutions",
      "Continuous Integration and Deployment Strategies",
      "Managing Docker Containers on AWS",
      "Implementing Infrastructure as Code",
      "Effective Monitoring and Incident Management",
      "Best Practices for Cloud Security"
    ],
    "headerText": {
      "before": "Discover &nbsp;",
      "highlight": "What You’ll Master",
      "after": "in the 5-Day Workshop"
    }
  },
  "lastLayer": {
    "registrationDeadline": "15th October 2024",
    "bonusesWorth": 6000,
    "bonuses": [
      {
        "title": "Extended Access to Course Content",
        "content": "Get lifetime access to all materials, including videos and exercises, for ongoing learning.",
        "img": book
      },
      {
        "title": "Exclusive Monthly Q&A with Instructors",
        "content": "Join our monthly live Q&A sessions to clarify your doubts and gain deeper insights.",
        "img": qa
      },
      {
        "title": "Professional Development Certification",
        "content": "Obtain a recognized Certificate of Completion to enhance your career prospects.",
        "img": ""
      }
    ],
    "description": "Join over 3k+ students who have transformed their careers with our expert-led workshops. Elevate your skills in Cloud Computing with us today!",
    "reviews": {
      "rating": "4.9/5",
      "count": "4k+"
    },
    "learnersCount": "25k+",
    "price": 349,
    "originalPrice": 2499,
    "discount": 86,
    "features": [
      "Hands-on projects",
      "Exclusive resource materials",
      "Personalized mentorship",
      "Interactive Learning Environment",
      "Certification by Industry Leaders"
    ],
    "offerEndsIn": "12:00",
    "masteryField": "Cloud Technologies",
    "callToAction": "Act Fast! Reserve Your Spot Now",
    "exclusiveContent": "Unlock unique insights and resources just for you"
  }
}];


export default data;
  