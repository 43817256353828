import React, { useRef, useState, useEffect, useCallback } from "react";
import { Box, Button } from "@mui/material";
import FilterDropDown from "../filterDropdowns/FilterDropDown";
import "./Filters.scss";
import categoryIcon from "../../../assets/images/findYourCareer/category.svg";
import locationIcon from "../../../assets/images/findYourCareer/Location.svg";
import modeIcon from "../../../assets/images/findYourCareer/mode.svg";
import rupeeIcon from "../../../assets/images/findYourCareer/rupee.svg";
import filterIcon from "../../../assets/images/findYourCareer/filter.svg";
import timeIcon from "../../../assets/images/findYourCareer/Time.svg";
import groupIcon from "../../../assets/images/findYourCareer/group.svg";
import next from "../../../assets/images/findYourCareer/next.svg";
import prev from "../../../assets/images/findYourCareer/prev.svg";
import FiltersDrawer from "../filtersDrawer/FiltersDrawer";

const FIELD_MAPPER = {
    "category": {
        "label": "Category",
        "icon": categoryIcon
    },
    "modeOfStudy": {
        "label": "Mode of Study",
        "icon": modeIcon
    },
    "totalFee": {
        "label": "Course Fee",
        "icon": rupeeIcon
    },
    "duration": {
        "label": "Duration",
        "icon": timeIcon
    },
    "courseLevel": {
        "label": "Course Level",
        "icon": groupIcon
    },
    "location": {
        "label": "Location",
        "icon": locationIcon
    }
};

const Filters = (props) => {
    const { config, handleFilterChange, handleApplyFilters, handleClearAllFilters } = props;
    const sliderRef = useRef(null);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [scrollButtonState, setScrollButtonState] = useState({ left: false, right: true });

    const updateButtonState = () => {
        if (sliderRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
            setScrollButtonState({
                left: scrollLeft > 0,
                right: scrollLeft + clientWidth + 1 < scrollWidth
            });
        }
    };

    const scrollBy200px = (direction) => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({ left: direction === "left" ? -210 : 210, behavior: "smooth" });
            updateButtonState();
        }
    };

    useEffect(() => {
        const handleScroll = () => updateButtonState();
        const slider = sliderRef.current;
        slider.addEventListener('scroll', handleScroll);
        return () => {
            slider.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isMobile = window.innerWidth < 900;

    const RenderFilterDropdown = useCallback(() => {
        return (
            <>
                {Object.keys(config || {}).map((fieldId) => {
                    const data = {
                        ...FIELD_MAPPER[fieldId],
                        options: config[fieldId],
                        fieldId: fieldId
                    };
                    return (
                        <Box key={fieldId} className="sliderItem">
                            <FilterDropDown
                                content={data}
                                handleItemsSelection={(data) => {
                                    const updatedItems = { ...selectedFilters, [data.fieldId]: data };
                                    setSelectedFilters(updatedItems)
                                    handleFilterChange(updatedItems);
                                }}
                                selectedOptions={selectedFilters[fieldId]?.options || []}
                            />
                        </Box>
                    );
                })}
            </>
        )
    }, [config, selectedFilters])

    const handleClearAll = () => {
        setSelectedFilters({})
        handleFilterChange({});
        const elem = document.getElementById("courseSearchBar");
        elem.dispatchEvent(new Event("clear-all"));
        handleClearAllFilters();
        setDrawerOpen(false);
    }

    return (
        <Box className="filtersWrapper" sx={{ display: { xs: "flex" }, flexDirection: { md: 'row' } }}>
            <>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center", position: "relative", width: { sm: 'calc(100% - 265px)' } }}>
                    <Box
                        onClick={() => scrollBy200px("left")}
                        className="scrollButton"
                        disabled={!scrollButtonState.left}
                        component="img"
                        src={prev}
                    />
                    <Box className="filtersSlider" ref={sliderRef} sx={{ overflowX: 'auto', display: 'flex', gap: '10px', scrollBehavior: 'smooth' }}>
                        <RenderFilterDropdown></RenderFilterDropdown>
                    </Box>
                    <Box
                        onClick={() => scrollBy200px("right")}
                        className="scrollButton"
                        disabled={!scrollButtonState.right}
                        component="img"
                        src={next}
                    />
                </Box>
                <Box className="filtersButtons" sx={{ display: { xs: 'none', md: 'flex' } }} >
                    <Button className="clearAllBtn" sx={{ width: { md: "72px" } }} onClick={handleClearAll}>Clear all</Button>
                    <Button variant="outlined" className="applyFiltersBtn" onClick={() => handleApplyFilters()}>Apply Filters</Button>
                </Box>
            </>
            {isMobile &&
                <Box className="mobile">
                    <Button onClick={() => setDrawerOpen(true)} className="applyFiltersBtn">
                        <Box component="img" src={filterIcon} sx={{ mr: "10px" }} />
                        Apply Filters
                    </Button>
                    <FiltersDrawer config={config} setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} FIELD_MAPPER={FIELD_MAPPER}
                        handleApplyFilters={() => {
                            setDrawerOpen(false);
                            handleApplyFilters();
                        }}
                        handleClearAll={handleClearAll}
                        selectedFilters={selectedFilters}
                        handleSelectedFilters={(info) => {
                            const data = { ...selectedFilters, [info.fieldId]: info };
                            setSelectedFilters(data)
                            handleFilterChange(data);
                        }}
                    />
                </Box>}

        </Box>
    );
};

export default Filters;