import { motion } from "framer-motion";
import "./Green.css";

function Green() {
  return (
    <div className="green">
      <motion.div
        className="round1"
        initial={{ opacity: 0, scale: 0 }} // Starts fully hidden and small
        animate={{
          opacity: [0, 1, 0.5, 1, 0], // Fades in to full opacity, then fades out to 0
          scale: [0, 1, 0.8, 1, 0], // Grows to full size, then shrinks to 80%, then back
        }}
        exit={{
          opacity: 0, // Fades out
          scale: 0, // Shrinks to 0
        }}
        transition={{
          duration: 2, // 2 seconds total per cycle
          ease: "easeInOut", // Smooth easing in and out
          repeat: Infinity, // Infinite loop
          repeatType: "loop", // Continuous looping
          repeatDelay: 0.5, // Adds a 1-second pause between cycles
        }}
      />
    </div>
  );
}

export default Green;
