// import React, { useState, useEffect } from "react";
// import ExploreSection from "./exploreSection/ExploreSection";
// import Navbar from "../commonComponents/navbar/Navbar";
// import Footer from "../commonComponents/footer/Footer";
// import Workshops from "./workshops/Workshops";
// import Companies from "./companies/Companies";
// import CoursesSection from "./coursesSection/CoursesSection";
// import WhyCoursevita from "./whyCoursevita/WhyCoursevita";
// // import LearningJourney from "./learningJourney/LearningJourney";
// import Testimonials from "./testimonials/Testimonials";
// import Subscribe from "./subscribe/Subscribe";
// import BookYourDemo from "./bookYourDemo/BookYourDemo";
// import Helper from "./helper/Helper";
// import Preloader from "../pre-loader/PreLoader";
// const LandingPage = (props) => {
//   const [sections, setSections] = useState({
//     showWorkshops: false,
//     showCompanies: false,
//     showCoursesSection: false,
//     showHelper: false,            // Add Helper state
//     showWhyCoursevita: false,
//     // showLearningJourney: false,
//     showTestimonials: false,
//     showSubscribe: false,
//     showBookYourDemo: false,
//     showFooter: false,
//   });

//   useEffect(() => {
//     const delays = [
//       { key: 'showWorkshops', delay: 500 },
//       { key: 'showCompanies', delay: 1000 },
//       { key: 'showCoursesSection', delay: 1500 },
//       { key: 'showHelper', delay: 2200 },        // Helper added with 1.8s delay
//       { key: 'showWhyCoursevita', delay: 2000 },
//       // { key: 'showLearningJourney', delay: 2500 },
//       { key: 'showTestimonials', delay: 2500 },
//       { key: 'showSubscribe', delay: 3000 },
//       { key: 'showBookYourDemo', delay: 3500 },
//       { key: 'showFooter', delay: 4000 },
//     ];

//     delays.forEach(({ key, delay }) => {
//       setTimeout(() => {
//         setSections((prevState) => ({ ...prevState, [key]: true }));
//       }, delay);
//     });

//     return () => {
//       delays.forEach(({ delay }) => clearTimeout(delay));
//     };
//   }, []);

//   return (
//     <div>
//       <Navbar />
//       <ExploreSection />

//       {/* Render components based on timers */}
//       {sections.showWorkshops && <Workshops />}
//       {sections.showCompanies && <Companies />}
//       {sections.showCoursesSection && <CoursesSection coursesContent={props.coursesContent} />}
//           {/* Conditional rendering for Helper */}
//       {sections.showWhyCoursevita && <WhyCoursevita />}
//       {/* {sections.showLearningJourney && <LearningJourney />} */}
//       {sections.showHelper && <Helper />}        {/* Conditional rendering for Helper */}

//       {sections.showTestimonials && <Testimonials />}
//       {sections.showSubscribe && <Subscribe />}
//       {sections.showBookYourDemo && <BookYourDemo />}
//       {sections.showFooter && <Footer />}
//     </div>
//   );
// };

// export default LandingPage;

import Preloader from "../pre-loader/PreLoader";
import React, { useState, useEffect } from "react";
import ExploreSection from "./exploreSection/ExploreSection";
import Navbar from "../commonComponents/navbar/Navbar";
import Footer from "../commonComponents/footer/Footer";
import Workshops from "./workshops/Workshops";
// import Companies from "./companies/Companies";
import CoursesSection from "./coursesSection/CoursesSection";
import WhyCoursevita from "./whyCoursevita/WhyCoursevita";
// import LearningJourney from "./learningJourney/LearningJourney";
import Testimonials from "./testimonials/Testimonials";
import Subscribe from "./subscribe/Subscribe";
import BookYourDemo from "./bookYourDemo/BookYourDemo";
import Helper from "./helper/Helper";
const LandingPage = (props) => {
  const [sections, setSections] = useState({
    showWorkshops: false,
    showCompanies: false,
    showCoursesSection: false,
    showHelper: false, // Add Helper state
    showWhyCoursevita: false,
    // showLearningJourney: false,
    showTestimonials: false,
    showSubscribe: false,
    showBookYourDemo: false,
    showFooter: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delays = [
      { key: "showWorkshops", delay: 800 },
      { key: "showCompanies", delay: 1000 },
      { key: "showCoursesSection", delay: 1500 },
      { key: "showHelper", delay: 2200 }, // Helper added with 1.8s delay
      { key: "showWhyCoursevita", delay: 2000 },
      // { key: 'showLearningJourney', delay: 2500 },
      { key: "showTestimonials", delay: 2500 },
      { key: "showSubscribe", delay: 3000 },
      { key: "showBookYourDemo", delay: 3500 },
      { key: "showFooter", delay: 4000 },
    ];
    delays.forEach(({ key, delay }) => {
      setTimeout(() => {
        setSections((prevState) => ({ ...prevState, [key]: true }));
      }, delay);
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
    //7234F
    //setisLoading(false)
    return () => {
      //setisLoading(false)
      delays.forEach(({ delay }) => clearTimeout(delay));
    };
  }, []);
  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <Navbar />
          <ExploreSection />
          {/* Render components based on timers */}
          {sections.showWorkshops && <Workshops />}
          {sections.showHelper && <Helper />}{" "}
          {/* {sections.showCompanies && <Companies />} */}
          {sections.showCoursesSection && (
            <CoursesSection coursesContent={props.coursesContent} />
          )}
          {/* Conditional rendering for Helper */}
          {sections.showWhyCoursevita && <WhyCoursevita />}
          {/* {sections.showLearningJourney && <LearningJourney />} */}
          {/* Conditional rendering for Helper */}
          {sections.showTestimonials && <Testimonials />}
          {sections.showSubscribe && <Subscribe />}
          {sections.showBookYourDemo && <BookYourDemo />}
          {sections.showFooter && <Footer />}
        </>
      )}
    </div>
  );
};
export default LandingPage;
