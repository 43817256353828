import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import bgImg from "../../../../assets/images/findYourCareer/adBgImg.png"
import "./ExploreCoursesAd.scss"
const ExploreCoursesAd=()=>{
    const navigate = useNavigate();

	const buttonClick = () => {
	  sessionStorage.setItem("scrollToCourses", "true");
	  navigate("/", { state: { scrollToCourses: true } });
	};
    return(
        <Box className="exploreAdWrapper"
				sx={{
					backgroundImage: `url(${bgImg})`,
					borderRadius: { xs: "12px", md: "14px" },
				}}>
				<Box className="exploreContentOverlay" />
				<Box className="exploreContent">
					<Typography className="text1"><span  style={{fontSize:"22px", color:"#7234F7", fontWeight:500}}>Coding</span> got you feeling stuck?</Typography>
					<Typography className="text2">Shift gears with </Typography>
					<Typography className="text3"> our dynamic <span style={{fontSize:"16px", fontWeight:500}}>Data science courses!</span></Typography>
					<Button
						sx={{ padding: "10px 36px" }}
						className="expBtn"
						variant="contained"
						onClick={buttonClick}
					>
					Explore courses
					</Button>
				</Box>
			</Box>
    )
}

export default ExploreCoursesAd;