// import "./aistyles.scss";
// import React, { useState } from "react";
// import superman from "../../assets/images/ai-challenge/superman.png";
// import aiimg1 from "../../assets/images/ai-challenge/aiimg1.png";
// import aiimg2 from "../../assets/images/ai-challenge/aiimg2.png";
// import aiimg3 from "../../assets/images/ai-challenge/aiimg3.png";
// import aiimg4 from "../../assets/images/ai-challenge/aiimg4.png";
// import aiimg5 from "../../assets/images/ai-challenge/aiimg5.png";
// import prize from "../../assets/images/ai-challenge/prize_section.png";
// import certification from "../../assets/images/ai-challenge/certification.png";
// import badge from "../../assets/images/ai-challenge/badge.png";
// import present from "../../assets/images/ai-challenge/present.png";
// import left from "../../assets/images/ai-challenge/Left.svg";
// import horizontalLogo from "../../assets/images/footer/horizontalLogo.svg";
// import { Box, Dialog, DialogTitle, DialogContent, TextField, Button } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import axios from "axios"; // Import axios for API requests
// const AILandingPage = () => {
//   const navigate = useNavigate();
//   const [openDialog, setOpenDialog] = useState(false);
//   const [formData, setFormData] = useState({ fullname: '', email: '', phone: '' });
//   const [errorMessage, setErrorMessage] = useState(""); // State for error messages
//   const [successMessage, setSuccessMessage] = useState(""); // State for success message
//   const [openItemIndex, setOpenItemIndex] = useState(null); // State for FAQ item toggling
//   const faqs = [
//     {
//       q: "What is the AI Avatar Challenge?",
//       a: "The AI Avatar Challenge is a competition where participants create AI-generated avatars using various tools and technologies.",
//     },
//     {
//       q: "How do I enter the AI Avatar Challenge?",
//       a: "You can enter by signing up on the official website and following the instructions to submit your AI-generated avatar.",
//     },
//     {
//       q: "Is there an entry fee?",
//       a: "No, there is no entry fee to participate in the AI Avatar Challenge.",
//     },
//     {
//       q: "Who can participate in the challenge?",
//       a: "Anyone with a passion for AI and creativity can participate, regardless of experience level.",
//     },
//   ];
//   const onClickHandler = (event, navigationPath) => {
//     event.preventDefault();
//     navigate(navigationPath);
//   };
//   const handleDialogOpen = () => setOpenDialog(true);
//   const handleDialogClose = () => {
//     setOpenDialog(false);
//     setErrorMessage(""); // Clear error message on close
//     setSuccessMessage(""); // Clear success message on close
//   };
//   const handleInputChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };
//   const handleSubmit = async () => {
//     try {
//       // Clear any existing messages
//       setErrorMessage("");
//       setSuccessMessage("");
//       // Make POST request to backend API
//       const response = await axios.post("https://localhost:5002/forms/ai-challenge", formData);
//       // Check response status
//       if (response.status === 200) {
//         setSuccessMessage("Form submitted successfully!");
//         handleDialogClose();
//       }
//     } catch (error) {
//       // Error handling
//       if (error.response) {
//         setErrorMessage("There was an error submitting the form. Please try again.");
//       } else if (error.request) {
//         setErrorMessage("No response received from the server. Please try again.");
//       } else {
//         setErrorMessage("An unexpected error occurred.");
//       }
//     }
//   };
//   const toggleItem = (index) => {
//     setOpenItemIndex(openItemIndex === index ? null : index);
//   };
//   return (
//     <>
//       <Helmet>
//         <title>AI Avatar Challenge - Win Amazing Prizes</title>
//         <meta name="description" content="ai-avatar-challenge" />
//         <meta
//           name="keywords"
//           content="ai-avatar, ai-challenge, avatar contest, prizes"
//         />
//       </Helmet>
//       <div className="bg-white">
//         <Box
//           sx={{
//             width: "100%",
//             padding: "20px",
//             zIndex: "10",
//             position: "fixed",
//             backgroundColor: "white",
//           }}
//         >
//           <Box
//             component="img"
//             src={horizontalLogo}
//             sx={{
//               "&:hover": {
//                 cursor: "pointer",
//               },
//             }}
//             onClick={(e) => {
//               onClickHandler(e, "/");
//             }}
//           />
//         </Box>
//         <div className="main-content">
//           <div className="content-wrapper">
//             <div className="text-section">
//               <div className="heading">
//                 Unleash Your Creativity with AI Avatar Challenge!
//               </div>
//               <p className="description">
//                 Create, customize, and showcase your unique AI avatar for a
//                 chance to win amazing prizes.
//               </p>
//               <div className="cta-button" onClick={handleDialogOpen}>
//                 Join the Challenge
//               </div>
//             </div>
//             <div className="image-section">
//               <img
//                 src={superman}
//                 alt="Superman Before Avatar"
//                 width="756"
//                 height="522"
//                 className="avatar-image"
//               />
//             </div>
//           </div>
//           <div className="recent-avatars-section">
//             <h2 className="section-heading">Recent Avatars</h2>
//             <div className="subheading">
//               Tag us on social media for a chance to get featured
//             </div>
//             <div className="avatar-gallery">
//               <img
//                 src={aiimg1}
//                 alt="Avatar 1"
//                 width="200"
//                 height="200"
//                 className="avatar"
//               />
//               <img
//                 src={aiimg2}
//                 alt="Avatar 2"
//                 width="200"
//                 height="200"
//                 className="avatar"
//               />
//               <img
//                 src={aiimg3}
//                 alt="Avatar 3"
//                 width="200"
//                 height="200"
//                 className="avatar"
//               />
//               <img
//                 src={aiimg4}
//                 alt="Avatar 4"
//                 width="200"
//                 height="200"
//                 className="avatar"
//               />
//               <img
//                 src={aiimg5}
//                 alt="Avatar 5"
//                 width="200"
//                 height="200"
//                 className="avatar"
//               />
//             </div>
//           </div>
//           <div className="prizes-section">
//             <div className="prizes-heading">The Prizes</div>
//             <div className="prizes-content">
//               <div className="prize-image">
//                 <img src={prize} alt="Prize" className="prize-img" />
//               </div>
//               <div className="prizes-list">
//                 <div className="prize-item">
//                   <img
//                     src={badge}
//                     alt="1"
//                     width="75"
//                     height="75"
//                     className="prize-icon"
//                   />
//                   <div>
//                     <div className="prize-title">CASH PRIZE OF ₹1 LAKH</div>
//                     <p className="prize-description">
//                       The winner will receive a cash prize of ₹1 Lakh for
//                       creating an innovative AI avatar.
//                     </p>
//                   </div>
//                 </div>
//                 <div className="prize-item">
//                   <img
//                     src={present}
//                     alt="2"
//                     width="65"
//                     height="65"
//                     className="prize-icon2"
//                   />
//                   <div>
//                     <div className="prize-title">SHOWCASE YOUR AI AVATAR</div>
//                     <p className="prize-description">
//                       The Top 3 entries will be featured in front of leading
//                       industry experts during the mega event.
//                     </p>
//                   </div>
//                 </div>
//                 <div className="prize-item">
//                   <img
//                     src={certification}
//                     alt="3"
//                     width="75"
//                     height="75"
//                     className="prize-icon"
//                   />
//                   <div>
//                     <div className="prize-title">
//                       CERTIFICATE OF PARTICIPATION
//                     </div>
//                     <p className="prize-description">
//                       Top 10 participants will be awarded a certificate for
//                       their creativity and AI technology prowess.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="faq-section">
//             <h2 className="faq-heading">Frequently Asked Questions</h2>
//             <div className="faq-list">
//               {faqs.map((faq, index) => (
//                 <div key={index} className="faq-item">
//                   <div className="faq-title" onClick={() => toggleItem(index)}>
//                     {faq.q}
//                     <img
//                       src={left}
//                       alt={openItemIndex === index ? "collapse" : "expand"}
//                       className={openItemIndex === index ? "faq-icon rotate" : "faq-icon"}
//                     />
//                   </div>
//                   {openItemIndex === index && <div className="faq-answer">{faq.a}</div>}
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//       <Dialog open={openDialog} onClose={handleDialogClose}>
//         <DialogTitle>Join Our AI Challenge</DialogTitle>
//         <DialogContent>
//         <TextField
//             margin="dense"
//             label="Email"
//             name="email"
//             type="email"
//             value={formData.email}
//             onChange={handleInputChange}
//             fullWidth
//             variant="outlined"
//             required
//           />
//           <TextField
//             margin="dense"
//             label="Full Name"
//             name="fullname"
//             value={formData.fullname}
//             onChange={handleInputChange}
//             fullWidth
//             variant="outlined"
//             required
//           />
//           <TextField
//             margin="dense"
//             label="Mobile"
//             name="phone"
//             type="text"
//             value={formData.phone}
//             onChange={handleInputChange}
//             fullWidth
//             variant="outlined"
//             required
//           />
//           <Button
//             onClick={handleSubmit}
//             variant="contained"
//             color="primary"
//             fullWidth
//             style={{
//               backgroundColor: "#8554EF",
//               color: "#fff",
//               marginTop: "20px",
//             }}
//           >
//             Submit
//           </Button>
//           {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
//           {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// };
// export default AILandingPage;



// import "./aistyles.scss"; // Your custom styles
// import React, { useState } from "react";
// import superman from "../../assets/images/ai-challenge/superman.png";
// import aiimg1 from "../../assets/images/ai-challenge/aiimg1.png";
// import aiimg2 from "../../assets/images/ai-challenge/aiimg2.png";
// import aiimg3 from "../../assets/images/ai-challenge/aiimg3.png";
// import aiimg4 from "../../assets/images/ai-challenge/aiimg4.png";
// import aiimg5 from "../../assets/images/ai-challenge/aiimg5.png";
// import prize from "../../assets/images/ai-challenge/prize_section.png";
// import certification from "../../assets/images/ai-challenge/certification.png";
// import badge from "../../assets/images/ai-challenge/badge.png";
// import present from "../../assets/images/ai-challenge/present.png";
// import left from "../../assets/images/ai-challenge/Left.svg";
// import horizontalLogo from "../../assets/images/footer/horizontalLogo.svg";
// import { Box, Dialog, DialogTitle, DialogContent, TextField, Button } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import axios from "axios"; // Import axios for API requests

// const AILandingPage = () => {
//   const navigate = useNavigate();
//   const [openDialog, setOpenDialog] = useState(false);
//   const [formData, setFormData] = useState({ fullname: '', email: '', phone: '' });
//   const [errorMessage, setErrorMessage] = useState(""); // State for error messages
//   const [successMessage, setSuccessMessage] = useState(""); // State for success messages
//   const [openItemIndex, setOpenItemIndex] = useState(null); // State for FAQ item toggling

//   const faqs = [
//     {
//       q: "What is the AI Avatar Challenge?",
//       a: "The AI Avatar Challenge is a competition where participants create AI-generated avatars using various tools and technologies.",
//     },
//     {
//       q: "How do I enter the AI Avatar Challenge?",
//       a: "You can enter by signing up on the official website and following the instructions to submit your AI-generated avatar.",
//     },
//     {
//       q: "Is there an entry fee?",
//       a: "No, there is no entry fee to participate in the AI Avatar Challenge.",
//     },
//     {
//       q: "Who can participate in the challenge?",
//       a: "Anyone with a passion for AI and creativity can participate, regardless of experience level.",
//     },
//   ];

//   // Handle opening of form dialog
//   const handleDialogOpen = () => setOpenDialog(true);
//   const handleDialogClose = () => {
//     setOpenDialog(false);
//     setErrorMessage(""); // Clear error message on close
//     setSuccessMessage(""); // Clear success message on close
//   };

//   // Handle input change
//   const handleInputChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   // Form submission handler
//   const handleSubmit = async () => {
//     try {
//       // Clear any existing messages
//       setErrorMessage("");
//       setSuccessMessage("");
      
//       // Make POST request to backend API
//       const response = await axios.post("https://localhost:5002/forms/ai-challenge", formData, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         withCredentials: true, // Ensure cookies are included
//       });

//       // Check response status
//       if (response.status === 200) {
//         setSuccessMessage("Form submitted successfully!");
//         handleDialogClose();
//       }
//     } catch (error) {
//       // Error handling
//       if (error.response) {
//         setErrorMessage("There was an error submitting the form. Please try again.");
//       } else if (error.request) {
//         setErrorMessage("No response received from the server. Please check your connection and try again.");
//       } else {
//         setErrorMessage("An unexpected error occurred.");
//       }
//     }
//   };

//   // Handle FAQ toggling
//   const toggleItem = (index) => {
//     setOpenItemIndex(openItemIndex === index ? null : index);
//   };

//   return (
//     <>
//       <Helmet>
//         <title>AI Avatar Challenge - Win Amazing Prizes</title>
//         <meta name="description" content="AI Avatar Challenge" />
//         <meta name="keywords" content="ai-avatar, ai-challenge, avatar contest, prizes" />
//       </Helmet>
//       <div className="bg-white">
//         {/* Navbar */}
//         <Box
//           sx={{
//             width: "100%",
//             padding: "20px",
//             zIndex: "10",
//             position: "fixed",
//             backgroundColor: "white",
//           }}
//         >
//           <Box
//             component="img"
//             src={horizontalLogo}
//             sx={{
//               "&:hover": {
//                 cursor: "pointer",
//               },
//             }}
//             onClick={(e) => {
//               navigate("/");
//             }}
//           />
//         </Box>

//         {/* Main Content */}
//         <div className="main-content">
//           <div className="content-wrapper">
//             <div className="text-section">
//               <div className="heading">Unleash Your Creativity with AI Avatar Challenge!</div>
//               <p className="description">
//                 Create, customize, and showcase your unique AI avatar for a chance to win amazing prizes.
//               </p>
//               <div className="cta-button" onClick={handleDialogOpen}>
//                 Join the Challenge
//               </div>
//             </div>
//             <div className="image-section">
//               <img src={superman} alt="Superman Before Avatar" width="756" height="522" className="avatar-image" />
//             </div>
//           </div>

//           {/* Recent Avatars Section */}
//           <div className="recent-avatars-section">
//             <h2 className="section-heading">Recent Avatars</h2>
//             <div className="subheading">Tag us on social media for a chance to get featured</div>
//             <div className="avatar-gallery">
//               <img src={aiimg1} alt="Avatar 1" width="200" height="200" className="avatar" />
//               <img src={aiimg2} alt="Avatar 2" width="200" height="200" className="avatar" />
//               <img src={aiimg3} alt="Avatar 3" width="200" height="200" className="avatar" />
//               <img src={aiimg4} alt="Avatar 4" width="200" height="200" className="avatar" />
//               <img src={aiimg5} alt="Avatar 5" width="200" height="200" className="avatar" />
//             </div>
//           </div>

//           {/* Prizes Section */}
//           <div className="prizes-section">
//             <div className="prizes-heading">The Prizes</div>
//             <div className="prizes-content">
//               <div className="prize-image">
//                 <img src={prize} alt="Prize" className="prize-img" />
//               </div>
//               <div className="prizes-list">
//                 <div className="prize-item">
//                   <img src={badge} alt="Cash Prize" width="75" height="75" className="prize-icon" />
//                   <div>
//                     <div className="prize-title">CASH PRIZE OF ₹1 LAKH</div>
//                     <p className="prize-description">
//                       The winner will receive a cash prize of ₹1 Lakh for creating an innovative AI avatar.
//                     </p>
//                   </div>
//                 </div>
//                 <div className="prize-item">
//                   <img src={present} alt="Showcase" width="65" height="65" className="prize-icon2" />
//                   <div>
//                     <div className="prize-title">SHOWCASE YOUR AI AVATAR</div>
//                     <p className="prize-description">
//                       The Top 3 entries will be featured in front of leading industry experts during the mega event.
//                     </p>
//                   </div>
//                 </div>
//                 <div className="prize-item">
//                   <img src={certification} alt="Certificate" width="75" height="75" className="prize-icon" />
//                   <div>
//                     <div className="prize-title">CERTIFICATE OF PARTICIPATION</div>
//                     <p className="prize-description">
//                       Top 10 participants will be awarded a certificate for their creativity and AI technology prowess.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* FAQ Section */}
//           <div className="faq-section">
//             <h2 className="faq-heading">Frequently Asked Questions</h2>
//             <div className="faq-list">
//               {faqs.map((faq, index) => (
//                 <div key={index} className="faq-item">
//                   <div className="faq-title" onClick={() => toggleItem(index)}>
//                     {faq.q}
//                     <img
//                       src={left}
//                       alt={openItemIndex === index ? "collapse" : "expand"}
//                       className={openItemIndex === index ? "faq-icon rotate" : "faq-icon"}
//                     />
//                   </div>
//                   {openItemIndex === index && <p className="faq-answer">{faq.a}</p>}
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* Footer Section */}
//           <div className="footer-section">
//             <Box component="img" src={horizontalLogo} sx={{ padding: "20px" }} />
//           </div>

//           {/* Form Dialog */}
//           <Dialog open={openDialog} onClose={handleDialogClose}>
//             <DialogTitle>Join the AI Avatar Challenge</DialogTitle>
//             <DialogContent>
//               <TextField
//                 name="fullname"
//                 label="Full Name"
//                 fullWidth
//                 margin="normal"
//                 onChange={handleInputChange}
//               />
//               <TextField
//                 name="email"
//                 label="Email"
//                 fullWidth
//                 margin="normal"
//                 onChange={handleInputChange}
//               />
//               <TextField
//                 name="phone"
//                 label="Phone Number"
//                 fullWidth
//                 margin="normal"
//                 onChange={handleInputChange}
//               />
//               <Button variant="contained" color="primary" onClick={handleSubmit}>
//                 Submit
//               </Button>

//               {/* Display error or success messages */}
//               {errorMessage && <p className="error-message">{errorMessage}</p>}
//               {successMessage && <p className="success-message">{successMessage}</p>}
//             </DialogContent>
//           </Dialog>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AILandingPage;

import "./aistyles.scss"; // Your custom styles
import React, { useState } from "react";
import superman from "../../assets/images/ai-challenge/superman.png";
import aiimg1 from "../../assets/images/ai-challenge/aiimg1.png";
import aiimg2 from "../../assets/images/ai-challenge/aiimg2.png";
import aiimg3 from "../../assets/images/ai-challenge/aiimg3.png";
import aiimg4 from "../../assets/images/ai-challenge/aiimg4.png";
import aiimg5 from "../../assets/images/ai-challenge/aiimg5.png";
import prize from "../../assets/images/ai-challenge/prize_section.png";
import certification from "../../assets/images/ai-challenge/certification.png";
import badge from "../../assets/images/ai-challenge/badge.png";
import present from "../../assets/images/ai-challenge/present.png";
import left from "../../assets/images/ai-challenge/Left.svg";
import horizontalLogo from "../../assets/images/footer/horizontalLogo.svg";
import { Box, Dialog, DialogTitle, DialogContent, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios"; // Import axios for API requests

const AILandingPage = () => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({  email: '',fullname: '', phone: '' });
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const [successMessage, setSuccessMessage] = useState(""); // State for success messages
  const [openItemIndex, setOpenItemIndex] = useState(null); // State for FAQ item toggling

  const faqs = [
    {
      q: "What is the AI Avatar Challenge?",
      a: "The AI Avatar Challenge is a competition where participants create AI-generated avatars using various tools and technologies.",
    },
    {
      q: "How do I enter the AI Avatar Challenge?",
      a: "You can enter by signing up on the official website and following the instructions to submit your AI-generated avatar.",
    },
    {
      q: "Is there an entry fee?",
      a: "No, there is no entry fee to participate in the AI Avatar Challenge.",
    },
    {
      q: "Who can participate in the challenge?",
      a: "Anyone with a passion for AI and creativity can participate, regardless of experience level.",
    },
  ];

  // Handle opening of form dialog
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setErrorMessage(""); // Clear error message on close
    setSuccessMessage(""); // Clear success message on close
  };

  // Handle input change
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Form submission handler
  const handleSubmit = async () => {
    try {
      // Clear any existing messages
      setErrorMessage("");
      setSuccessMessage("");
      
      // Make POST request to backend API
      const response = await axios.post(
        `http://localhost:5002/forms/ai-challenge`, // Using environment variable
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          // Ensure cookies are included
        }
      );

      // Check response status
      if (response.status === 200) {
        
        setSuccessMessage("Form submitted successfully!");
        handleDialogClose();
      }
    } catch (error) {
      // Error handling
      if (error.response) {
        setErrorMessage("There was an error submitting the form. Please try again.");
      } else if (error.request) {
        setErrorMessage("No response received from the server. Please check your connection and try again.");
        console.log(error)
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };

  // Handle FAQ toggling
  const toggleItem = (index) => {
    setOpenItemIndex(openItemIndex === index ? null : index);
  };

  return (
    <>
      <Helmet>
        <title>AI Avatar Challenge - Win Amazing Prizes</title>
        <meta name="description" content="AI Avatar Challenge" />
        <meta name="keywords" content="ai-avatar, ai-challenge, avatar contest, prizes" />
      </Helmet>
      <div className="bg-white">
        {/* Navbar */}
        <Box
          sx={{
            width: "100%",
            padding: "20px",
            zIndex: "10",
            position: "fixed",
            backgroundColor: "white",
          }}
        >
          <Box
            component="img"
            src={horizontalLogo}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={(e) => {
              navigate("/");
            }}
          />
        </Box>

        {/* Main Content */}
        <div className="main-content">
          <div className="content-wrapper">
            <div className="text-section">
              <div className="heading">Unleash Your Creativity with AI Avatar Challenge!</div>
              <p className="description">
                Create, customize, and showcase your unique AI avatar for a chance to win amazing prizes.
              </p>
              <div className="cta-button" onClick={handleDialogOpen}>
                Join the Challenge
              </div>
            </div>
            <div className="image-section">
              <img src={superman} alt="Superman Before Avatar" width="756" height="522" className="avatar-image" />
            </div>
          </div>

          {/* Recent Avatars Section */}
          <div className="recent-avatars-section">
            <h2 className="section-heading">Recent Avatars</h2>
            <div className="subheading">Tag us on social media for a chance to get featured</div>
            <div className="avatar-gallery">
              <img src={aiimg1} alt="Avatar 1" width="200" height="200" className="avatar" />
              <img src={aiimg2} alt="Avatar 2" width="200" height="200" className="avatar" />
              <img src={aiimg3} alt="Avatar 3" width="200" height="200" className="avatar" />
              <img src={aiimg4} alt="Avatar 4" width="200" height="200" className="avatar" />
              <img src={aiimg5} alt="Avatar 5" width="200" height="200" className="avatar" />
            </div>
          </div>

          {/* Prizes Section */}
          <div className="prizes-section">
            <div className="prizes-heading">The Prizes</div>
            <div className="prizes-content">
              <div className="prize-image">
                <img src={prize} alt="Prize" className="prize-img" />
              </div>
              <div className="prizes-list">
                <div className="prize-item">
                  <img src={badge} alt="Cash Prize" width="75" height="75" className="prize-icon" />
                  <div>
                    <div className="prize-title">CASH PRIZE OF ₹1 LAKH</div>
                    <p className="prize-description">
                      The winner will receive a cash prize of ₹1 Lakh for creating an innovative AI avatar.
                    </p>
                  </div>
                </div>
                <div className="prize-item">
                  <img src={present} alt="Showcase" width="65" height="65" className="prize-icon2" />
                  <div>
                    <div className="prize-title">SHOWCASE YOUR AI AVATAR</div>
                    <p className="prize-description">
                      The Top 3 entries will be featured in front of leading industry experts during the mega event.
                    </p>
                  </div>
                </div>
                <div className="prize-item">
                  <img src={certification} alt="Certificate" width="75" height="75" className="prize-icon" />
                  <div>
                    <div className="prize-title">CERTIFICATE OF PARTICIPATION</div>
                    <p className="prize-description">
                      Top 10 participants will be awarded a certificate for their creativity and AI technology prowess.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FAQ Section */}
          <div className="faq-section">
            <h2 className="faq-heading">Frequently Asked Questions</h2>
            <div className="faq-list">
              {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                  <div className="faq-title" onClick={() => toggleItem(index)}>
                    {faq.q}
                    <img
                      src={left}
                      alt={openItemIndex === index ? "collapse" : "expand"}
                      className={openItemIndex === index ? "faq-icon rotate" : "faq-icon"}
                    />
                  </div>
                  {openItemIndex === index && <p className="faq-answer">{faq.a}</p>}
                </div>
              ))}
            </div>
          </div>

          {/* Footer Section */}
          <div className="footer-section">
            <Box component="img" src={horizontalLogo} sx={{ padding: "20px" }} />
          </div>

          {/* Form Dialog */}
          <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle>Join the AI Avatar Challenge</DialogTitle>
            <DialogContent>
            <TextField
                name="email"
                label="Email"
                fullWidth
                margin="normal"
                onChange={handleInputChange}
              />
              <TextField
                name="fullname"
                label="Full Name"
                fullWidth
                margin="normal"
                onChange={handleInputChange}
              />
             
              <TextField
                name="phone"
                label="Phone Number"
                fullWidth
                margin="normal"
                onChange={handleInputChange}
              />
              <Button variant="contained" color="primary"  style={{
          backgroundColor: "#8554EF",
          color: "#fff",
          marginTop: "20px",
            }} onClick={handleSubmit}>
                Submit
              </Button>

              {/* Display error or success messages */}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              {successMessage && <p className="success-message">{successMessage}</p>}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default AILandingPage;

