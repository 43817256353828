import "./last.scss";
import { Avatar, AvatarGroup, Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import girlImage from "../../../assets/images/mockGroupDiscussion/lastLayer/girl.png";
import boyImage from "../../../assets/images/mockGroupDiscussion/lastLayer/boy.png";
import av1 from "../../../assets/images/mockGroupDiscussion/lastLayer/av1.png";
import av2 from "../../../assets/images/mockGroupDiscussion/lastLayer/av2.png";
import av3 from "../../../assets/images/mockGroupDiscussion/lastLayer/av3.png";
import av4 from "../../../assets/images/mockGroupDiscussion/lastLayer/av4.png";

import rightWing from "../../../assets/images/mockGroupDiscussion/lastLayer/rightWing.svg";
import leftWing from "../../../assets/images/mockGroupDiscussion/lastLayer/leftWing.svg";

const Last = () => {
  const [isIOnToOneframeVisible, setIsIOnToOneframeVisible] = useState(false);
  const handleCloseClick = () => {
    setIsIOnToOneframeVisible(false);
  };

  return (
    <Box className="wrapper-top-last">
      <Box className="wrapper-1-last">
        <Box className="firstImage-last ">
          <Box className="firstImage-line-last"></Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="start"
            gap="5px"
          >
            <AvatarGroup>
              <Avatar alt="a1" className="av-i" src={av1}></Avatar>
              <Avatar alt="a2" className="av-i" src={av2}></Avatar>
              <Avatar alt="a3" className="av-i" src={av3}></Avatar>
              <Avatar alt="a4" className="av-i" src={av4}></Avatar>
            </AvatarGroup>
            <Typography className="firstImage-text-last">
              Your not the only among them!!
            </Typography>
          </Box>
        </Box>
        <Box className="secondImage-last" width="200px" height="auto">
          <Box className="rightWing-last" component="img" src={rightWing}></Box>
          <Box className="IIndimage-last" component="img" src={girlImage}></Box>
          <Box className="leftWing-last" component="img" src={leftWing}></Box>
        </Box>
        <Box className="thirdImage-last" width="70px" height="auto">
          <Box className="IIIrdImage-last" component="img" src={boyImage}></Box>
        </Box>
      </Box>
      <Box className="wrapper-2-last">
        <Typography className="header-text-last">
          {" "}
          You’re <span style={{ color: "black" }}>Not Alone </span>{" "}
        </Typography>
        <Typography className="normal-text-last">
          In group discussions, you’re part of a supportive community. Share
          your thoughts, gain new insights, and stay motivated as we help each
          other to reach their goals.
        </Typography>
        <Button
          onClick={() => {
            setIsIOnToOneframeVisible(true);
          }}
          className="btn-last"
        >
          Get Started
        </Button>
        {isIOnToOneframeVisible && (
          <div className="iframe-popup">
            <span className="iframe-popup-close" onClick={handleCloseClick}>
              &times;
            </span>
            <iframe
              width="100%"
              height="100%"
              src="https://talk.coursevita.com/portal-embed#/237817000000078002"
              frameborder="0"
              allowfullscreen=""
            >
              {" "}
            </iframe>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Last;
