// import {
//   Box,
//   Button,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   // MenuItem,
//   // Select,
//   TextField,
//   Typography,
// } from "@mui/material";
// import Cancel from "../../../assets/images/coursePreview/Cancel.svg";
// import React, { useState } from "react";
// import "./ApplyNowDialog.scss";
// import { useNavigate } from "react-router-dom";
// import { isEmpty } from "lodash";
// import axios from "axios";

// function ApplyNowDialog(props) {
//   const navigate = useNavigate();

//   const onClickHandler = (event, navigationPath) => {
//     event.preventDefault();
//     navigate(navigationPath);
//     window.scrollTo(0, 0);
//   };
//   const { handleCloseDialog, courseTitle, courseLink } = props;
//   const [name, setName] = useState("");
//   const [emailId, setEmailId] = useState("");
//   const [mobileNum, setMobileNum] = useState("");
//   // const [course, setCourse] = useState("");
//   // const coursesOptions = [
//   //   "Data Science",
//   //   "Data Analytics",
//   //   "Data Visualization",
//   // ];
//   const [message, setMessage] = useState("");
//   const [loading, setLoading] = useState(false);

//   const handleDownload = async () => {
//     setLoading(true);
//     try {
//       await axios.post(
//         `${process.env.REACT_APP_BACKEND_LINK}/api/course/applyNow`,
//         {
//           fullName: name,
//           emailId: emailId,
//           number: mobileNum,
//           courseName: courseTitle,
//         }
//       );
//       setName("");
//       setEmailId("");
//       setMobileNum("");
//       // setCourse('');
//       window.open(courseLink, "_blank");
//       handleCloseDialog();
//       setTimeout(() => {
//         setMessage("");
//       }, 3000);
//     } catch (error) {
//       console.error(
//         "Error subscribing:",
//         error.response?.data?.message || error.message
//       );
//     } finally {
//       setLoading(false);
//     }
//   };

//   const validation = () => {
//     const mobileNumRegex = /^\d{10}$/;
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (isEmpty(name)) {
//       setMessage("Please enter Full name");
//       setLoading(false);
//     } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
//       setMessage("Please enter valid Email Id");
//       return false;
//     } else if (
//       isEmpty(mobileNum) ||
//       mobileNum.length !== 10 ||
//       !mobileNumRegex.test(mobileNum)
//     ) {
//       setMessage("Please enter valid mobile number");
//       return false;
//     }
//     // else if (isEmpty(course)) {
//     //   setMessage("Please select course");
//     //   return false;
//     // }
//     else {
//       setMessage("");
//       console.log("handleDownload");
//       handleDownload();
//       return true;
//     }
//   };

//   return (
//     <>
//       <Box sx={{ width: "100%" }} className="curriculumWrapper">
//         <Box
//           className="curriculumContent"
//           sx={{
//             borderRadius: { md: "25px 25px 0px 0px", xs: "12px" },
//           }}
//         >
//           <Box
//             component="img"
//             src={Cancel}
//             sx={{ p: "16px 16px 0px", cursor: "pointer" }}
//             onClick={handleCloseDialog}
//           />
//           <Box
//             className="contentform"
//             sx={{ p: { xs: "0 16px", md: "0 48px 32px" }, mt: "-10px" }}
//           >
//             <Typography className="curriculumHeader" sx={{ mb: "4px" }}>
//             Start Your Application Process
//             </Typography>
//             <Box className="formInput">
//               <Typography className="formInputHeader">Full Name*</Typography>
//               <TextField
//                 id="outlined-basic"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 variant="outlined"
//                 className="formText"
//               />
//             </Box>
//             <Box className="formInput">
//               <Typography className="formInputHeader">Email ID*</Typography>
//               <TextField
//                 id="outlined-basic"
//                 value={emailId}
//                 onChange={(e) => setEmailId(e.target.value)}
//                 variant="outlined"
//                 fullWidth
//                 className="formText"
//               />
//             </Box>
//             <Box className="formInput">
//               <Typography className="formInputHeader">
//                 Mobile Number*
//               </Typography>
//               <TextField
//                 id="outlined-basic"
//                 value={mobileNum}
//                 onChange={(e) => setMobileNum(e.target.value)}
//                 variant="outlined"
//                 className="formText"
//               />
//             </Box>
//             {/* <Box className="formInput">
//               <Typography className="formInputHeader">
//                 Select Courses*
//               </Typography>
//               <Select
//                 id="demo-simple-select"
//                 fullWidth
//                 displayEmpty
//                 className="formText selectForm"
//                 renderValue={(selected) => {
//                   if (selected === "") {
//                     return <>--Select--</>;
//                   }
//                   return selected;
//                 }}
//                 value={course}
//                 onChange={(e) => setCourse(e.target.value)}
//               >
//                 <MenuItem disabled value="">
//                   --Select--
//                 </MenuItem>
//                 {coursesOptions.map((option) => (
//                   <MenuItem key={option} value={option}>
//                     {option}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Box> */}
//             {message && (
//               <Typography className="freeTagline" sx={{ color: "red" }}>
//                 {message}
//               </Typography>
//             )}
//             <Button
//               variant="contained"
//               className="contactButton"
//               onClick={validation}
//               disabled={loading}
//             >
//               {/* <span style={{ color: 'white' }}>{loading ? "Downloading..." : "Download"}</span> */}
//               <span style={{ color: "white" }}>Apply Now</span>
//             </Button>
//             <Typography className="tagline">
//               By filling this form, you agree to our{" "}
//               <a
//                 className="terms"
//                 href="/terms"
//                 onClick={(e) => onClickHandler(e, "/terms")}
//               >
//                 Terms and conditions
//               </a>
//             </Typography>
//           </Box>
//         </Box>
//       </Box>
//     </>
//   );
// }

// export default ApplyNowDialog;
import {
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Cancel from "../../../assets/images/coursePreview/Cancel.svg";
import React, { useState } from "react";
import "./ApplyNowDialog.scss";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import axios from "axios";

function ApplyNowDialog(props) {
  const navigate = useNavigate();

  const onClickHandler = (event, navigationPath) => {
    event.preventDefault();
    navigate(navigationPath);
    window.scrollTo(0, 0);
  };

  const { handleCloseDialog, courseTitle, courseLink } = props;
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_LINK}/api/course/applyNow`,
        {
          fullName: name,
          emailId: emailId,
          number: mobileNum,
          courseName: courseTitle,
        }
      );
      setName("");
      setEmailId("");
      setMobileNum("");
      window.open(courseLink, "_blank");
      handleCloseDialog();
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } catch (error) {
      console.error(
        "Error subscribing:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const validation = () => {
    const mobileNumRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (isEmpty(name)) {
      setMessage("Please enter Full name");
      setLoading(false);
    } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
      setMessage("Please enter valid Email Id");
      return false;
    } else if (
      isEmpty(mobileNum) ||
      mobileNum.length !== 10 ||
      !mobileNumRegex.test(mobileNum)
    ) {
      setMessage("Please enter valid mobile number");
      return false;
    } else {
      setMessage("");
      handleDownload();
      return true;
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }} className="curriculumWrapper">
        <Box
          className="curriculumContent"
          sx={{
            borderRadius: { md: "25px 25px 0px 0px", xs: "12px" },
          }}
        >
          <Box
            component="img"
            src={Cancel}
            sx={{ p: "16px 16px 0px", cursor: "pointer" }}
            onClick={handleCloseDialog}
          />
          <Box
            className="contentform"
            sx={{ p: { xs: "0 16px", md: "0 48px 32px" }, mt: "-10px" }}
          >
            <Typography className="curriculumHeader" sx={{ mb: "4px" }}>
              Start Your Application Process
            </Typography>
            <Box className="formInput">
              <Typography className="formInputHeader">Full Name*</Typography>
              <TextField
                id="outlined-basic"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                className="formText"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#7233f7', // Set the border color when focused
                    },
                  },
                }}
              />
            </Box>
            <Box className="formInput">
              <Typography className="formInputHeader">Email ID*</Typography>
              <TextField
                id="outlined-basic"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                variant="outlined"
                fullWidth
                className="formText"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#7233f7', // Set the border color when focused
                    },
                  },
                }}
              />
            </Box>
            <Box className="formInput">
              <Typography className="formInputHeader">
                Mobile Number*
              </Typography>
              <TextField
                id="outlined-basic"
                value={mobileNum}
                onChange={(e) => setMobileNum(e.target.value)}
                variant="outlined"
                className="formText"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#7233f7', // Set the border color when focused
                    },
                  },
                }}
              />
            </Box>
            {message && (
              <Typography className="freeTagline" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
            <Button
              variant="contained"
              className="contactButton"
              onClick={validation}
              disabled={loading}
            >
              <span style={{ color: "white" }}>Apply Now</span>
            </Button>
            <Typography className="tagline">
              By filling this form, you agree to our{" "}
              <a
                className="terms"
                href="/terms"
                onClick={(e) => onClickHandler(e, "/terms")}
              >
                Terms and conditions
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ApplyNowDialog;
