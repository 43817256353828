import React from "react";
import Landing from "./landing/Landing";
import Questions from "./questions/Questions";
import { useState } from "react";
const SkillAssessment = () => {
  const [isLanding, setIsLanding] = useState(true);

  return (
    <>
      {isLanding && <Landing setIsLanding={setIsLanding} />}
      {!isLanding && <Questions setIsLanding={setIsLanding} />}
    </>
  );
};

export default SkillAssessment;
