import React, { useState, useEffect } from "react";

const AnimatedTypography = ({ children }) => {
  const [count, setCount] = useState(0);
  const targetCount = parseInt(children, 10) || 0;

  useEffect(() => {
    const animateCount = () => {
      const duration = 3000; // Animation duration in milliseconds
      const start = performance.now();
      const updateCount = (timestamp) => {
        const progress = (timestamp - start) / duration;
        if (progress < 1) {
          setCount(Math.floor(progress * targetCount));
          requestAnimationFrame(updateCount);
        } else {
          setCount(targetCount);
        }
      };
      requestAnimationFrame(updateCount);
    };
    animateCount();
    return () => {
      setCount(0);
    };
  }, [targetCount]);
  return count;
};

export default AnimatedTypography;
