// import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import "../courseCard/CourseCard.scss";
// import docsIcon from "../../../../assets/images/coursesSection/docsIcon.svg";
// import modeOfDelivery from "../../../../assets/images/coursesSection/modeOfDelivery.svg";
// import calender from "../../../../assets/images/coursesSection/calender.svg";
// import Download from "../../../../assets/images/coursesSection/Download.svg";
// import Curriculum from "../../../commonComponents/curriculum/Curriculum";
// import { useState } from "react";

// const CourseCard = ({ courseContent }) => {
//   const { course, imageSource, period, mode, resource, learnmoreCourse } =
//     courseContent;
//   const navigate = useNavigate();
//   const handleLearnMoreClick = () => {
//     navigate(`/courses/${learnmoreCourse}`);
//   };
//   const [dialogOpen, setDialogOpen] = useState(false);

//   const handleOpenDialog = () => {
//     setDialogOpen(true);
//   };
//   const handleCloseDialog = () => {
//     setDialogOpen(false);
//   };

//   return (
//     <Box
//       className="courseCardWrapper"
//       sx={{ width: { sm: "320px", xs: "100%" } }}
//     >
//       <Box className="imageContainer">
//         <img
//           style={{ width: "100%", height: "100%", objectFit: "cover" }}
//           src={imageSource}
//           alt={course}
//         />
//       </Box>
//       <Box className="courseContainer">
//         <Typography className="placementTag">
//           100% Placement assistance
//         </Typography>
//         <Typography className="courseName"> {course}</Typography>
//         <Box className="listContent">
//           <img src={calender} alt="" />
//           <Typography className="label">{period}</Typography>
//         </Box>
//         <Box className="listContent">
//           <img src={modeOfDelivery} alt="" />
//           <Typography className="label">{mode}</Typography>
//         </Box>
//         <Box className="listContent">
//           <img src={docsIcon} alt="" />
//           <Typography className="label">{resource}</Typography>
//         </Box>
//       </Box>
//       <Box className="buttonContainer">
//         <Button
//           onClick={handleLearnMoreClick}
//           variant="contained"
//           className="button learnmore"
//           sx={{ background: "#EAE1FF" }}
//         >
//           Learn more
//         </Button>
//         <Button
//           variant="contained"
//           className="button"
//           sx={{ background: "#7234F7 !important", color: "#fff !important" }}
//           startIcon={<img src={Download} alt="download-icon" />}
//           onClick={handleOpenDialog}
//         >
//           Curriculum
//         </Button>
//         <Dialog
//           onClose={handleCloseDialog}
//           open={dialogOpen}
//           PaperProps={{
//             sx: {
//               width: { xs: "100%", md: "40%" },
//               m: { xs: "16px", md: "0px" },
//               borderRadius: "24px",
//               backgroundColor: "#FFF",
//               boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.08)",
//               minHeight: "380px",
//               fontFamily: '"Outfit", sans-serif !important',
//               border: "1px solid #C7C7C7",
//               pb: { xs: "10px", md: "10px" },
//             },
//           }}
//         >
//           <DialogContent sx={{ p: "0" }}>
//             <Curriculum handleCloseDialog={handleCloseDialog} courseContent={courseContent} />
//           </DialogContent>
//         </Dialog>
//       </Box>
//     </Box>
//   );
// };

// export default CourseCard;


import React, { lazy, Suspense, useState } from "react";
import { Box, Button, Dialog, DialogContent, Typography, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../courseCard/CourseCard.scss";
import docsIcon from "../../../../assets/images/coursesSection/docsIcon.svg";
import modeOfDelivery from "../../../../assets/images/coursesSection/modeOfDelivery.svg";
import calender from "../../../../assets/images/coursesSection/calender.svg";
import Download from "../../../../assets/images/coursesSection/Download.svg";

const Curriculum = lazy(() => import("../../../commonComponents/curriculum/Curriculum"));

const CourseCard = ({ courseContent }) => {
  const { course, imageSource, period, mode, resource, learnmoreCourse } =
    courseContent;
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleLearnMoreClick = () => {
    navigate(`/courses/${learnmoreCourse}`);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box
      className="courseCardWrapper"
      sx={{ width: { sm: "320px", xs: "100%" } }}
    >
      <Box className="imageContainer" sx={{ position: "relative" }}>
        {!imageLoaded && (
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height="100%"
            sx={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}
          />
        )}
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover", display: imageLoaded ? 'block' : 'none' }}
          src={imageSource}
          alt={course}
          onLoad={() => setImageLoaded(true)}
        />
      </Box>
      <Box className="courseContainer">
        {course ? (
          <Typography className="placementTag">
            100% Placement assistance
          </Typography>
        ) : (
          <Skeleton variant="text" animation="wave" width="60%" />
        )}
        {course ? (
          <Typography className="courseName">{course}</Typography>
        ) : (
          <Skeleton variant="text" animation="wave" width="80%" />
        )}
        
        <Box className="listContent">
          <img src={calender} alt="" />
          {period ? (
            <Typography className="label">{period}</Typography>
          ) : (
            <Skeleton variant="text" animation="wave" width="50%" />
          )}
        </Box>
        <Box className="listContent">
          <img src={modeOfDelivery} alt="" />
          {mode ? (
            <Typography className="label">{mode}</Typography>
          ) : (
            <Skeleton variant="text" animation="wave" width="50%" />
          )}
        </Box>
        <Box className="listContent">
          <img src={docsIcon} alt="" />
          {resource ? (
            <Typography className="label">{resource}</Typography>
          ) : (
            <Skeleton variant="text" animation="wave" width="50%" />
          )}
        </Box>
      </Box>
      <Box className="buttonContainer">
        <Button
          onClick={handleLearnMoreClick}
          variant="contained"
          className="button learnmore"
          sx={{ background: "#EAE1FF" }}
        >
          Learn more
        </Button>
        <Button
          variant="contained"
          className="button"
          sx={{ background: "#7234F7 !important", color: "#fff !important" }}
          startIcon={<img src={Download} alt="download-icon" />}
          onClick={handleOpenDialog}
        >
          Curriculum
        </Button>
        <Dialog
          onClose={handleCloseDialog}
          open={dialogOpen}
          PaperProps={{
            sx: {
              width: { xs: "100%", md: "40%" },
              m: { xs: "16px", md: "0px" },
              borderRadius: "24px",
              backgroundColor: "#FFF",
              boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.08)",
              minHeight: "380px",
              fontFamily: '"Outfit", sans-serif !important',
              border: "1px solid #C7C7C7",
              pb: { xs: "10px", md: "10px" },
            },
          }}
        >
          <DialogContent sx={{ p: "0" }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Curriculum handleCloseDialog={handleCloseDialog} courseContent={courseContent} />
            </Suspense>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default CourseCard;
