import "./last.scss";
import { Box, Button, Typography } from "@mui/material";

const Last = ({ targetJoinNow }) => {
  return (
    <Box className="wrapper-last">
      <Typography className="header-last">What are you waiting for</Typography>
      <Typography className="normal-last">
        Join our career counseling and mock interviews today and take the first
        step toward your dream career!
      </Typography>
      <Button
        onClick={() => {
          if (targetJoinNow.current) {
            targetJoinNow.current.scrollIntoView({ behavior: "smooth" });
          }
        }}
        className="btn-last"
      >
        Join Now
      </Button>
    </Box>
  );
};

export default Last;
