// import { Box, Button, Typography } from "@mui/material";
// import "../whyCoursevita/WhyCoursevita.scss";
// import kidWithPencilIcon from "../../../assets/images/whyCoursevita/kidWithPencil.svg";
// import curriculumIcon from "../../../assets/images/whyCoursevita/curriculam.svg";
// import bookIcon from "../../../assets/images/whyCoursevita/book.svg";
// import lecturerIcon from "../../../assets/images/whyCoursevita/lecturer.svg";
// import gamingIcon from "../../../assets/images/whyCoursevita/gaming.svg";
// import pencilIcon from "../../../assets/images/whyCoursevita/pencil.svg";
// // import call from "../../../assets/images/whyCoursevita/call.svg";
// import cubes from "../../../assets/images/whyCoursevita/cubes.svg";
// import portfolioIcon from "../../../assets/images/whyCoursevita/portfolio.svg";
// import certificateIcon from "../../../assets/images/whyCoursevita/certificate.svg";
// import curriculumGif from "../../../assets/images/whyCoursevita/Gifs/curriculam.gif";
// import bookGif from "../../../assets/images/whyCoursevita/Gifs/book.gif";
// import lecturerGif from "../../../assets/images/whyCoursevita/Gifs/lecturer.gif";
// import gamingGif from "../../../assets/images/whyCoursevita/Gifs/gaming.gif";
// import portfolioGif from "../../../assets/images/whyCoursevita/Gifs/portfolio.gif";
// import certificateGif from "../../../assets/images/whyCoursevita/Gifs/certificate.gif";
// import kidWithPencilfilled from "../../../assets/images/whyCoursevita/kidwithpencilfilled.svg";
// import Frame from "./frame/Frame";
// import { useState } from "react";

// const WhyCoursevita = () => {
//   const [src, setSrc] = useState(kidWithPencilIcon);

//   const WhyCoursevitadata = [
//     {
//       label: "Learn from top industry experts",
//       subText:
//         "Our expert instructors bring real-world experience to every course.",
//       icon: lecturerIcon,
//       gif: lecturerGif,
//     },
//     {
//       label: "Personalised Doubts Clearing window",
//       subText: "Get your questions answered quickly for better understanding.",
//       icon: bookIcon,
//       gif: bookGif,
//     },
//     {
//       label: "Prepare with Mock Interview Sessions",
//       subText: "Practice real-world scenarios to boost your confidence.",
//       icon: curriculumIcon,
//       gif: curriculumGif,
//     },
//     {
//       label: "Gamified Learning",
//       subText:
//         "Our gamified learning approach maximizes engagement and retention.",
//       icon: gamingIcon,
//       gif: gamingGif,
//     },
//     {
//       label: "Earn certificate",
//       subText: "Unlock a certificate to showcase your achievement!",
//       icon: certificateIcon,
//       gif: certificateGif,
//     },
//     {
//       label: "CV & Portfolio",
//       subText:
//         "Crafting tailored CVs and portfolios to highlight your skills and achievements.",
//       icon: portfolioIcon,
//       gif: portfolioGif,
//     },
//   ];
//   return (
//     <Box className="whyCoursevita" sx={{backgroundColor:"#F4F4FE"}}>
//       <Box className="header" sx={{ mb: { md: "48px", xs: "30px" } }}>
//         <Typography
//           className="headText"
//           sx={{
//             fontSize: { xs: "20px", md: "32px" },
//             lineHeight: { xs: "25px", md: "40px" },
//           }}
//         >
//           Why CourseVita?{" "}
//         </Typography>
//         <Typography
//           className="subText"
//           sx={{
//             fontSize: { xs: "20px", md: "36px" },
//             lineHeight: { xs: "25px", md: "45px" },
//           }}
//         >
//           Because{" "}
//           <span style={{ color: "#FE7465", position: "relative" }}>
//             Your Success Matters
//             <Box
//               component="img"
//               src={pencilIcon}
//               sx={{
//                 position: "absolute",
//                 bottom: "-4px",
//                 right: { md: "-75px", xs: "-40px" },
//                 height: { xs: "45px", md: "87px" },
//               }}
//             />
//           </span>
//         </Typography>
//       </Box>
//       <Box
//         className="frameWrapper"
//         sx={{
//           px: { xs: "4px", md: "26px " },
//           flexDirection: { xs: "column", md: "row" },
//         }}
//       >
//         <Box className="column1" sx={{ flex: 1 }}>
//           {WhyCoursevitadata?.slice(0, 3).map((data, index) => {
//             return <Frame data={data} />;
//           })}
//         </Box>
//         <Box
//           className="column2"
//           sx={{
//             flex: 1,
//             pt: "62px",
//             px: "12px",
//             borderRadius: { md: "20px", xs: "12px" },
//           }}
//         >
//           <Typography className="label">Want to upskill ?</Typography>
//           <Typography className="subText">
//             Start your <span style={{ fontWeight: 700 }}>learning journey</span>{" "}
//             today!
//           </Typography>
//           <Button
//             sx={{ padding: "8px 18px" }}
//             className="exploreBtn"
//             variant="contained"
//             onClick={() =>
//               window.open("https://app.youform.com/forms/twpaswxy", "_blank")
//             }
//           >
//             Assess Yourself
//           </Button>
//           <Box
//             component="img"
//             src={src}
//             onMouseEnter={() => setSrc(kidWithPencilfilled)}
//             onMouseLeave={() => setSrc(kidWithPencilIcon)}
//             sx={{
//               position: "absolute",
//               width: "100%",
//               bottom: "0px",
//               left: { xs: "-31px", sm: "80px", md: "-31px" },
//               maxHeight: "380px",
//               maxWidth: "380px",
//               zIndex: "2",
//             }}
//           />
//           <Box
//             component="img"
//             src={cubes}
//             sx={{
//               position: "absolute",
//               bottom: "0px",
//               left: "0px",
//               width: "100%",
//             }}
//           />
//         </Box>
//         <Box className="column3" sx={{ flex: 1 }}>
//           {WhyCoursevitadata?.slice(3, 6).map((data, index) => {
//             return <Frame data={data} key={index} />;
//           })}
//         </Box>

//         {/* Middle Column (lg and above)
//           <Grid item lg={12} display={{ xs: "none", sm: "none", md: "none", lg: "block" }}>
//             <Paper style={{ height: "100%" }}>
//               <Typography variant="h6">Middle Column</Typography>
//               <Typography variant="body2">Single Row</Typography>
//             </Paper>
//           </Grid> */}
//       </Box>
//     </Box>
//   );
// };

// export default WhyCoursevita;
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../whyCoursevita/WhyCoursevita.scss";
import kidWithPencilIcon from "../../../assets/images/whyCoursevita/kidWithPencil.svg";
import curriculumIcon from "../../../assets/images/whyCoursevita/curriculam.svg";
import bookIcon from "../../../assets/images/whyCoursevita/book.svg";
import lecturerIcon from "../../../assets/images/whyCoursevita/lecturer.svg";
import gamingIcon from "../../../assets/images/whyCoursevita/gaming.svg";
import pencilIcon from "../../../assets/images/whyCoursevita/pencil.svg";
import cubes from "../../../assets/images/whyCoursevita/cubes.svg";
import portfolioIcon from "../../../assets/images/whyCoursevita/portfolio.svg";
import certificateIcon from "../../../assets/images/whyCoursevita/certificate.svg";
import curriculumGif from "../../../assets/images/whyCoursevita/Gifs/curriculam.gif";
import bookGif from "../../../assets/images/whyCoursevita/Gifs/book.gif";
import lecturerGif from "../../../assets/images/whyCoursevita/Gifs/lecturer.gif";
import gamingGif from "../../../assets/images/whyCoursevita/Gifs/gaming.gif";
import portfolioGif from "../../../assets/images/whyCoursevita/Gifs/portfolio.gif";
import certificateGif from "../../../assets/images/whyCoursevita/Gifs/certificate.gif";
import kidWithPencilfilled from "../../../assets/images/whyCoursevita/kidwithpencilfilled.svg";
import Frame from "./frame/Frame";
import { useState } from "react";

const WhyCoursevita = () => {
  const [src, setSrc] = useState(kidWithPencilIcon);
  const navigate = useNavigate(); // Initialize navigate

  const WhyCoursevitadata = [
    {
      label: "Learn from top industry experts",
      subText:
        "Our expert instructors bring real-world experience to every course.",
      icon: lecturerIcon,
      gif: lecturerGif,
    },
    {
      label: "Personalised Doubts Clearing window",
      subText: "Get your questions answered quickly for better understanding.",
      icon: bookIcon,
      gif: bookGif,
    },
    {
      label: "Prepare with Mock Interview Sessions",
      subText: "Practice real-world scenarios to boost your confidence.",
      icon: curriculumIcon,
      gif: curriculumGif,
    },
    {
      label: "Gamified Learning",
      subText:
        "Our gamified learning approach maximizes engagement and retention.",
      icon: gamingIcon,
      gif: gamingGif,
    },
    {
      label: "Earn certificate",
      subText: "Unlock a certificate to showcase your achievement!",
      icon: certificateIcon,
      gif: certificateGif,
    },
    {
      label: "CV & Portfolio",
      subText:
        "Crafting tailored CVs and portfolios to highlight your skills and achievements.",
      icon: portfolioIcon,
      gif: portfolioGif,
    },
  ];

  return (
    <Box className="whyCoursevita" sx={{ backgroundColor: "#F4F4FE" }}>
      <Box className="header" sx={{ mb: { md: "48px", xs: "30px" } }}>
        <Typography
          className="headText"
          sx={{
            fontSize: { xs: "20px", md: "32px" },
            lineHeight: { xs: "25px", md: "40px" },
          }}
        >
          Why CourseVita?{" "}
        </Typography>
        <Typography
          className="subText"
          sx={{
            fontSize: { xs: "20px", md: "36px" },
            lineHeight: { xs: "25px", md: "45px" },
          }}
        >
          Because{" "}
          <span style={{ color: "#FE7465", position: "relative" }}>
            Your Success Matters
            <Box
              component="img"
              src={pencilIcon}
              sx={{
                position: "absolute",
                bottom: "-4px",
                right: { md: "-75px", xs: "-40px" },
                height: { xs: "45px", md: "87px" },
              }}
            />
          </span>
        </Typography>
      </Box>
      <Box
        className="frameWrapper"
        sx={{
          px: { xs: "4px", md: "26px " },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box className="column1" sx={{ flex: 1 }}>
          {WhyCoursevitadata?.slice(0, 3).map((data, index) => {
            return <Frame data={data} key={index} />;
          })}
        </Box>
        <Box
          className="column2"
          sx={{
            flex: 1,
            pt: "62px",
            px: "12px",
            borderRadius: { md: "20px", xs: "12px" },
          }}
        >
          <Typography className="label">Want to upskill ?</Typography>
          <Typography className="subText">
            Start your <span style={{ fontWeight: 700 }}>learning journey</span>{" "}
            today!
          </Typography>
          <Button
            sx={{ padding: "8px 18px" }}
            className="exploreBtn"
            variant="contained"
            onClick={() => navigate("/skillAssessment")} // Use navigate to change the route
          >
            Assess Yourself
          </Button>
          <Box
            component="img"
            src={src}
            onMouseEnter={() => setSrc(kidWithPencilfilled)}
            onMouseLeave={() => setSrc(kidWithPencilIcon)}
            sx={{
              position: "absolute",
              width: "100%",
              bottom: "0px",
              left: { xs: "-31px", sm: "80px", md: "-31px" },
              maxHeight: "380px",
              maxWidth: "380px",
              zIndex: "2",
            }}
          />
          <Box
            component="img"
            src={cubes}
            sx={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "100%",
            }}
          />
        </Box>
        <Box className="column3" sx={{ flex: 1 }}>
          {WhyCoursevitadata?.slice(3, 6).map((data, index) => {
            return <Frame data={data} key={index} />;
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default WhyCoursevita;
