// import { Box, Typography, Button, Pagination, Paper } from "@mui/material";
// import leftIcon from "../../../assets/images/blogPosts/leftIcon.svg";
// import blogArrow from "../../../assets/images/blogPosts/blogArrow.svg";
// import arrow from "../../../assets/images/testimonials/arrow.svg";
// import { useEffect, useState } from "react";
// import "../blogPosts/BlogPosts.scss";
// // import { isEmpty } from "lodash";
// import CourseCard from "../../landingPage/coursesSection/courseCard/CourseCard";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const BlogPosts = (props) => {
//   const { coursesContent, blogsContent } = props;
//   const navigate = useNavigate();

//   useEffect(()=>{
//     async function getBlogs(){
//       try {
//         // const response = axios.post("http://localhost:5002/api/blogs/search");
//         const response = await fetch("http://localhost:5002/api/blogs/search",{
//           method: "POST"
//         });
//         if(response.ok){
//           const data = await response.json();
//           console.log(data);
//         }
//         // console.log(response);
//       } catch (error) {
//         console.error(error.message);
//       }
//     }
//     getBlogs();
//   },[])

//   const trendingData = blogsContent?.filter((blog) => blog.trending === true);
//   const getUniqueTechnologies = (blogs) => {
//     const technologies = blogs.map((blog) => blog.technology);
//     const uniqueTechnologies = [...new Set(technologies)];
//     return ["All", ...uniqueTechnologies];
//   };
//   const technologyCategories = getUniqueTechnologies(blogsContent);

//   const [activeStep, setActiveStep] = useState(0);
//   const maxSteps = trendingData?.length;
//   const [activeCategory, setActiveCategory] = useState("All");

//   const [filteredData, setFilteredData] = useState(blogsContent);
//   const [currentPage, setCurrentPage] = useState(1); // Start pagination from page 1
//   const [filteredCourses, setFilteredCourses] = useState([]);

//   useEffect(() => {
//     if (coursesContent) {
//       setFilteredCourses(
//         coursesContent
//           .filter(
//             (post) =>
//               post.technology.toLowerCase() === activeCategory.toLowerCase()
//           )
//           .slice(0, 4)
//       );
//     }
//   }, [coursesContent, activeCategory]);

//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
//   };

//   const handleBack = () => {
//     setActiveStep(
//       (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
//     );
//   };

//   const handleFilter = (technology) => {
//     setActiveCategory(technology);
//     if (technology.toLowerCase() === "all") {
//       setFilteredData(blogsContent);
//       setFilteredCourses([]);
//     } else {
//       const filtered = blogsContent?.filter(
//         (post) => post.technology.toLowerCase() === technology.toLowerCase()
//       );
//       setFilteredData(filtered);
//       const filteredCourses = coursesContent
//         ?.filter(
//           (post) => post.technology.toLowerCase() === technology.toLowerCase()
//         )
//         .slice(0, 4);
//       setFilteredCourses(filteredCourses);
//     }
//     setCurrentPage(1);
//   };



//   const handlePageChange = (event, page) => {
//     setCurrentPage(page);
//   };

//   const handleReadMore = (selected) => {
//     navigate(`/blogs/${encodeURIComponent(selected.routeHelper)}`);
//   };

//   const cardsPerPage = 3;
//   const totalPages = Math.ceil(filteredData?.length / cardsPerPage);
//   const truncate = (text, maxLength) => {
//     if (text.length > maxLength) {
//       return text.slice(0, maxLength) + "...";
//     }
//     return text;
//   };

//   return (
//     <>
//       <>
//         <Paper
//           square
//           elevation={0}
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             overflow: "hidden",
//             position: "relative",
//             justifyContent: "center",
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               transition: "transform 0.3s ease-in-out",
//               transform: `translateX(-${activeStep * 100}%)`,
//               width: "100%",
//             }}
//           >
//             {trendingData?.map((data, index) => (
//               <Box
//                 key={index}
//                 sx={{
//                   width: "100%",
//                   flexShrink: 0,
//                   p: { xs: "0 16px", md: "0 32px" },
//                 }}
//               >
//                 <Box
//                   className="trendingBlogWrapper"
//                   key={index}
//                   sx={{
//                     backgroundImage: `url(${data.image})`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     height: {
//                       lg: "65vh",
//                       md: "55vh",
//                       sm: "45vh",
//                       xs: "25vh",
//                     },
//                     width: "100%",
//                     borderRadius: { xs: "12px", md: "24px" },
//                   }}
//                 >
//                   <Box
//                     className="trendingWrapper"
//                     sx={{
//                       m: { md: "28px", xs: "10px" },
//                       p: { md: "20px", xs: "10px" },
//                     }}
//                   >
//                     <Typography
//                       className="trendingTitle"
//                       sx={{
//                         p: { md: "4px 16px", xs: "2px 8px" },
//                         fontSize: { md: "16px", xs: "10px" },
//                         borderRadius: { md: "8px", xs: "2px" },
//                         fontFamily: '"Outfit", sans-serif',  // Apply the Outfit font
//                       }}
//                     >
//                       Trending
//                     </Typography>
//                     <Typography
//                       sx={{
//                         fontSize: { md: "24px", xs: "10px" },
//                         mt: { md: "20px", xs: "8px" },
//                         fontFamily: '"Outfit", sans-serif',  // Apply the Outfit font
//                       }}
//                     >
//                       {data.title}
//                     </Typography>
//                   </Box>

//                   {/* Button */}
//                   <Button
//                     onClick={() => {
//                       handleReadMore(data);
//                     }}
//                     className="trendingblogsImages"
//                     sx={{
//                       fontSize: { md: "20px", xs: "12px" },
//                       m: { md: "32px 48px", xs: "12px 16px" },
//                       p: { md: "9px 16px ", xs: "10px 10px" },
//                       backgroundColor: "white",
//                       borderRadius:"8px",
//                       color: "black", // Set button text color to black
//                       textTransform: "none", // Prevent text from being uppercase
//                       fontFamily: '"Outfit", sans-serif', // Apply the Outfit font
//                       '&:hover': {
//                         backgroundColor: '#D3D3D3', // Ensure background stays white
//                         transition: 'color 0.3s ease', // Smooth transition for color change
//                         boxShadow: 'none',        // Remove box shadow effect on hover
//                       },
//                     }}
//                   >
//                     Read Blog
//                     <Box
//                       component="img"
//                       src={blogArrow}
//                       sx={{
//                         width: { xs: "14px", md: "20px" },
//                         pl: "5px", // Add padding-left to create space between text and icon
//                         filter: "invert(0) brightness(0)", // Make the icon black
//                       }}
//                     />
//                   </Button>

//                 </Box>
//               </Box>
//             ))}

//           </Box>
//         </Paper>
//         <Box sx={{ display: "flex", justifyContent: "center" }}>
//           <Box
//             sx={{
//               width: "100%",
//               mt: { md: "20px" },
//               display: "flex",
//               alignItems: "center",
//               maxWidth: "400px",
//             }}
//           >
//             <Button disabled={activeStep === 0} onClick={handleBack}>
//               <Box
//                 component="img"
//                 sx={{ transform: "rotate(180deg)" }}
//                 src={arrow}
//                 size="small"
//               />
//             </Button>
//             <Box sx={{ flexGrow: 1, mx: 2 }}>
//               <Box
//                 sx={{
//                   width: "100%",
//                   height: 8,
//                   backgroundColor: "#e0e0e0",
//                   borderRadius: "12px",
//                   position: "relative",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     width: `${100 / maxSteps}%`,
//                     height: "100%",
//                     position: "absolute",
//                     backgroundColor: "#000000",
//                     borderRadius: 12,
//                     left: `${(100 / maxSteps) * activeStep}%`,
//                     transition: "left 0.3s",
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Button disabled={activeStep === maxSteps - 1} onClick={handleNext}>
//               <Box component="img" src={arrow} size="small" />
//             </Button>
//           </Box>
//         </Box>
//         <Box
//           className="blogWrapper"
//           sx={{
//             m: {
//               md: "26px auto 26px auto",
//               xs: "16px",
//             },
//             width: { md: "80%" },
//           }}
//         >
//           <Typography
//             className="header"
//             sx={{
//               fontSize: { xs: "24px", md: "36px" },
//               lineHeight: { xs: "30px", md: "54px" },
//             }}
//           >
//             <span style={{ color: "#FF7262" }}>Explore</span> advanced concepts
//             with our blogs
//           </Typography>
// <div className="categoryContainer">
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "flex-start",
//       overflowX: "auto",
//       width: "100%",
//       whiteSpace: "nowrap",
//       padding: "10px 0", // Add padding for better visibility
//       "&::-webkit-scrollbar": {
//         display: "none", // Hide scrollbar for a cleaner look
//       },
//     }}
//   >
//     {technologyCategories?.map((tech, index) => (
//       <Button
//         key={index}
//         sx={{
//           flexShrink: "0",
//           marginRight: "10px",
//           whiteSpace: "nowrap", // Prevent text from wrapping within button
//         }}
//         className={`categoryBtns ${activeCategory === tech ? "contained" : "outlined"}`}
//         onClick={() => handleFilter(tech)}
//       >
//         {tech}
//       </Button>
//     ))}
//   </Box>
// </div>


//           {filteredData?.length === 0 ? (
//             <Typography
//               variant="h6"
//               sx={{
//                 textAlign: "center",
//                 my: 4,
//                 fontSize: "20px",
//                 color: "#727272",
//               }}
//             >
//               New Blogs Coming soon!!!
//             </Typography>
//           ) : (
//             <>
//               {filteredData
//                 ?.slice(
//                   (currentPage - 1) * cardsPerPage,
//                   currentPage * cardsPerPage
//                 )
//                 .map((item, index) => (
//                   <Box
//                     key={index}
//                     className="cardWrapper"
//                     sx={{
//                       flexDirection: { xs: "column-reverse", md: "row" },
//                       p: { sm: "20px 26px", xs: "16px" },
//                     }}
//                   >
//                     <Box
//                       className="contentWrapper"
//                       sx={{
//                         width: { md: "65%", xs: "100%" },
//                         pt: { xs: "16px", md: "0px" },
//                         display: "flex",
//                         flexDirection: "column",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <Box>
//                         <Typography
//                           className="title"
//                           sx={{
//                             color: { md: "#0F002E", xs: "#FF7262" },
//                             fontSize: { xs: "16px", md: "24px" },
//                           }}
//                         >
//                           {item.title}
//                         </Typography>
//                         <Typography
//                           sx={{
//                             color: { md: "#0F002E", xs: "#FF7262" },
//                             fontSize: { xs: "14px", md: "16px" },
//                             pb: "6px",
//                             fontFamily: "Outfit"
//                           }}
//                         >
//                           {item.subtitle}
//                         </Typography>
//                         <Typography className="date">{item.date}</Typography>
//                         <Typography
//                           className="blog"
//                           sx={{ fontSize: { xs: "14px", md: "16px" } }}
//                         >
//                           {truncate(item.blog, 200)}
//                         </Typography>
//                       </Box>
//                       <Button
//                         className="readMoreBtn"
//                         sx={{
//                           alignSelf: { md: "flex-start", xs: "center" },
//                           mt: { xs: "50px", md: "20px" },
//                         }}
//                         onClick={() => handleReadMore(item)}
//                         endIcon={<img src={leftIcon} alt="Read More Icon" />}
//                       >
//                         Read full blog
//                       </Button>
//                     </Box>
//                     <Box
//                       component="img"
//                       src={item.image}
//                       sx={{
//                         width: { md: "30%", xs: "100%" },
//                         minWidth: { lg: "442px", md: "390px" },
//                         maxHeight: "230px",
//                         borderRadius: { xs: "12px", md: "20px" },
//                       }}
//                     />
//                   </Box>
//                 ))}
//               {totalPages > 1 && (
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     marginTop: "20px",
//                   }}
//                 >
//                   <Pagination
//                     count={totalPages}
//                     page={currentPage}
//                     siblingCount={0}
//                     variant="outlined"
//                     shape="rounded"
//                     onChange={handlePageChange}
//                     sx={{
//                       "& .Mui-selected": {
//                         border: "1px #0F002E solid",
//                         backgroundColor: "#FFFFFF !important",
//                         color: "#000000 !important"
//                       },
//                       "& .MuiPaginationItem-root": {
//                         color: "#000000",
//                       },
//                     }}
//                   />
//                 </Box>
//               )}
//             </>
//           )}
//         </Box>
//       </>
//       {filteredCourses.length !== 0 && (
//         <Box
//           className="coursesSection"
//           sx={{
//             p: { md: "30px 80px 0px", xs: "20px 16px 20px 16px" },
//             backgroundColor: "#F3F4FD",
//           }}
//         >
//           <Box
//             className="headersSection"
//             sx={{ mb: { md: "5px", xs: "20px" } }}
//           >
//             <Typography
//               className="headText"
//               sx={{ fontSize: { md: "32px", xs: "20px" } }}
//             >
//               Explore
//             </Typography>
//             <Typography
//               className="subText"
//               sx={{ fontSize: { md: "36px", xs: "20px" } }}
//             >
//               Our {activeCategory} courses
//             </Typography>
//           </Box>
//           <Box
//             className="cardsWrapper"
//             sx={{
//               overflow: { xs: "auto", md: "hidden" },
//               "&::-webkit-scrollbar": {
//                 display: "none",
//               },
//             }}
//           >
//             <Box
//               className="courseCards"
//               sx={{
//                 width: "100%",
//                 // transition: "transform 0.3s ease",
//                 // transform: { md: `translateX(-${activeStepCourse * 45}%)` },
//                 p: { md: "40px 0 76px", xs: "16px 0" },
//               }}
//             >
//               {filteredCourses?.map((courseContent, index) => (
//                 <CourseCard key={index} courseContent={courseContent} />
//               ))}
//             </Box>
//           </Box>

//         </Box>
//       )}
//     </>
//   );
// };

// export default BlogPosts;



import { Box, Typography, Button, Pagination, Paper } from "@mui/material";
import leftIcon from "../../../assets/images/blogPosts/leftIcon.svg";
import blogArrow from "../../../assets/images/blogPosts/blogArrow.svg";
import arrow from "../../../assets/images/testimonials/arrow.svg";
import { useEffect, useState } from "react";
import "../blogPosts/BlogPosts.scss";
// import { isEmpty } from "lodash";
import CourseCard from "../../landingPage/coursesSection/courseCard/CourseCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PreLoader from "../../../components/pre-loader/PreLoader"

const BlogPosts = (props) => {
  const { coursesContent } = props;
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([]);
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getBlogs() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_LINK}/api/blogs/getallblogs`);
        if (response.status === 200) {
          const { data, length } = response.data;
    
          // Reverse the blog data to show the latest first
          const reversedBlogs = data.reverse();
    
          setBlogs(reversedBlogs);
          setPages(length);
          setLoading(false);
        }
      } catch (error) {
        console.error(error.message);
        setLoading(false);
      }
    }
    
    getBlogs();
  }, []);


  const trendingData = blogs?.filter((blog) => blog.trending === true);
  const getUniqueCategories = (blogs) => {
    const categories = blogs.map((blog) => blog.blogCategory);
    const uniqueCategories = [...new Set(categories)];
    return ["All", ...uniqueCategories];
  };
  const blogCategories = getUniqueCategories(blogs);

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = trendingData?.length;
  const [activeCategory, setActiveCategory] = useState("All");

  const [filteredData, setFilteredData] = useState(blogs);
  const [currentPage, setCurrentPage] = useState(1); // Start pagination from page 1
  const [filteredCourses, setFilteredCourses] = useState([]);


  var totalPages = 0;
  const cardsPerPage = 3;
  useEffect(() => {
    if (activeCategory === "All") {
      setFilteredData(blogs);
    } else {
      setFilteredData(blogs.filter((blog) => blog.blogCategory === activeCategory));
    }
  }, [blogs, activeCategory]);

  useEffect(() => {
    // Update the totalPages whenever filteredData changes
    const updatedPages = Math.ceil(filteredData.length / cardsPerPage);
    setPages(updatedPages);
    setCurrentPage(1); // Reset to the first page whenever data changes
  }, [filteredData]);

  useEffect(() => {
    if (coursesContent) {
      setFilteredCourses(
        coursesContent
          .filter(
            (post) =>
              post.blogCategory?.toLowerCase() === activeCategory.toLowerCase()
          )
          .slice(0, 4)
      );
    }
  }, [coursesContent, activeCategory]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  const handleFilter = (category) => {
    setActiveCategory(category);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleReadMore = (selected) => {
    const id = selected._id;
    navigate(`/blogs/${id}`);
  };

  // const cardsPerPage = 3;
  // const totalPages = Math.ceil(filteredData?.length / cardsPerPage);
  const truncate = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return loading ? (
    <PreLoader />
  ) : (
    <>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          position: "relative",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            transition: "transform 0.3s ease-in-out",
            transform: `translateX(-${activeStep * 100}%)`,
            width: "100%",
          }}
        >
          {trendingData?.map((data, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                flexShrink: 0,
                p: { xs: "0 16px", md: "0 32px" },
              }}
            >
             <Box
  className="trendingBlogWrapper"
  sx={{
    backgroundImage: `url(${data.headerImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: {
      lg: "calc(65vw * 0.5625)",
      md: "calc(55vw * 0.5625)",
      sm: "calc(45vw * 0.5625)",
      xs: "calc(30vw * 0.5625)", // Reduce image height on mobile
    },
    width: "100%",
    borderRadius: { xs: "12px", md: "24px" },
    height:{xs:"200px",md:"560px"}
  }}
>
  <Box
    className="trendingWrapper"
    sx={{
      m: { md: "28px", xs: "10px" }, // Reduced margin for mobile
      p: { md: "20px", xs: "10px" },
      height:{xs:"50px !important", md:"160px !important"}  // Reduced padding for mobile
    }}
  >
    <Typography
      className="trendingTitle"
      sx={{
        p: { md: "4px 16px", xs: "2px 8px" }, // Smaller padding on mobile
        fontSize: { md: "16px", xs: "12px" },  // Smaller text on mobile
        borderRadius: { md: "8px", xs: "4px" }, // Smaller border radius for mobile
        fontFamily: '"Outfit", sans-serif',
      }}
    >
      Trending
    </Typography>
    <Typography
      sx={{
        fontSize: { md: "24px", xs: "16px" },  // Reduced font size on mobile
        mt: { md: "20px", xs: "8px" },        // Adjust margin for mobile
        fontFamily: '"Outfit", sans-serif',
      }}
    >
 
    </Typography>
  </Box>
  <Button
    onClick={() => {
      handleReadMore(data);
    }}
    className="trendingblogsImages"
    sx={{
      fontSize: { md: "20px", xs: "14px" }, // Reduced font size for mobile
      m: { md: "32px 48px", xs: "12px 16px" },  // Adjusted margin for mobile
      p: { md: "9px 16px", xs: "8px 12px" }, // Smaller padding on mobile
      backgroundColor: "white",
      borderRadius: "8px",
      color: "black",
      textTransform: "none",
      fontFamily: '"Outfit", sans-serif',
      "&:hover": {
        backgroundColor: "#D3D3D3",
        transition: "color 0.3s ease",
        boxShadow: "none",
      },
    }}
  >
    Read Blog
    <Box
      component="img"
      src={blogArrow}
      sx={{
        width: { xs: "14px", md: "20px" },
        pl: "5px",
        filter: "invert(0) brightness(0)",
      }}
    />
  </Button>
</Box>

            </Box>
          ))}
        </Box>
      </Paper>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: "100%",
            mt: { md: "20px" },
            display: "flex",
            alignItems: "center",
            maxWidth: "400px",
          }}
        >
          <Button disabled={activeStep === 0} onClick={handleBack}>
            <Box
              component="img"
              sx={{ transform: "rotate(180deg)" }}
              src={arrow}
              size="small"
            />
          </Button>
          <Box sx={{ flexGrow: 1, mx: 2 }}>
            <Box
              sx={{
                width: "100%",
                height: 8,
                backgroundColor: "#e0e0e0",
                borderRadius: "12px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: `${100 / maxSteps}%`,
                  height: "100%",
                  position: "absolute",
                  backgroundColor: "#000000",
                  borderRadius: 12,
                  left: `${(100 / maxSteps) * activeStep}%`,
                  transition: "left 0.3s",
                }}
              />
            </Box>
          </Box>
          <Button disabled={activeStep === maxSteps - 1} onClick={handleNext}>
            <Box component="img" src={arrow} size="small" />
          </Button>
        </Box>
      </Box>
      <Box
        className="blogWrapper"
        sx={{
          m: {
            md: "26px auto 26px auto",
            xs: "16px",
          },
          width: { md: "80%" },
        }}
      >
        <Typography
          className="header"
          sx={{
            fontSize: { xs: "26px !important", md: "36px !important" },
            lineHeight: { xs: "30px", md: "54px" },
            marginBottom:{xs:"-20px !important"},
            marginTop:{xs:"17px !important"}
          }}
        >
          <span style={{ color: "#FF7262" }}>Explore</span> advanced concepts
          with our blogs
        </Typography>
        <div className="categoryContainer">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              overflowX: "auto",
              width: "100%",
              whiteSpace: "nowrap",
              padding: "10px 0",
              marginTop:"0px !important",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {blogCategories?.map((category, index) => (
              <Button
                key={index}
                sx={{
                  flexShrink: "0",
                  marginRight: "10px",
                  whiteSpace: "nowrap",
                }}
                className={`categoryBtns ${activeCategory === category ? "contained" : "outlined"
                  }`}
                onClick={() => handleFilter(category)}
              >
                {category}
              </Button>
            ))}
          </Box>
        </div>
        {filteredData.length === 0 ? (
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              my: 4,
              fontSize: "20px",
              color: "#727272",
            }}
          >
            New Blogs Coming soon!!!
          </Typography>
        ) : (
          <>
            {filteredData
              ?.slice(
                (currentPage - 1) * cardsPerPage,
                currentPage * cardsPerPage
              )
              .map((item, index) => (
                <Box
                  key={index}
                  className="cardWrapper"
                  sx={{
                    flexDirection: { xs: "column-reverse", md: "row" },
                    p: { sm: "20px 26px", xs: "16px" },
                  }}
                >
                  <Box
                    className="contentWrapper"
                    sx={{
                      width: { md: "65%", xs: "100%" },
                      pt: { xs: "16px", md: "0px" },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        className="title"
                        sx={{
                          color: { md: "#0F002E", xs: "#0F002E" },
                          fontSize: { xs: "16px", md: "24px" },
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: { md: "#0F002E", xs: "#0F002E" },
                          fontSize: { xs: "14px", md: "16px" },
                          pb: "6px",
                          fontFamily: "Outfit",
                        }}
                      >
                        {item.subTitle}
                      </Typography>
                      <Typography className="date">{item.date}</Typography>
                      <Typography
                        className="blog"
                        sx={{ fontSize: { xs: "14px", md: "16px" } }}
                      >
                        {truncate(item.blogDemo, 200)}
                      </Typography>
                    </Box>
                    <Button
                      className="readMoreBtn"
                      sx={{
                        alignSelf: { md: "flex-start", xs: "center" },
                        mt: { xs: "50px", md: "20px" },
                      }}
                      onClick={() => handleReadMore(item)}
                      endIcon={<img src={leftIcon} alt="Read More Icon" />}
                    >
                      Read full blog
                    </Button>
                  </Box>
                  <Box
                    component="img"
                    src={item.headerImageUrl}
                    sx={{
                      width: { md: "30%", xs: "100%" }, // Ensure images take up consistent width
                      minWidth: { lg: "320px", md: "240px", xs: "140px" }, // Make sure the minimum width is set
                      height: "270px", // Preserve aspect ratio
                      objectFit: "cover", // Ensures the image is cropped and fills the container
                      borderRadius: "25px", // Round corners for a consistent look
                    }}
                  />

                </Box>
              ))}

            {pages > 1 && (

              <Pagination
                count={pages}
                page={currentPage}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
                sx={{
                  display: "flex", justifyContent: "center", width: "100%", mt: 4,
                  "& .Mui-selected": {

                    border: "1px #0F002E solid",
                    backgroundColor: "#FFFFFF !important",
                    color: "#000000 !important",
                  },
                  "& .MuiPaginationItem-root": {
                    color: "#000000",
                  },
                }}
              />
            )
            }
          </>
        )}
      </Box>
    </>
  );
};

export default BlogPosts;


