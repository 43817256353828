import TopLayer from "./topLayer/TopLayer";
import Join from "./join/join";
import Navbar from "../commonComponents/navbar/Navbar";
import Footer from "../commonComponents/footer/Footer";
import Tips from "./tips/Tips";
import Last from "./lastLayer/Last";

const mockGroupDiscussion = () => {
  return (
    <>
      <Navbar />
      <TopLayer />
      <Join />
      <Tips />
      <Last />
      <Footer />
    </>
  );
};

export default mockGroupDiscussion;
