import { useRef, useState } from "react";
import "./Checkout.scss";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import payment from "../../assets/images/checkout/payment.svg";
import axios from "axios";
import PhoneIcon from "@mui/icons-material/Phone"; // Import PhoneIcon from MUI icons

import { isEmpty } from "lodash";
import ApplyNowDialog from "../commonComponents/ApplynowDialog.jsx/ApplyNowDialog";
import ReCAPTCHA from "react-google-recaptcha";

const Checkout = ({ courseContent }) => {
  const recaptchaRef = useRef();
  const firstnameRef = useRef(null); // Ref for first name field
  const lastNameRef = useRef(null); // Ref for last name field
  const emailRef = useRef(null); // Ref for email field
  const mobileNumberRef = useRef(null); // Ref for mobile number field

  const [firstname, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [agree, setAgree] = useState(false);
  const [message, setMessage] = useState("");
  const [captchaMessage, setCaptchaMessage] = useState("");
  const [discountErrorMessage, setdiscountErrorMessage] = useState("");
  const [discountSuccessMessage, setdiscountSuccessMessage] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const priceOfSellingCourse = courseContent?.sellingPrice;
  const [finalAmmount, setfinalAmount] = useState(priceOfSellingCourse);
  const [couponApplied, setCouponApplied] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [applyCouponLoading, setApplyCouponLoading] = useState(false);

  const [errors, setErrors] = useState({
    firstname: "",
    lastName: "",
    email: "",
    mobileNumber: "",
  });

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const validation = () => {
    const mobileNumRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newErrors = {};

    if (isEmpty(firstname)) {
      newErrors.firstname = "Please enter first name";
    }
    if (isEmpty(lastName)) {
      newErrors.lastName = "Please enter last name";
    }
    if (isEmpty(email)) {
      newErrors.email = "Please enter a valid Email Id";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid Email Id";
    }
    if (isEmpty(mobileNumber)) {
      newErrors.mobileNumber = "Please enter a valid mobile number";
    } else if (!mobileNumRegex.test(mobileNumber)) {
      newErrors.mobileNumber = "Please enter a valid mobile number";
    }

    setErrors(newErrors);

    // If errors exist, scroll to the first field with an error
    if (Object.keys(newErrors).length > 0) {
      if (newErrors.firstname) {
        firstnameRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (newErrors.lastName) {
        lastNameRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (newErrors.email) {
        emailRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (newErrors.mobileNumber) {
        mobileNumberRef.current.scrollIntoView({ behavior: "smooth" });
      }
      return false;
    } else {
      return true;
    }
  };

  const toggleCheckbox = () => {
    setAgree(!agree);
  };

  const getPageName = async () => {
    const { hostname, pathname } = window.location;

    const siteName = hostname.split('.')[0].charAt(0).toUpperCase() + hostname.split('.')[0].slice(1);

    const pathSegments = pathname.split('/').filter(Boolean);
    
    if (pathSegments.length === 0) {
      return `The user clicked on the ${siteName} Main Page and reached the payment page`;
    }

    const pageDescriptions = pathSegments.map(segment => {
      if (segment && typeof segment === 'string') {
        return segment.replace(/-/g, ' ').charAt(0).toUpperCase() + segment.slice(1) + " Page";
      }
      return "";
    }).filter(Boolean);

    return `The user clicked through to the ${siteName} ${pageDescriptions.join(' -> ')} and reached payment page`;

  };

  const handlePayment = async () => {
    const currentUrl = window.location.href;
    console.log(currentUrl);
    const pageDescription = await getPageName();
    console.log(pageDescription);
    if (validation()) {
      (async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_LINK}/api/verifyCaptchaCheckout`,
            { recaptchaValue: recaptchaRef.current.getValue() ,email, mobileNumber,firstname ,lastName,currentUrl,pageDescription,course:courseContent.course}
          );
          console.log(response);
          if (response.data.message === "success") {
            setCaptchaMessage("");
            var options = {
              key: process.env.REACT_APP_RAZOR_PAY_KEY,
              key_secret: process.env.REACT_APP_RAZOR_PAY_SECRET_KEY,
              amount: parseInt(finalAmmount) * 100,
              currency: "INR",
              name: "Coursevita",
              description: `Payment for ${courseContent?.course} Program`,
              handler: function (response) {
                const paymentId = response.razorpay_payment_id;
                console.log("paymant id", paymentId);
              },
              theme: {
                color: "#7233F7",
              },
            };
            var pay = new window.Razorpay(options);
            pay.open();
            recaptchaRef?.current?.reset();
          } else {
            setMessage(response.data.message);
          }
        } catch (error) {
          if (error?.response?.data?.message) {
            setCaptchaMessage(error.response.data.message);
          }
        }
      })();
      var options = {
        key: "rzp_live_I0SwaLrZPMkjwd",
        key_secret: "tqeaZIIAX1NCBR2yWD9ioVtN",
        amount: parseInt(finalAmmount) * 100,
        currency: "INR",
        name: "Coursevita",
        description: `Payment for ${courseContent?.course} Program`,
        handler: function (response) {
          const paymentId = response.razorpay_payment_id;
          console.log("paymant id", paymentId);
        },
        theme: {
          color: "#7233F7",
        },
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  };

  const handleApplyClick = async () => {
    setApplyCouponLoading(true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}/api/verifyCoupon`, {
        promocode: couponCode,
      })
      .then((response) => {
        console.log("Response:", response.data);
        setdiscountErrorMessage("");
        setdiscountSuccessMessage("Coupon code applied");
        setfinalAmount((prevValue) => {
          const discount = response.data.discountAmount;
          const discountedAmount = prevValue * (discount / 100);
          const finalDiscountedAmmount = prevValue - discountedAmount;
          return finalDiscountedAmmount;
        });
        setCouponApplied(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setdiscountSuccessMessage("");
        setdiscountErrorMessage("Invalid coupon code");
      })
      .finally(() => {
        setApplyCouponLoading(false);
      });
  };
  const handleCaptchaChange = () => {
    setCaptchaMessage("");
  };

  return (
    <Box className="checkout" sx={{ p: { md: "32px", xs: "16px" } }}>
      <Typography className="title" sx={{ pb: { xs: "35px", md: "24px" } }}>
        Order Summary
      </Typography>
      <Box
        className="contentWrapper"
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <Box className="leftDiv" sx={{ width: { xs: "100%", md: "60%" } }}>
          <Box
            className="courseOrdered"
            sx={{ flexDirection: { sm: "row", xs: "column" } }}
          >
            <Box
              component="img"
              src={courseContent?.imageSource}
              sx={{
                width: { xs: "100%", sm: "300px" },
                height: "180px",
                borderRadius: "12px",
              }}
            />
            <Box sx={{ pl: { sm: "28px" } }}>
              <Typography
                className="courseName"
                sx={{ pt: { xs: "20px", sm: "0" } }}
              >
                {courseContent?.course} Program
              </Typography>
              <Typography
                className="point1"
                sx={{ p: { xs: "14px 0 10px 0px", sm: "12px 0px" } }}
              >
                Duration:{" "}
                <span style={{ fontWeight: "500" }}>
                  {courseContent?.period}
                </span>
              </Typography>
              <Typography className="point2">
                Mode :
                <span style={{ fontWeight: "500" }}>{courseContent?.mode}</span>
              </Typography>
            </Box>
          </Box>
          <Box
            height="60px"
            width="100%"
            bgcolor="primary.main"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            mt={4}
            pl={5}
            mr={5}
            sx={{
              borderRadius: "10px",
              background: "linear-gradient(90deg, #7234F7 0%, #FE7565 110.4%)",
            }}
          >
            <Typography
              variant="body1"
              style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}
            >
              Looking for more guidance and offers?
            </Typography>
            <Button
              variant="contained"
              startIcon={<PhoneIcon />}
              style={{ backgroundColor: "white", color: "black" }}
            >
              <Typography
                variant="caption"
                style={{ color: "black" }}
                onClick={handleOpenDialog}
              >
                Talk to expert
              </Typography>
            </Button>
            <Dialog
              onClose={handleCloseDialog}
              open={dialogOpen}
              PaperProps={{
                sx: {
                  width: { xs: "100%", md: "40%" },
                  m: { xs: "16px", md: "0px" },
                  borderRadius: "24px",
                  backgroundColor: "#FFF",
                  boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.08)",
                  minHeight: "380px",
                  fontFamily: '"Outfit", sans-serif !important',
                  border: "1px solid #C7C7C7",
                  pb: { xs: "10px", md: "10px" },
                },
              }}
            >
              <DialogContent sx={{ p: "0" }}>
                <ApplyNowDialog
                  handleCloseDialog={handleCloseDialog}
                  courseContent={courseContent}
                  handleOpenDialog={null}
                  source="Talk_To_Expert"
                />
              </DialogContent>
            </Dialog>
          </Box>
          <Box
            className="billingOrder"
            sx={{
              p: { md: "33px 24px 30px 26px", xs: "26px 16px" },
              mt: { xs: "32px", sm: "42px" },
              mb: "26px",
              width: { xs: "100%" },
            }}
          >
            <Typography className="billingHead">Billing Details</Typography>
            <Box
              className="formDetails"
              sx={{
                flexDirection: { sm: "row", xs: "column" },
                gap: { sm: "20px " },
              }}
            >
              <Box
                className="formInput halfInput"
                sx={{ width: { md: "50%", xs: "100%" } }}
              >
                <Typography className="formInputHeader">First Name</Typography>
                <TextField
                  id="outlined-basic"
                  inputRef={firstnameRef}
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  variant="outlined"
                  className="formText"
                  error={!!errors.firstname}
                  helperText={errors.firstname}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& fieldset': {
                        borderColor: '#7234f7',  // Set the outline color to #7234f7 on focus
                      },
                    },
                  }}
                />
              </Box>
              <Box
                className="formInput halfInput"
                sx={{ width: { md: "50%", xs: "100%" } }}
              >
                <Typography className="formInputHeader">Last name</Typography>
                <TextField
                  id="outlined-basic"
                  inputRef={lastNameRef}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  variant="outlined"
                  className="formText"
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& fieldset': {
                        borderColor: '#7234f7',  // Set the outline color to #7234f7 on focus
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              className="formDetails"
              sx={{
                flexDirection: { sm: "row", xs: "column" },
                gap: { sm: "20px " },
              }}
            >
              <Box
                className="formInput halfInput"
                sx={{ width: { md: "50%", xs: "100%" } }}
              >
                <Typography className="formInputHeader">
                  Email address
                </Typography>
                <TextField
                  id="outlined-basic"
                  inputRef={emailRef}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  className="formText"
                  error={!!errors.email}
                  helperText={errors.email}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& fieldset': {
                        borderColor: '#7234f7',  // Set the outline color to #7234f7 on focus
                      },
                    },
                  }}
                />
              </Box>
              <Box
                className="formInput halfInput"
                sx={{ width: { md: "50%", xs: "100%" } }}
              >
                <Typography className="formInputHeader">
                  Mobile Number
                </Typography>
                <TextField
                  id="outlined-basic"
                  inputRef={mobileNumberRef}
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  variant="outlined"
                  className="formText"
                  error={!!errors.mobileNumber}
                  helperText={errors.mobileNumber}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& fieldset': {
                        borderColor: '#7234f7',  // Set the outline color to #7234f7 on focus
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Typography
              sx={{
                color: "red",
                pt: "10px",
                fontFamily: `"Outfit", sans-serif`,
              }}
            >
              {message ? message : ""}
            </Typography>
          </Box>
        </Box>
        <Box
          className="rightDiv"
          sx={{ width: { xs: "100%", sm: "70%", md: "32%" } }}
        >
          <Box
            className="paymentDetails"
            sx={{
              p: { xs: "36px 32px 33px 32px" },
              borderRadius: { xs: "12px", md: "20px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "35px",
                pb: { xs: "45px", sm: "35px" },
              }}
            >
              <Box component="img" src={payment} />
              <Typography className="paymentHead"> Payment details</Typography>
            </Box>
            <Typography className="feeHead">Total Program Fee</Typography>
            <Typography className="cp">
              <del>₹{courseContent?.costPrice}/-</del>
            </Typography>
            <Typography className="offerTag">With Early bird offer</Typography>
            <Typography className="sellingPrice">₹{finalAmmount}/-</Typography>
            <Typography className="gst">*Including GST</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                mb: 2,
              }}
            >
              <Typography
                variant="caption"
                sx={{ mb: 1, alignSelf: "flex-start" }}
              >
                Apply coupon-code
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", width: 1 }}>
                <TextField
                  label="Enter coupon code here"
                  variant="outlined"
                  onChange={(e) => setCouponCode(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={handleApplyClick}
                          disabled={couponApplied || applyCouponLoading}
                          endIcon={
                            applyCouponLoading ? (
                              <CircularProgress size={20} />
                            ) : null
                          }
                        >
                          Apply
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <div
                style={{
                  color: "green",
                  marginTop: "8px",
                  alignSelf: "flex-start",
                }}
              >
                {discountSuccessMessage}
              </div>
              <div
                style={{
                  color: "red",
                  marginTop: "8px",
                  alignSelf: "flex-start",
                }}
              >
                {discountErrorMessage}
              </div>
            </Box>

            {/* // */}
            <Box paddingBottom="15px">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                ref={recaptchaRef}
                onChange={handleCaptchaChange}
              />
            </Box>
            {/* // */}

            <Typography
              sx={{
                color: "red !important",
                py: "10px",
                fontFamily: `"Outfit", sans-serif`,
              }}
            >
              {captchaMessage ? captchaMessage : ""}
            </Typography>

            <Button
              disabled={!agree}
              className={agree ? "payBtn" : "payBtn payBtnDisabled"}
              sx={{
                mt: { xs: "5px", md: "5px" },
                mb: { xs: "10px", md: "10px" },
              }}
              variant="contained"
              onClick={handlePayment}
            >
              Proceed to pay
            </Button>
            <Box sx={{ display: "flex" }}>
              <Checkbox
                onChange={toggleCheckbox}
                checked={agree}
                sx={{
                  color: "black",
                  "&.Mui-checked": {
                    color: "black",
                  },
                }}
              />
              <Typography className="terms">
                By paying the booking amount, you agree to our{" "}
                <Link
                  href="/terms"
                  className="styled-link"
                  target="_blank"
                  underline="always"
                  sx={{ fontWeight: 500, color: "#000000" }}
                >
                  Terms and Conditions
                </Link>{" "}
                and{" "}
                <Link
                  href="/refund-policy"
                  target="_blank"
                  className="styled-link"
                  underline="always"
                  sx={{ fontWeight: 500, color: "#000000" }}
                >
                  Refund Policy
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Checkout;


