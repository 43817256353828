import { Box} from "@mui/material";
import ExploreCoursesAd from "./ExploreCoursesAd/ExploreCoursesAd";
import BlogsAd from "./BlogsAd/BlogsAd";

const AddWrapper = () => {


	return (
		<Box >
			<ExploreCoursesAd />
			<BlogsAd/>

		</Box> 
	)
}

export default AddWrapper;