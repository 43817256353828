import React, { useState, useEffect } from 'react';
import './aiavathar.scss';

import logo from "../../assets/images/footer/logoCV.png";
import Avatar from "../../assets/images/aiAvatar/AVATAR.mp4";
import registerIcon from "../../assets/images/aiAvatar/register.svg";
import createIcon from "../../assets/images/aiAvatar/magic-wand.svg";
import submitIcon from "../../assets/images/aiAvatar/mission.svg";
import stayTunedIcon from "../../assets/images/aiAvatar/marketing.svg";
import firstPrizeIcon from "../../assets/images/aiAvatar/1st.png";
import secondPrizeIcon from "../../assets/images/aiAvatar/2nd.png";
import thirdPrizeIcon from "../../assets/images/aiAvatar/3rd.png";
import user1 from '../../assets/images/aiAvatar/user1.jpeg';
import user2 from '../../assets/images/aiAvatar/user2.jpeg';
import user3 from '../../assets/images/aiAvatar/user3.jpeg';
import Avatar2 from '../../assets/images/aiAvatar/AVATAR 2.m4v';
import Avatar3 from '../../assets/images/aiAvatar/AVATAR 3.m4v';
import Avatar4 from '../../assets/images/aiAvatar/AVATAR4.m4v';
import prizecon from '../../assets/images/aiAvatar/63938.jpg'
import Footer from '../commonComponents/footer/Footer';
import Questions from './questions/Questions';

const testimonialsData = [
  {
    text: "The AI Avatar Challenge was a game-changer! It allowed me to explore AI in ways I hadn't thought of before. A must-try for any creator!",
    name: "Rahul",
    title: "AI Enthusiast",
    image: user1,
  },
  {
    text: "As a tech enthusiast, this competition was incredibly rewarding. I got to test my creativity and learn new AI skills along the way!",
    name: "Anvita",
    title: "Digital Artist",
    image: user2,
  },
  {
    text: "The AI Avatar Challenge was both fun and challenging, a valuable learning opportunity that pushed my limits and helped me bring out my best creative ideas.",
    name: "Sejal",
    title: "Creative Technologist",
    image: user3,
  },
];

const AiAvatar = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleScrollAnimation = () => {
      const sections = document.querySelectorAll('.fade-up');
      sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        const isVisible = sectionTop < window.innerHeight - 100;
        if (isVisible) {
          section.classList.add('fade-up-active');
        }
      });
    };

    window.addEventListener('scroll', handleScrollAnimation);

    return () => {
      window.removeEventListener('scroll', handleScrollAnimation);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
  };

  const { text, name, title, image } = testimonialsData[activeIndex];

  return (
    <div className="home-page">
      {/* Navbar */}
      <nav className="navbar">
      <div className="navbar-logo">
    <a href="https://coursevita.com/">
      <img src={logo} alt="Logo" />
    </a>
    </div>
      </nav>

      
      {/* Hero Section */}
      <section className="hero-section" id="hero-section">
        <div className="hero-content">
          <h1>
            <span>A.I Avatar</span> Creator Challenge
          </h1>
          <p>
            Create, customize, and showcase your unique AI avatar for a chance to win amazing prizes.
          </p>
          <a href="https://forms.gle/tSVsyVHFa7DZn3XX8" target="_blank" rel="noopener noreferrer">
          <button className="cta-button">
  Submit your AI Avatar
  <div className="star-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 784.11 815.53"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd"
      }}
      version="1.1"
    >
      <g id="Layer_x0020_1">
        <path
          d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          className="fil0"
        />
      </g>
    </svg>
  </div>
  <div className="star-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 784.11 815.53"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd"
      }}
      version="1.1"
    >
      <g id="Layer_x0020_1">
        <path
          d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          className="fil0"
        />
      </g>
    </svg>
  </div>
  <div className="star-3">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 784.11 815.53"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd"
      }}
      version="1.1"
    >
      <g id="Layer_x0020_1">
        <path
          d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          className="fil0"
        />
      </g>
    </svg>
  </div>
  <div className="star-4">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 784.11 815.53"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd"
      }}
      version="1.1"
    >
      <g id="Layer_x0020_1">
        <path
          d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          className="fil0"
        />
      </g>
    </svg>
  </div>
  <div className="star-5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 784.11 815.53"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd"
      }}
      version="1.1"
    >
      <g id="Layer_x0020_1">
        <path
          d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          className="fil0"
        />
      </g>
    </svg>
  </div>
  <div className="star-6">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 784.11 815.53"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd"
      }}
      version="1.1"
    >
      <g id="Layer_x0020_1">
        <path
          d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
          className="fil0"
        />
      </g>
    </svg>
  </div>
</button>
</a>

        </div>
        <div className="hero-image">
          <svg className="s-shape" viewBox="0 0 200 200">
            <path d="M20,150 C60,20 140,20 180,150" stroke="#7234fe" strokeWidth="8" fill="none" />
          </svg>
          <video src={Avatar} alt="AI Avatar Hero" autoPlay muted loop />
        </div>


      </section>
       {/* prizes */}
       <section className="prizesSection fade-up">
  <h1 className="prizes-heading">Win Big with Incredible Prizes!</h1>
  <div className="prizes-container">
    <div className="prizes-image">
      <img src={prizecon} alt="Prize Image" autoPlay muted loop />
    </div>
    <div className="prizes-content">
      <div className="prize-card">
        <div className="prize-icon-container">
          <div className="icon-wrapper">
            <img src={firstPrizeIcon} alt="First Prize Icon" className="prize-icon" />
          </div>
        </div>
        <div className="prize-content">
          <h3>1st Prize</h3>
          <p>INR 3,00,000  
            <br></br>Job Offer</p>
          <span className="prize-description">Claim the top prize and showcase your creativity to the world!</span>
        </div>
      </div>
      <div className="prize-card">
        <div className="prize-icon-container">
          <div className="icon-wrapper">
            <img src={secondPrizeIcon} alt="Second Prize Icon" className="prize-icon" />
          </div>
        </div>
        <div className="prize-content">
          <h3>2nd Prize</h3>
          <p>INR 2,00,000  <br />Job Offer</p>
          <span className="prize-description">Stand out with your AI avatar win the second prize!</span>
        </div>
      </div> 
      <div className="prize-card">
        <div className="prize-icon-container">
          <div className="icon-wrapper">
            <img src={thirdPrizeIcon} alt="Third Prize Icon" className="prize-icon" />
          </div>
        </div>
        <div className="prize-content">
          <h3>3rd Prize</h3>
          <p>INR 1,00,000</p>
          <span className="prize-description"><br />Make an impact and secure the third prize with your design skills!</span>
        </div>
      </div>
    </div>
  </div>
</section>

      {/* Challenge Section */}
      <section className="challenge-section fade-up" >
        <h2>About the Challenge</h2>
        <p>
          Welcome to the AI Avatar Challenge – where creativity meets technology! 
          This competition invites creators and tech enthusiasts to design AI-powered 
          avatars for a chance to win exclusive learning programs. Unleash your creativity 
          and dive into this journey of innovation!
        </p>
        
       <div className="ChallengeVideos">
       <div className="Challenge-image">
          <video src={Avatar2} alt="AI Avatar Hero" autoPlay muted loop />
        </div>
        <div className="Challenge-image">
          <video src={Avatar3} alt="AI Avatar Hero" autoPlay muted loop />
        </div>
        <div className="Challenge-image">
          <video src={Avatar4} alt="AI Avatar Hero" autoPlay muted loop />
        </div>
       </div>
      </section> 

    

      {/* Rest of the Code */}
      {/* Add your remaining sections like How to Participate, Prizes, Guidelines, Testimonials */}
      {/* Footer and Dialog for form submission */}

      {/* How to Participate Section with Custom Images */}
      <section className="how-to-participate fade-up">
        <h2>How to participate</h2>
        <div className="card-container">
          <div className="card">
            <div className="icon">
              <img src={registerIcon} alt="Register Icon" />
            </div>
            <h3>Register</h3>
            <p>Click on the "Register Now" button and fill out the form to secure your spot.</p>
          </div>
          <div className="card">
            <div className="icon">
              <img src={createIcon} alt="Create Icon" />
            </div>
            <h3>Create Your Avatar</h3>
            <p>Use your preferred AI design tools to craft a unique avatar that represents your vision.</p>
          </div>
          <div className="card">
            <div className="icon">
              <img src={submitIcon} alt="Submit Icon" />
            </div>
            <h3>Submit</h3>
            <p>Upload your AI avatar design by the submission deadline.</p>
          </div>
          <div className="card">
            <div className="icon">
              <img src={stayTunedIcon} alt="Stay Tuned Icon" />
            </div>
            <h3>Stay Tuned</h3>
            <p>Watch for announcements on judging and winners!</p>
          </div>
        </div>
      </section>

{/*  guidelines*/}
    <section className="how-to-participate fade-up">
    <h2 className="guidelines-heading">Guidelines & Judging Criteria</h2>
    <div className="card-container">
          <div className="card">
           
            <h3>Originality</h3>
            <p>Your avatar should be unique and not copied from other sources.</p>
          </div>
          <div className="card">
          
            <h3>Creativity</h3>
            <p>Show your imagination in the design – the more innovative, the better!</p>
          </div>
          <div className="card">
          
            <h3>Technical Skill</h3>
            <p>Incorporate AI-based elements into the design.</p>
          </div>
          <div className="card">
          
            <h3>Submission Rules</h3>
            <p>Avatars must be in JPG or PNG format, 1000x1000 pixels.</p>
          </div>
        </div>
     
    </section>
    {/* testimonials */}
    <section className="testimonials-section fade-up">
    <h1 className="testimonials-heading">See What Creators Are Saying!</h1>
      <div className="testimonial-content">
        <blockquote>
          <p>{text}</p>
        </blockquote>
        <div className="testimonial-info">
          <img src={image} alt={name} className="user-avatar" />
          <h3>{name}</h3>
          <p className="user-title">{title}</p>
        </div>
        <div className="navigation">
          <button onClick={handlePrev} className="arrow left-arrow">&larr;</button>
          <button onClick={handleNext} className="arrow right-arrow">&rarr;</button>
        </div>
      </div>
    </section>
    <section className="ban fade-up">
      <div className="banner">
            <div className="banner-content">
                <div className="tagline">Unleash Your Creativity!</div>
                <h1>What are you waiting for</h1>
                <p>
                Submit your AI avatar design and chance to win exciting prizes, including a grand prize of ₹3 lakh and job offers, along with ₹2 lakh and ₹1 lakh rewards for runner-ups!
                </p>
                <a href="https://forms.gle/tSVsyVHFa7DZn3XX8" target="_blank" rel="noopener noreferrer">
                  <button className="enroll-button" >Join the Challenge</button>
                </a>
            </div>
        </div>
    </section>
      
      <Questions />
      <Footer />
    </div>
  );
};

export default AiAvatar;
