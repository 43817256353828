import { Box, Modal, Pagination, Typography } from "@mui/material";
import "./CoursesCatalog.scss";
import ProgramCard from "../programCard/ProgramCard";
import AddWrapper from "../AdWrapper";
import { useCallback, useEffect, useState } from "react";
import CourseDescriptionPage from "../courseDescription";
import { getSearchedCareerResults } from "../../../services/service-index";
import { HttpStatusCode } from "axios";
import CVLoader from "../../commonComponents/loader/loader";
import { useParams } from "react-router-dom";

const AllCourseResults = ({ setLoader, noResultsFound }) => {
  const [response, setResponse] = useState({});
  const cardsPerPage = 6;
  const [totalPages, setTotalPages] = useState(0);
  // const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCourse, setSelectedCourse] = useState({
    show: false,
    data: undefined,
  });

  const { searchKey } = useParams();

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const getCoursesBySearchKey = (input, from) => {
    setLoader(true);
    getSearchedCareerResults({ searchKey: input, from: from })
      .then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          shuffleArray(response.data.results);
          setResponse(shuffleArray(response.data));
          if (response.data?.totalCount) {
            setTotalPages(Math.ceil(response.data.totalCount / cardsPerPage));
          }
        }
      })
      .catch((err) => console.log(err?.message))
      .finally(() => setLoader(false));
  };
  useEffect(() => {
    if (searchKey) {
      getCoursesBySearchKey(searchKey, currentPage);
    } else {
      getCoursesBySearchKey("ALL", currentPage);
    }
  }, [currentPage]);
  const AllCourseResults = ({ setLoader, noResultsFound }) => {
    const [response, setResponse] = useState({});
    const cardsPerPage = 6;
    const [totalPages, setTotalPages] = useState(0);
    //   const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCourse, setSelectedCourse] = useState({
      show: false,
      data: undefined,
    });

    const getCoursesBySearchKey = (input, from) => {
      setLoader(true);
      getSearchedCareerResults({ searchKey: input, from: from })
        .then((response) => {
          if (response.status === HttpStatusCode.Ok) {
            setResponse(shuffleArray(response.data));

            if (response.data?.totalCount) {
              setTotalPages(Math.ceil(response.data.totalCount / cardsPerPage));
            }
          }
        })
        .catch((err) => console.log(err?.message))
        .finally(() => setLoader(false));
    };

    useEffect(() => {
      getCoursesBySearchKey("ALL", currentPage);
    }, [currentPage]);

    useEffect(() => {
      window.addEventListener("resetPagination", () => setCurrentPage(1));
      return () => {
        window.removeEventListener("resetPagination", () => setCurrentPage(1));
      };
    }, []);

    const RenderCatalog = useCallback(() => {
      if (!response?.results) return <></>;
      return (
        <>
          {noResultsFound ? (
            <Box
              className="helperText"
              sx={{ px: { xs: "16px", md: "32px" }, gap: "25px" }}
            >
              No results matching your search, you may also like:{" "}
            </Box>
          ) : (
            <></>
          )}
          <Box className="coursesCatalogWrapper" id="coursesCatalog">
            <Box
              className="sectionWrapper"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                px: { xs: "16px", md: "32px" },
                gap: "25px",
                justifyContent: { xs: "center", md: "space-between" },
              }}
            >
              {response?.results?.length !== 0 ? (
                <Box
                  className="cardsDiv"
                  sx={{
                    width: { xs: "100%", md: "72%" },
                    gap: { xs: "16px", md: "20px" },
                  }}
                >
                  {response?.results?.map((course) => {
                    return (
                      <ProgramCard
                        key={course._id}
                        course={course}
                        handleKnowMore={() =>
                          setSelectedCourse({ show: true, data: course })
                        }
                      />
                    );
                  })}
                  {response?.totalCount > cardsPerPage && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "24px",
                        marginBottom: { xs: "40px", md: "52px" },
                      }}
                    >
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        siblingCount={0}
                        variant="outlined"
                        shape="rounded"
                        onChange={(_, page) => setCurrentPage(page)}
                        sx={{
                          "& .MuiPaginationItem-root": {
                            backgroundColor: "#FFFFFF",
                            color: "#000000",
                          },
                          "& .Mui-selected": {
                            border: "1px #0F002E solid",
                            backgroundColor: "#FFFFFF !important",
                          },
                          "& .MuiPaginationItem-previousNext.Mui-disabled": {
                            backgroundColor: "#FFFFFF",
                            color: "#000000",
                          },
                          "& .MuiPaginationItem-previousNext:not(.Mui-disabled)":
                            {
                              backgroundColor: "#000000",
                              color: "#FFFFFF",
                            },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <Typography>
                    Oops ! no courses matching your search
                  </Typography>
                </Box>
              )}
              <Box
                className="AddsDiv "
                sx={{ width: { xs: "100%", md: "28%" }, fontSize: "30px" }}
              >
                <AddWrapper />
              </Box>
            </Box>
          </Box>
        </>
      );
    }, [response, totalPages, currentPage, noResultsFound]); ///
    return (
      <Box>
        <Modal
          open={selectedCourse.show}
          onClose={() => setSelectedCourse({ show: false })}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          style={{ overflowY: "scroll", scroll: "body" }}
        >
          <Box>
            <CourseDescriptionPage
              content={selectedCourse.data}
              handleClose={() => setSelectedCourse({ show: false })}
            />
          </Box>
        </Modal>
        <RenderCatalog></RenderCatalog>
      </Box>
    );
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const RenderCatalog = useCallback(() => {
    if (!response?.results) return <></>;
    return (
      <>
        {/* {loader ? (
          <Box className="loaderWrapper">
            <CVLoader />
          </Box>
        ) : (
          <></>
        )} */}
        {noResultsFound ? (
          <Box
            className="helperText"
            sx={{ px: { xs: "16px", md: "32px" }, gap: "25px" }}
          >
            No results matching your search, you may also like:{" "}
          </Box>
        ) : (
          <></>
        )}
        <Box className="coursesCatalogWrapper" id="coursesCatalog">
          <Box
            className="sectionWrapper"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              px: { xs: "16px", md: "32px" },
              gap: "25px",
              justifyContent: { xs: "center", md: "space-between" },
            }}
          >
            {response?.results?.length !== 0 ? (
              <Box
                className="cardsDiv"
                sx={{
                  width: { xs: "100%", md: "72%" },
                  gap: { xs: "16px", md: "20px" },
                }}
              >
                {response?.results?.map((course) => {
                  return (
                    <ProgramCard
                      key={course._id}
                      course={course}
                      handleKnowMore={() =>
                        setSelectedCourse({ show: true, data: course })
                      }
                    />
                  );
                })}
                {response?.totalCount > cardsPerPage && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "24px",
                      marginBottom: { xs: "40px", md: "52px" },
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(_, page) => setCurrentPage(page)}
                      sx={{
                        "& .MuiPaginationItem-root": {
                          backgroundColor: "#FFFFFF",
                          color: "#000000",
                        },
                        "& .Mui-selected": {
                          border: "1px #0F002E solid",
                          backgroundColor: "#FFFFFF !important",
                        },
                        "& .MuiPaginationItem-previousNext.Mui-disabled": {
                          backgroundColor: "#FFFFFF",
                          color: "#000000",
                        },
                        "& .MuiPaginationItem-previousNext:not(.Mui-disabled)":
                          {
                            backgroundColor: "#000000",
                            color: "#FFFFFF",
                          },
                      }}
                    />
                  </Box>
                )}
              </Box>
            ) : (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Typography>Oops ! no courses matching your search</Typography>
              </Box>
            )}
            <Box
              className="AddsDiv "
              sx={{ width: { xs: "100%", md: "28%" }, fontSize: "30px" }}
            >
              <AddWrapper />
            </Box>
          </Box>
        </Box>
      </>
    );
  }, [response, totalPages, currentPage]);
  return (
    <Box>
      <Modal
        open={selectedCourse.show}
        onClose={() => setSelectedCourse({ show: false })}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ overflowY: "scroll", scroll: "body" }}
      >
        <Box>
          <CourseDescriptionPage
            content={selectedCourse.data}
            handleClose={() => setSelectedCourse({ show: false })}
          />
        </Box>
      </Modal>
      <RenderCatalog></RenderCatalog>
    </Box>
  );
};

export default AllCourseResults;
