// import "../bookYourDemo/BookYourDemo.scss";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import bookYourDemo from "../../../assets/images/bookYourDemo/bookYourDemo.png";
// import gradient from "../../../assets/images/bookYourDemo/gradient.png";
// import underline from "../../../assets/images/bookYourDemo/underline.png";
// import arrowLine from "../../../assets/images/bookYourDemo/arrowLine.png";
// import { useState, useRef } from "react";
// import axios from "axios";
// import { isEmpty } from "lodash";
// import ReCAPTCHA from "react-google-recaptcha";

// const BookYourDemo = () => {
//   const recaptchaRef = useRef(null);
//   //
//   const [name, setName] = useState("");
//   const [emailId, setEmailId] = useState("");
//   const [mobileNum, setMobileNum] = useState("");
//   const [qualification, setQualification] = useState("");
//   const qualificationOptions = ["Student", "Graduate", "Working Professional"];
//   const [message, setMessage] = useState("");
//   const [messageCode, setMessageCode] = useState("#000");
//   const [loading, setLoading] = useState(false);

//   const validation = () => {
//     const mobileNumRegex = /^\d{10}$/;
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (isEmpty(name)) {
//       setMessage("Please enter full name");
//       setMessageCode("red");
//       return false;
//     } else if (
//       isEmpty(mobileNum) ||
//       mobileNum.length !== 10 ||
//       !mobileNumRegex.test(mobileNum)
//     ) {
//       setMessage("Please enter valid mobile number");
//       setMessageCode("red");
//       return false;
//     } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
//       setMessage("Please enter valid Email Id");
//       setMessageCode("red");
//       return false;
//     } else if (isEmpty(qualification)) {
//       setMessage("Please select qualification");
//       setMessageCode("red");
//       return false;
//     } else {
//       setMessage("");
//       setMessageCode("#000");
//       return true;
//     }
//   };
//   const getPageName = async () => {
//     const { hostname, pathname } = window.location;

//     // Extract the first part of the hostname and capitalize it
//     const siteName = hostname.split('.')[0].charAt(0).toUpperCase() + hostname.split('.')[0].slice(1);

//     // Process the pathname to create a descriptive format with "page" appended
//     const pathSegments = pathname.split('/').filter(Boolean);

//     if (pathSegments.length === 0) {
//       return `The user clicked on the ${siteName}  Main page for Booking Demo for masterclass`;
//     }

//     const pageDescriptions = pathSegments.map(segment => {
//       // Check if the segment is defined and not an empty string
//       if (segment && typeof segment === 'string') {
//         return segment.replace(/-/g, ' ').charAt(0).toUpperCase() + segment.slice(1) + " Page";
//       }
//       return ""; // Return an empty string if segment is invalid
//     }).filter(Boolean); // Filter out any empty strings

//     return `The user clicked through to the ${siteName} ${pageDescriptions.join(' -> ')} for Booking Demo for masterclass`;

//   };

//   const handleSubmit = async () => {
//     const currentUrl = window.location.href;
//     console.log(currentUrl);
//     const pageDescription = await getPageName();
//     console.log(pageDescription);
//     const recaptchaValue = recaptchaRef.current.getValue();

//     if (validation()) {
//       setMessage("Registering..");
//       setLoading(true);
//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_BACKEND_LINK}/api/demoRegistration`,
//           {
//             name,
//             emailId,
//             mobileNum,
//             qualification,
//             recaptchaValue,currentUrl,pageDescription
//           }
//         );
//         if (response?.status === 201) {
//           setMessage("Registered Successfully");
//           setMessageCode("#02da15");
//           setTimeout(() => {
//             setMessage("");
//             setMessageCode("#000");
//           }, 3000);
//           setName("");
//           setEmailId("");
//           setMobileNum("");
//           setQualification("");
//         } else {
//           setMessageCode("red");
//           setMessage("Please try again later");
//           recaptchaRef.current.reset();
//           setTimeout(() => {
//             setMessage("");
//             setMessageCode("#000");
//           }, 3000);
//         }
//       } catch (error) {
//         console.error("There was an error!", error);
//         setMessageCode("red");
//         setMessage(error.response.data.message);
//         recaptchaRef.current.reset();
//         setTimeout(() => {
//           setMessage("");
//           setMessageCode("#000");
//         }, 3000);
//       } finally {
//         setLoading(false);
//       }
//     }
//   };

//   return (
//     <>
//       <Box className="demoWrapper" sx={{ overflow: "hidden" }}>
//         <Box
//           className="headerWrapper"
//           sx={{ mt: { md: "53px", xs: "12px" }, mb: "48px" }}
//         >
//           <Box
//             component="img"
//             src={arrowLine}
//             className="arrowLine"
//             sx={{ left: { lg: "4%", sm: 0 } }}
//           />
//           <Typography
//             className="headText"
//             sx={{ fontSize: { xs: "20px", md: "36px" } }}
//           >
//             <span style={{ color: "#FE7465" }}>Try before you join! </span>
//             Take up our free demo session today!
//           </Typography>
//           <Typography
//             className="subText"
//             sx={{ display: { xs: "none", sm: "contents" } }}
//           >
//             Have a chance to experience our immersive & interactive learning
//             session with industry experts.
//           </Typography>
//         </Box>
//         <Box
//           sx={{
//             m: { lg: "80px 64px", md: "80px 32px", xs: "40px 0px" },
//             display: "flex",
//             flexDirection: { lg: "row", xs: "column-reverse" },

//             borderRadius: { md: "26px", xs: "12px" },
//           }}
//           className="contentWrapper"
//         >
//           <Box
//             component="img"
//             src={bookYourDemo}
//             sx={{
//               width: { xl: "60%", lg: "80%", xs: "100%" },
//               height: "100%",
//               borderRadius: { lg: "25px 0 0 25px", xs: "0 0 25px 25px" },
//             }}
//             className="bookingImg"
//           ></Box>
//           <Box
//             sx={{
//               width: { lg: "45%", xs: "100%" },
//               ml: { xl: "-10%", lg: "-20%" },
//               background: "linear-gradient(to right, #10303A, #123644)",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "flex-end",
//               borderRadius: { lg: "0 25px 25px", xs: "25px 25px 0 0" },
//             }}
//           >
//             <Box
//               style={{ position: "relative", width: "100%" }}
//               sx={{ ml: { lg: "-27%" } }}
//             >
//               <Box className="bookContent">
//                 <Box
//                   className="contentform"
//                   sx={{
//                     width: { xs: "100%", sm: "70%", lg: "100%" },
//                     mx: "16px",
//                     borderRadius: { md: "25px 25px 0px 0px", xs: "12px" },
//                   }}
//                 >
//                   <Typography className="bookHeader">
//                     <Typography className="bookHeader">
//                       Attend our free masterclass!
//                     </Typography>
//                     <Box component="img" src={underline} />
//                   </Typography>
//                   <Box
//                     className="formDetails"
//                     sx={{ flexDirection: { md: "row", xs: "column" } }}
//                   >
//                     <Box
//                       className="formInput halfInput"
//                       sx={{ width: { md: "48%", xs: "100%" } }}
//                     >
//                       <Typography className="formInputHeader">
//                         Full Name*
//                       </Typography>
//                       <TextField
//                         id="outlined-basic"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                         variant="outlined"
//                         className="formText"
//                       />
//                     </Box>
//                     <Box
//                       className="formInput halfInput"
//                       sx={{ width: { md: "48%", xs: "100%" } }}
//                     >
//                       <Typography className="formInputHeader">
//                         Mobile Number*
//                       </Typography>
//                       <TextField
//                         id="outlined-basic"
//                         value={mobileNum}
//                         onChange={(e) => setMobileNum(e.target.value)}
//                         variant="outlined"
//                         className="formText"
//                       />
//                     </Box>
//                   </Box>
//                   <Box className="formInput">
//                     <Typography className="formInputHeader">
//                       Email ID*
//                     </Typography>
//                     <TextField
//                       id="outlined-basic"
//                       value={emailId}
//                       onChange={(e) => setEmailId(e.target.value)}
//                       variant="outlined"
//                       fullWidth
//                       className="formText"
//                     />
//                   </Box>
//                   <Box sx={{ paddingBottom: "20px" }} className="formInput">
//                     <Typography className="formInputHeader">
//                       Qualification*
//                     </Typography>
//                     <Select
//                       id="demo-simple-select"
//                       fullWidth
//                       displayEmpty
//                       className="formText selectForm"
//                       renderValue={(selected) => {
//                         if (selected === "") {
//                           return <>Select your qualification</>;
//                         }
//                         return selected;
//                       }}
//                       value={qualification}
//                       onChange={(e) => setQualification(e.target.value)}
//                     >
//                       <MenuItem disabled value="">
//                         Select your qualification
//                       </MenuItem>
//                       {qualificationOptions.map((option) => (
//                         <MenuItem key={option} value={option}>
//                           {option}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </Box>

//                   {/* Captcha component  */}

//                   <ReCAPTCHA
//                     sitekey={process.env.REACT_APP_CAPTCHA_KEY}
//                     ref={recaptchaRef}
//                   />

//                   {/*  */}
//                   <Button
//                     variant="contained"
//                     className="contactButton"
//                     onClick={handleSubmit}
//                     disabled={loading}
//                   >
//                     Register
//                   </Button>
//                   <Typography
//                     className="freeTagline"
//                     sx={{ color: messageCode }}
//                   >
//                     {message}
//                   </Typography>
//                 </Box>
//                 <Box
//                   component="img"
//                   src={gradient}
//                   className="gradientImg"
//                   sx={{
//                     right: { lg: "-27%", sm: "0" },
//                     top: { lg: "-12%", sm: "3%" },
//                   }}
//                 />
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default BookYourDemo;
// import "../bookYourDemo/BookYourDemo.scss";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import bookYourDemo from "../../../assets/images/bookYourDemo/bookYourDemo.png";
// import gradient from "../../../assets/images/bookYourDemo/gradient.png";
// import underline from "../../../assets/images/bookYourDemo/underline.png";
// import arrowLine from "../../../assets/images/bookYourDemo/arrowLine.png";
// import { useState, useRef } from "react";
// import axios from "axios";
// import { isEmpty } from "lodash";
// import ReCAPTCHA from "react-google-recaptcha";

// const BookYourDemo = () => {
//   const recaptchaRef = useRef(null);

//   const [name, setName] = useState("");
//   const [emailId, setEmailId] = useState("");
//   const [mobileNum, setMobileNum] = useState("");
//   const [qualification, setQualification] = useState("");
//   const qualificationOptions = ["Student", "Graduate", "Working Professional"];
//   const [message, setMessage] = useState("");
//   const [messageCode, setMessageCode] = useState("#000");
//   const [loading, setLoading] = useState(false);

//   const validation = () => {
//     const mobileNumRegex = /^\d{10}$/;
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (isEmpty(name)) {
//       setMessage("Please enter full name");
//       setMessageCode("red");
//       return false;
//     } else if (
//       isEmpty(mobileNum) ||
//       mobileNum.length !== 10 ||
//       !mobileNumRegex.test(mobileNum)
//     ) {
//       setMessage("Please enter valid mobile number");
//       setMessageCode("red");
//       return false;
//     } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
//       setMessage("Please enter valid Email Id");
//       setMessageCode("red");
//       return false;
//     } else if (isEmpty(qualification)) {
//       setMessage("Please select qualification");
//       setMessageCode("red");
//       return false;
//     } else {
//       setMessage("");
//       setMessageCode("#000");
//       return true;
//     }
//   };

//   const handleSubmit = async () => {
//     const recaptchaValue = recaptchaRef.current.getValue();

//     if (validation()) {
//       setMessage("Registering..");
//       setLoading(true);
//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_BACKEND_LINK}/api/demoRegistration`,
//           {
//             name,
//             emailId,
//             mobileNum,
//             qualification,
//             recaptchaValue,
//           }
//         );
//         if (response?.status === 201) {
//           setMessage("Registered Successfully");
//           setMessageCode("#02da15");
//           setTimeout(() => {
//             setMessage("");
//             setMessageCode("#000");
//           }, 3000);
//           setName("");
//           setEmailId("");
//           setMobileNum("");
//           setQualification("");
//         } else {
//           setMessageCode("red");
//           setMessage("Please try again later");
//           recaptchaRef.current.reset();
//           setTimeout(() => {
//             setMessage("");
//             setMessageCode("#000");
//           }, 3000);
//         }
//       } catch (error) {
//         console.error("There was an error!", error);
//         setMessageCode("red");
//         setMessage(error.response.data.message);
//         recaptchaRef.current.reset();
//         setTimeout(() => {
//           setMessage("");
//           setMessageCode("#000");
//         }, 3000);
//       } finally {
//         setLoading(false);
//       }
//     }
//   };

//   return (
//     <>
//       <Box className="demoWrapper" sx={{ overflow: "hidden" }}>
//         <Box
//           className="headerWrapper"
//           sx={{ mt: { md: "53px", xs: "12px" }, mb: "48px" }}
//         >
//           <Box
//             component="img"
//             src={arrowLine}
//             className="arrowLine"
//             sx={{ left: { lg: "4%", sm: 0 } }}
//           />
//           <Typography
//             className="headText"
//             sx={{ fontSize: { xs: "20px", md: "36px" } }}
//           >
//             <span style={{ color: "#FE7465" }}>Try before you join! </span>
//             Take up our free demo session today!
//           </Typography>
//         </Box>
//         <Box
//           sx={{
//             m: { lg: "80px 64px", md: "80px 32px", xs: "40px 0px" },
//             display: "flex",
//             flexDirection: { lg: "row", xs: "column-reverse" },
//             borderRadius: { md: "26px", xs: "12px" },
//           }}
//           className="contentWrapper"
//         >
//           <Box
//             component="img"
//             src={bookYourDemo}
//             sx={{
//               width: { xl: "60%", lg: "80%", xs: "100%" },
//               height: "100%",
//               borderRadius: { lg: "25px 0 0 25px", xs: "0 0 25px 25px" },
//             }}
//             className="bookingImg"
//           />
//           <Box
//             sx={{
//               width: { lg: "45%", xs: "100%" },
//               ml: { xl: "-10%", lg: "-20%" },
//               background: "linear-gradient(to right, #10303A, #123644)",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "flex-end",
//               borderRadius: { lg: "0 25px 25px", xs: "25px 25px 0 0" },
//             }}
//           >
//             <Box
//               style={{ position: "relative", width: "100%" }}
//               sx={{ ml: { lg: "-27%" } }}
//             >
//               <Box className="bookContent">
//                 <Box
//                   className="contentform"
//                   sx={{
//                     width: { xs: "100%", sm: "70%", lg: "100%" },
//                     mx: "16px",
//                     borderRadius: { md: "25px 25px 0px 0px", xs: "12px" },
//                   }}
//                 >
//                   <Typography className="bookHeader">
//                     Attend our free masterclass!
//                   </Typography>
//                   <Box
//                     className="formDetails"
//                     sx={{ flexDirection: { md: "row", xs: "column" } }}
//                   >
//                     <Box
//                       className="formInput halfInput"
//                       sx={{ width: { md: "48%", xs: "100%" } }}
//                     >
//                       <Typography className="formInputHeader">Full Name*</Typography>
//                       <TextField
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                         variant="outlined"
//                         className="formText"
//                       />
//                     </Box>
//                     <Box
//                       className="formInput halfInput"
//                       sx={{ width: { md: "48%", xs: "100%" } }}
//                     >
//                       <Typography className="formInputHeader">Mobile Number*</Typography>
//                       <TextField
//                         value={mobileNum}
//                         onChange={(e) => setMobileNum(e.target.value)}
//                         variant="outlined"
//                         className="formText"
//                       />
//                     </Box>
//                   </Box>
//                   <Box className="formInput">
//                     <Typography className="formInputHeader">Email ID*</Typography>
//                     <TextField
//                       value={emailId}
//                       onChange={(e) => setEmailId(e.target.value)}
//                       variant="outlined"
//                       fullWidth
//                       className="formText"
//                     />
//                   </Box>
//                   <Box sx={{ paddingBottom: "20px" }} className="formInput">
//                     <Typography className="formInputHeader">Qualification*</Typography>
//                     <Select
//                       value={qualification}
//                       onChange={(e) => setQualification(e.target.value)}
//                       fullWidth
//                       displayEmpty
//                       renderValue={(selected) => {
//                         if (selected === "") {
//                           return <>Select your qualification</>;
//                         }
//                         return selected;
//                       }}
//                       sx={{
//                         '& .MuiOutlinedInput-root': {
//                           '&.Mui-focused fieldset': {
//                             borderColor: '#7233f7 !imortpant', // Focused border color
//                           },
//                           '&:hover fieldset': {
//                             borderColor: '#7233f7 !imortpant', // Hover border color
//                           },
//                           '& .MuiSelect-select': {
//                             color: '#7233f7 !imortpant' , // Text color of the selected option
//                           },
//                         },
//                       }}
//                     >
//                       <MenuItem disabled value="">
//                         Select your qualification
//                       </MenuItem>
//                       {qualificationOptions.map((option) => (
//                         <MenuItem key={option} value={option}>
//                           {option}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </Box>

//                   {/* Captcha component  */}
//                   <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY} ref={recaptchaRef} />
//                   {/*  */}
//                   <Button
//                     variant="contained"
//                     className="contactButton"
//                     onClick={handleSubmit}
//                     disabled={loading}
//                   >
//                     Register
//                   </Button>
//                   <Typography className="freeTagline" sx={{ color: messageCode }}>
//                     {message}
//                   </Typography>
//                 </Box>
//                 <Box
//                   component="img"
//                   src={gradient}
//                   className="gradientImg"
//                   sx={{
//                     right: { lg: "-27%", sm: "0" },
//                     top: { lg: "-12%", sm: "3%" },
//                   }}
//                 />
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default BookYourDemo;
import "../bookYourDemo/BookYourDemo.scss";
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import bookYourDemo from "../../../assets/images/bookYourDemo/bookYourDemo.png";
import gradient from "../../../assets/images/bookYourDemo/gradient.png";
import underline from "../../../assets/images/bookYourDemo/underline.png";
import arrowLine from "../../../assets/images/bookYourDemo/arrowLine.png";
import { useState, useRef } from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";

const BookYourDemo = () => {
  const recaptchaRef = useRef(null);

  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [qualification, setQualification] = useState("");
  const qualificationOptions = ["Student", "Graduate", "Working Professional"];
  const [message, setMessage] = useState("");
  const [messageCode, setMessageCode] = useState("#000");
  const [loading, setLoading] = useState(false);

  const validation = () => {
    const mobileNumRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (isEmpty(name)) {
      setMessage("Please enter full name");
      setMessageCode("red");
      return false;
    } else if (
      isEmpty(mobileNum) ||
      mobileNum.length !== 10 ||
      !mobileNumRegex.test(mobileNum)
    ) {
      setMessage("Please enter valid mobile number");
      setMessageCode("red");
      return false;
    } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
      setMessage("Please enter valid Email Id");
      setMessageCode("red");
      return false;
    } else if (isEmpty(qualification)) {
      setMessage("Please select qualification");
      setMessageCode("red");
      return false;
    } else {
      setMessage("");
      setMessageCode("#000");
      return true;
    }
  };

  const handleSubmit = async () => {
    const recaptchaValue = recaptchaRef.current.getValue();

    if (validation()) {
      setMessage("Registering..");
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_LINK}/api/demoRegistration`,
          {
            name,
            emailId,
            mobileNum,
            qualification,
            recaptchaValue,
          }
        );
        if (response?.status === 201) {
          setMessage("Registered Successfully");
          setMessageCode("#02da15");
          setTimeout(() => {
            setMessage("");
            setMessageCode("#000");
          }, 3000);
          setName("");
          setEmailId("");
          setMobileNum("");
          setQualification("");
        } else {
          setMessageCode("red");
          setMessage("Please try again later");
          recaptchaRef.current.reset();
          setTimeout(() => {
            setMessage("");
            setMessageCode("#000");
          }, 3000);
        }
      } catch (error) {
        console.error("There was an error!", error);
        setMessageCode("red");
        setMessage(error.response.data.message);
        recaptchaRef.current.reset();
        setTimeout(() => {
          setMessage("");
          setMessageCode("#000");
        }, 3000);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Box className="demoWrapper" sx={{ overflow: "hidden" }}>
        <Box
          className="headerWrapper"
          sx={{ mt: { md: "53px", xs: "12px" }, mb: "48px" }}
        >
          <Box
            component="img"
            src={arrowLine}
            className="arrowLine"
            sx={{ left: { lg: "4%", sm: 0 } }}
          />
          <Typography
            className="headText"
            sx={{ fontSize: { xs: "20px", md: "36px" } }}
          >
            <span style={{ color: "#FE7465" }}>Try before you join! </span>
            Take up our free demo session today!
          </Typography>
        </Box>
        <Box
          sx={{
            m: { lg: "80px 64px", md: "80px 32px", xs: "40px 0px" },
            display: "flex",
            flexDirection: { lg: "row", xs: "column-reverse" },
            borderRadius: { md: "26px", xs: "12px" },
          }}
          className="contentWrapper"
        >
          <Box
            component="img"
            src={bookYourDemo}
            sx={{
              width: { xl: "60%", lg: "80%", xs: "100%" },
              height: "100%",
              borderRadius: { lg: "25px 0 0 25px", xs: "0 0 25px 25px" },
            }}
            className="bookingImg"
          />
          <Box
            sx={{
              width: { lg: "45%", xs: "100%" },
              ml: { xl: "-10%", lg: "-20%" },
              background: "linear-gradient(to right, #10303A, #123644)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              borderRadius: { lg: "0 25px 25px", xs: "25px 25px 0 0" },
            }}
          >
            <Box
              style={{ position: "relative", width: "100%" }}
              sx={{ ml: { lg: "-27%" } }}
            >
              <Box className="bookContent">
                <Box
                  className="contentform"
                  sx={{
                    width: { xs: "100%", sm: "70%", lg: "100%" },
                    mx: "16px",
                    borderRadius: { md: "25px 25px 0px 0px", xs: "12px" },
                  }}
                >
                  <Typography className="bookHeader">
                    Attend our free masterclass!
                  </Typography>
                  <Box
                    className="formDetails"
                    sx={{ flexDirection: { md: "row", xs: "column" } }}
                  >
                    <Box
                      className="formInput halfInput"
                      sx={{ width: { md: "48%", xs: "100%" } }}
                    >
                      <Typography className="formInputHeader">Full Name*</Typography>
                      <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        className="formText"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#7233f7', // Set border color when focused
                            },
                          },
                        }}
                      />
                    </Box>
                    <Box
                      className="formInput halfInput"
                      sx={{ width: { md: "48%", xs: "100%" } }}
                    >
                      <Typography className="formInputHeader">Mobile Number*</Typography>
                      <TextField
                        value={mobileNum}
                        onChange={(e) => setMobileNum(e.target.value)}
                        variant="outlined"
                        className="formText"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#7233f7', // Set border color when focused
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="formInput">
                    <Typography className="formInputHeader">Email ID*</Typography>
                    <TextField
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      variant="outlined"
                      fullWidth
                      className="formText"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#7233f7', // Set border color when focused
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ paddingBottom: "20px" }} className="formInput">
                    <Typography className="formInputHeader">Qualification*</Typography>
                    <Select
                      value={qualification}
                      onChange={(e) => setQualification(e.target.value)}
                      fullWidth
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === "") {
                          return <>Select your qualification</>;
                        }
                        return selected;
                      }}
                      sx={{
                        // Target the root of the Select component and apply focused border color
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused': {
                            '& fieldset': {
                              borderColor: '#7234f7', // Set border color when focused
                            },
                          },
                        },
                        '& .MuiSelect-icon': {
                          color: '#7234f7', // Set color for the dropdown arrow
                        },
                      }}
                    >
                      <MenuItem disabled value="">
                        Select your qualification

                      </MenuItem>

                      {qualificationOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>


                  {/* Captcha component  */}
                  <ReCAPTCHA className="recaptcha-container" sitekey={process.env.REACT_APP_CAPTCHA_KEY} ref={recaptchaRef} />
                  {/*  */}
                  <Button
                    variant="contained"
                    className="contactButton"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Register
                  </Button>
                  <Typography className="freeTagline" sx={{ color: messageCode }}>
                    {message}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookYourDemo;
