import React, { useRef } from "react";
import Navbar from "../commonComponents/navbar/Navbar";
import TopLayer from "./topLayer/TopLayer";
import Join from "./join/Join";
import Mid1 from "./mid1/Mid1";
import Mid2 from "./mid2/Mid2";
import KeyBenefits from "./keyBenefits/KeyBenefits";
import Testimonials from "./testimonials/Testimonials";
import Last from "./last/Last";
import Footer from "../commonComponents/footer/Footer";
import { Helmet } from "react-helmet";

const CareerCounselling = () => {
  const targetJoinNow = useRef(null);
  return (
    <>

      <Helmet>
        <title>Career Counselling</title>
        <meta name="description" content="Career Counselling" />
        <meta name="keywords" content="career, counselling" />
      </Helmet>
      <Navbar />
      <TopLayer targetJoinNow={targetJoinNow} />
      <Join targetJoinNow={targetJoinNow} />
      <Mid1 />
      <Mid2 />
      <KeyBenefits />
      <Testimonials />
      <Last targetJoinNow={targetJoinNow} />
      <Footer />
    </>
  );
};

export default CareerCounselling;
