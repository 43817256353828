import { Box, Button, Typography } from "@mui/material";
import arrow from "../../../assets/images/dicover/arrow.svg";
import "../startLearning/StartLearning.scss";
import { useNavigate } from "react-router-dom";

const StartLearning = () => {
  const navigate = useNavigate();

  const buttonClick = () => {
    sessionStorage.setItem("scrollToCourses", "true");
    navigate("/", { state: { scrollToCourses: true } });
  };

  return (
    <Box>
      <Box
        className="startLearningWrapper"
        sx={{ py: { md: "60px", xs: "30px" }, px: "10px", my: "50px", mx: { xs: "18px", md: "32px" } }}
      >
        <Typography className="headTag" sx={{ fontSize: { xs: "16px", md: "32px" } }}>
          Feeling inspired?
        </Typography>
        <Typography className="subTag" sx={{ fontSize: { xs: "14px", md: "24px" } }}>
          Explore our on demand courses to achieve something extraordinary.
        </Typography>
        <Button
          sx={{ padding: "8px 18px", mt: "26px" }}
          className="startLearningBtn"
          onClick={buttonClick}
          variant="contained"
          endIcon={<img style={{ width: "18px" }} src={arrow} alt="arrow-icon" />}
        >
          Start Learning
        </Button>
      </Box>
    </Box>
  );
};

export default StartLearning;
