import React from "react";
import { Box, TextField, Typography, Button } from "@mui/material";
import Background from "../../../assets/images/ProjectsPage/background.svg";
import { useState } from "react";
import axios from "axios";

function End() {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleClick = async () => {
    setLoading(true);
    if (!validateEmail(email)) {
      setSuccessMessage("Please enter a valid email address");
      setLoading(false);
      return;
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_LINK}/api/addProjectRequirement`,
        {
          email: email,
        }
      );
      setEmail("");
      setSuccessMessage("You have been subscribed successfully!");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      setSuccessMessage(error.response.data);
      console.error(
        "Error subscribing:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      sx={{
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          marginBottom: "20px",
          position: "relative",
          width: "90%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          margin: "0 auto",
          borderRadius: "8px",
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: { xs: "8px", md: "20px" },
          textAlign: "center",
          paddingTop: { xs: "20px" },
          margintop: "15px",
        }}
      >
        <Typography
          sx={{
            marginBottom: { xs: "5px", md: "15px" },
            fontFamily: "Outfit",
            fontWeight: { xs: "400", md: "600" },
            fontSize: { xs: "25px", md: "40px" },
          }}
        >
          Let's Build Great Projects Together
        </Typography>
        <Typography
          sx={{
            marginBottom: 4,
            fontFamily: "Outfit",
            fontWeight: "300",
            fontSize: { xs: "13px", md: "15px" },
            width: { xs: "80%", md: "60%" },
          }}
        >
          Inviting startups and students to collaborate and help in building
          innovative projects with real-world impact.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "80%", md: "40%" },
            maxWidth: "600px",
            marginBottom: "20px",
          }}
        >
          <TextField
            placeholder="Email Address"
            variant="outlined"
            size="small"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#7233f7 !important", // Outline color when focused
                },
              },
              "& .MuiInputBase-input": {
                fontFamily: "Outfit",
              },
              "& input::placeholder": {
                fontFamily: "Outfit",
                fontWeight: "normal",
                color: "black",
                fontSize: "14px",
              },
              "&:hover fieldset": {
                borderColor: "black",
              },
              fontFamily: "Outfit",
              width: { xs: "400px", md: "150%" },
            }}
          />
          <Button
            variant="contained"
            onClick={handleClick}
            sx={{
              marginLeft: 1,
              backgroundColor: "#FE7465",
              color: "white",
              padding: { xs: "10px", md: "10px" },
              fontWeight: "400",
              fontSize: { xs: "11px", md: "11px" },
              fontFamily: "Outfit",
              width: "50%",
              boxShadow: "none",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#e75a53",
              },
            }}
          >
            Submit
          </Button>
        </Box>

        <Box
          sx={{
            color: successMessage.includes("successfully") ? "#02da15" : "red",
          }}
        >
          {successMessage}
        </Box>
      </Box>
    </Box>
  );
}

export default End;
