import "./TopLayer.scss";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import heroImage from "../../../assets/images/mockGroupDiscussion/MockGroupHeroImage.png";
const TopLayer = () => {
  const [isIOnToOneframeVisible, setisIOnToOneframeVisible] = useState(false);
  const handleCloseClick = () => {
    setisIOnToOneframeVisible(false);
  };
  return (
    <Box position="relative" className="hero-container">
      <Box className="hero-image-top" component="img" src={heroImage}></Box>
      <Box className="header-text-wrapper-top">
        <Box className="header-text-subwrapper-top">
          <Typography className="header-text-1-top">Join Our</Typography>
          <Typography className="header-text-2-top">
            Mock Group Discussions
          </Typography>
          <Typography className="header-text-3-top">
            Speak Up, Fear Less!
          </Typography>
        </Box>
        <Typography className="normal-text-top">
          Discover the joy of sharing your thoughts and overcoming speaking
          anxiety.
        </Typography>
        <Button
          onClick={() => {
            setisIOnToOneframeVisible(true);
          }}
          className="btn-top"
        >
          Join Now
        </Button>
      </Box>
      {isIOnToOneframeVisible && (
        <div className="iframe-popup">
          <span className="iframe-popup-close" onClick={handleCloseClick}>
            &times;
          </span>
          <iframe
            width="100%"
            height="100%"
            src="https://hemanth-coursevita94.zohobookings.in/portal-embed#/237817000000078002"
            frameborder="0"
            allowfullscreen=""
          >
            {" "}
          </iframe>
        </div>
      )}
      <Box className="gradient-bg"></Box>
    </Box>
  );
};

export default TopLayer;
