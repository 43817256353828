export const paginate = (array, page = 1, perPage = 10) => {
    // Calculate the starting and ending indices for the slice
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    // Return the slice of the array for the given page
    return array?.slice(startIndex, endIndex) || [];
}

export const sort = (data) => {
    const entries = Object.entries(data);
    const sortedEntries = entries.sort(([keyA], [keyB]) => keyA.toLowerCase().localeCompare(keyB.toLowerCase()));
    return Object.fromEntries(sortedEntries);
}