
import Navbar from "../commonComponents/navbar/Navbar";
import Terms from "./Terms";
import Footer from "../commonComponents/footer/Footer";
import Helmet from "react-helmet"
const TermsPage = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions</title>
        <meta name="description" content="Terms and Conditions" />
        <meta name="keywords" content="terms, conditions" />
      </Helmet>
      <Navbar/>
      <Terms/>
      <Footer/>
    </>
  );
};

export default TermsPage;