import React from "react";
import { Box, Typography } from "@mui/material";
import MentorsImages  from "../../../assets/images/mentors";
import linkedin from "../../../assets/images/mentors/linkedin.svg";
import "./Mentors.scss";

const Mentors = ({courseContent}) => {

  const mentors = courseContent?.mentors
  const data = mentors.map(mentor => ({
    label: mentor,
    icon: MentorsImages[mentor["profileImg"]] ? MentorsImages[mentor["profileImg"]] : MentorsImages["default"],
  }));

  return (
    <Box className="mentorsContainer" sx={{p:{md:"0px 100px 37px 100px"}}}>
      <Typography className="title" sx={{fontSize:{xs:"20px !important", md:"36px !important"},pt:{xs:"22px", md:"32px"}, lineHeight:{xs:"30px",md:"54px"}}}>
        Meet our <span style={{ color: "#FF7262" }}>Mentors</span>
      </Typography>
      <Box className="sliderContainer" sx={{mt:{xs:"20px",md:"47px"}}}>
        {data?.map((item, index) => (
            <Box className="mentorCard" key={index}>
              <Box><Box component="img" src={item.icon} className="profimg" alt={item.name} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ pt:"12px"}}>
                  <Typography className="name">{item.label.name}</Typography>
                  <Typography className="designation">
                    {item.label.tagLine}
                  </Typography>
                </Box>
             
              </Box></Box>
              <Box sx={{justifyContent:"center",display:"flex", alignContent:"center",gap:"8px",pt:"20px"}}>
                <Box src={linkedin} component="img" />
                <Typography
                  component="a"
                  href={item.label.linkedIn}
                  target="_blank"
                  className="linkedinLink"
                >
                  View LinkedIn Profile
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    
    </Box>
  );
};

export default Mentors;
