// HomeIcon.js
import React from "react";

const HomeIcon = ({ color = "#200E32", width = 512, height = 512 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M222.331 432.232H136.998C109.347 432.131 86.9284 409.795 86.7253 382.145V212.406C86.7796 203.15 90.9215 194.391 98.0412 188.476L113.067 177.345C118.553 171.501 119.111 162.582 114.396 156.1C109.681 149.617 101.025 147.4 93.7746 150.818L78.3775 162.319C62.846 174.337 53.6291 192.771 53.334 212.406V382.331C53.436 428.495 90.8339 465.893 136.998 465.995H222.331C231.552 465.995 239.027 458.52 239.027 449.299C239.027 440.078 231.552 432.603 222.331 432.603V432.232Z"
        fill={color}
      />
      <path
        d="M454.215 212.035C454.02 192.544 445.103 174.165 429.914 161.948L301.728 59.7337C273.559 37.0914 233.433 37.0914 205.264 59.7337L164.638 89.2293C157.005 94.7106 155.261 105.342 160.743 112.974C166.224 120.607 176.855 122.351 184.487 116.87L225.67 86.0757C241.834 72.7681 265.158 72.7681 281.322 86.0757L409.508 188.29C416.885 194.138 421.244 202.993 421.38 212.406V382.331C421.38 409.993 398.956 432.418 371.293 432.418H333.264C329.371 432.418 326.215 429.262 326.215 425.368V348.754C326.215 324.34 306.478 304.52 282.064 304.418H226.412C214.653 304.418 203.376 309.089 195.062 317.404C186.747 325.718 182.076 336.995 182.076 348.754V378.064C182.076 387.285 189.551 394.76 198.772 394.76C207.992 394.76 215.467 387.285 215.467 378.064V348.754C215.417 345.868 216.529 343.084 218.552 341.026C220.575 338.968 223.341 337.809 226.227 337.809H281.879C287.821 337.809 292.638 342.626 292.638 348.568V425.368C292.74 447.733 310.899 465.809 333.264 465.809H370.366C416.572 465.809 454.029 428.352 454.029 382.145L454.215 212.035Z"
        fill={color}
      />
    </svg>
  );
};

export default HomeIcon;
