import { Box, Button, Checkbox, Drawer, Typography } from "@mui/material";
import { useState } from "react";
import "./FiltersDrawer.scss";

const FiltersDrawer = ({ config, FIELD_MAPPER, setDrawerOpen, drawerOpen, handleApplyFilters, handleClearAll, selectedFilters, handleSelectedFilters }) => {
    const [filterSection, setFilterSection] = useState({});

    const handleFilterSelection = (data) => {
        setFilterSection(data);
    };

    const handleSelectAll = () => {
        const isAllSelected = filterSection?.options?.length === selectedFilters[filterSection.fieldId]?.options?.length;
        handleSelectedFilters({ fieldId: filterSection.fieldId, options: isAllSelected ? [] : filterSection?.options });
    };

    const handleOptionClick = (filterSection, option) => {
        let targetOptions = [];
        if (selectedFilters[filterSection.fieldId]?.options?.includes(option)) {
            targetOptions = selectedFilters[filterSection.fieldId]?.options?.filter(i => i != option);
        } else {
            const listItems = selectedFilters[filterSection.fieldId]?.options || [];
            targetOptions = [...listItems, option];
        }
        handleSelectedFilters({ fieldId: filterSection.fieldId, options: targetOptions });
    };

    return (
        <Drawer
            className="filtersDrawer"
            anchor="bottom"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            sx={{ display: { xs: 'flex', md: 'none' } }}
        >
            <Box className="filtersDrawerheader" sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
                <Typography className="filters">All Filters</Typography>
                <Typography className="clearAll" onClick={handleClearAll} sx={{ cursor: 'pointer' }}>Clear all</Typography>
            </Box>
            <Box className="drawerContent" sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
                <Box className="categoriesContainer">
                    {Object.keys(config||{}).map((key) => {
                        const info = {
                            fieldId: key,
                            options: config[key],
                            ...FIELD_MAPPER[key]
                        }
                        return (
                            <Box
                                id={info.fieldId}
                                key={info.fieldId}
                                className="drawerItem"
                                onClick={() => handleFilterSelection(info)}
                            >
                                <Box component="img" src={info.icon} sx={{ width: 24, height: 24 }} />
                                <Typography className="label">{info.label}</Typography>
                            </Box>
                        );
                    })}
                </Box>
                <Box className="optionsContainer" sx={{ width: "60%", padding: 2, overflowY: 'auto' }}>
                    {filterSection?.options?.length > 0 && (
                        <Box className="option">
                            <Checkbox
                                checked={filterSection?.options?.length === selectedFilters[filterSection.fieldId]?.options?.length}
                                onChange={() => handleSelectAll()}
                            />
                            <Typography className="label">Select All</Typography>
                        </Box>
                    )}
                    {filterSection?.options?.map((option, index) => {
                        const isChecked = selectedFilters[filterSection.fieldId]?.options?.includes(option) || false;
                        return <Box key={index} className="option">
                            <Checkbox
                                checked={isChecked}
                                onChange={() => handleOptionClick(filterSection, option)}
                            />
                            <Typography className="label">{option}</Typography>
                        </Box>
                    })}
                </Box>
            </Box>
            <Box className="buttonsWrapper">
                <Button variant="outlined" onClick={() => setDrawerOpen(false)} className="cancelBtn">Cancel</Button>
                <Button variant="contained" className="applyFiltersBtn" onClick={() => handleApplyFilters()}>Apply Filters</Button>
            </Box>
        </Drawer>
    );
};

export default FiltersDrawer;
