// export const getUniqueFilterValues = (project) => {
//   const uniqueCategories = [...new Set(project.map((item) => item.category))];
//   const uniqueSkills = [...new Set(project.flatMap((item) => item.skill))];
//   const uniqueDifficulties = [...new Set(project.map((item) => item.difficulty))];

//   return {
//     uniqueCategories,
//     uniqueSkills,
//     uniqueDifficulties,
//   };
// };

export const getUniqueFilterValues = (data) => {
  const uniqueSkills = [...new Set(data.flatMap((project) => project.skill))];
  const uniqueDifficulties = [...new Set(data.map((project) => project.difficulty))];
  return { uniqueSkills, uniqueDifficulties };
};

