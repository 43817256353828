import { Box, Button, Typography } from "@mui/material";
import "./ProgramCard.scss";
import share from "../../../assets/images/findYourCareer/share.svg";
import location from "../../../assets/images/findYourCareer/Location.svg";
import rupee from "../../../assets/images/findYourCareer/rupee.svg";
import tick from "../../../assets/images/findYourCareer/tick.svg";
import calender from "../../../assets/images/findYourCareer/calendar.svg";
import { useState } from "react";
import ShareDialogBox from "../../commonComponents/shareDialogBox/ShareDialogBox";
import { useNavigate } from "react-router-dom";

const ProgramCard = ({ course}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const courseTitle = course.title.replace(" - ", ' ').replace(" : ", " ").replace("&","_").replace(/ /g, "_");

  const handleKnowMore = () => {
    navigate(`/findyourcareer/${courseTitle}-${course._id}`);
  };


  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: course.title,
          text: `Check out this course: ${course.title} at ${course.instituteName}`,
          url: `${window.location.origin}/findyourcareer/${course._id}`,
        })
        .catch(console.error);
    } else {
      setOpen(true);
    }
  };

  const labels = [
    {
      icon: tick,
      tag: "Eligibility",
      value: course.courseLevel,
    },
    {
      icon: calender,
      tag: "Duration",
      value: course.duration,
    },
    {
      icon: location,
      tag: "Location",
      value: course.location,
    },
    {
      icon: rupee,
      tag: "Price",
      value: course.totalFee,
    },
  ];

  return (
    <Box
      className="programCardWrapper"
      sx={{
        p: { xs: "24px 16px", md: "24px 24px 26px 24px" },
        gap: { sm: "25px" },
      }}
    >
      <Box
        sx={{
          p: "14px",
          maxHeight: "76px",
          border: "1px solid #F1F1F1",
          borderRadius: "8px",
          display: { xs: "none", sm: "inline-block" },
        }}
      >
        <Box
          component="img"
          src={course.logoUrl}
          sx={{ height: "48px", width: "48px" }}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box
          className="headerWrapper"
          sx={{ justifyContent: { xs: "flex-start", sm: "space-between" } }}
        >
          <Box
            sx={{
              p: "12px 7px",
              mr: "17px",
              border: "1px solid #F1F1F1",
              borderRadius: "8px",
              display: { xs: "inline-block", sm: "none" },
              height: "62px",
            }}
          >
            <Box
              component="img"
              src={course.logoUrl}
              sx={{ height: "38px", width: "48px" }}
            />
          </Box>
          <Box>
            <Typography
              className="course"
              sx={{ fontSize: { xs: "14px", md: "20px" } }}
            >
              {course.title}
            </Typography>
            <Typography
              className="institute"
              sx={{ fontSize: { xs: "12px", md: "14px" } }}
            >
              {course.instituteName}
            </Typography>
          </Box>
          <Box
            onClick={handleShare}
            sx={{
              border: "1px solid #4F4F4F1A",
              borderRadius: "50%",
              p: "13.7px",
              height: "48px",
              display: { xs: "none", sm: "inline-block" },
              cursor: "pointer",
            }}
          >
            <a>
              <Box
                sx={{ height: "21px", width: "21px" }}
                component="img"
                src={share}
              />
            </a>
          </Box>
        </Box>
        <Box
          className="pointWrapper"
          sx={{
            gap: { xs: "20px", md: "40px" },
            justifyContent: { xs: "space-between", md: "flex-start" },
          }}
        >
          {labels?.map((item, index) => {
            return (
              <Box key={index}>
                <Box sx={{ display: "flex" }}>
                  <Box component="img" src={item.icon} />
                  <Typography
                    className="tag"
                    sx={{ fontSize: { xs: "10px", md: "12px" } }}
                  >
                    {" "}
                    {item.tag}
                  </Typography>
                </Box>
                <Typography
                  className="value"
                  sx={{ fontSize: { xs: "10px", md: "14px" } }}
                >
                  {item.value}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { sm: "space-between" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            className="tagsWrapper"
            sx={{
              gap: { xs: "8px", md: "16px" },
              justifyContent: "flex-start",
            }}
          >
            {course?.tags?.map((tag, index) => (
              <Box
                key={index}
                className="contentBox"
                sx={{
                  p: { xs: "6px 15px", md: "8px 20px" },
                  height: { md: "37px", xs: "27px" },
                }}
              >
                <Typography
                  className="tag"
                  sx={{ fontSize: { xs: "10px", md: "12px" } }}
                >
                  {tag}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              alignSelf: "flex-end",
              pt: { xs: "28px", sm: "0px" },
            }}
          >
            <Button
              variant="outlined"
              className="shareBtn"
              onClick={handleShare}
              sx={{
                width: "115px",
                height: "36px",
                display: { xs: "flex", sm: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                style={{ width: "18px", height: "18px", marginRight: "10px" }}
                src={share}
                alt="share-icon"
              />
              share
            </Button>
            <Button
              variant="outlined"
              className="knowmoreBtn"
              onClick={handleKnowMore}
              
              sx={{ width: { xs: "115px", md: "160px" }, height: "36px" }}
            >
              Know more
            </Button>
          </Box>
        </Box>
      </Box>
      <ShareDialogBox
        open={open}
        handleClose={handleClose}
        endpoint={course?._id}
      />
    </Box>
  );
};

export default ProgramCard;
