import { Box, Typography } from "@mui/material";
import "./secondLayer.scss";
import React from "react";
import Tick from "../../../assets/images/workshop/tick.svg";

const SecondLayer = ({ data }) => {
  return (
    <Box>
      <Typography className="header-text-1-secondlayer-workshop">
        {data.heading1.plainText}{" "}
        <span className="header-text-2-secondlayer-workshop">
          {data.heading1.highlightedText}
        </span>
      </Typography>

      <Box className="box-wrapper-2-secondlayer-workshop">
        {data.learningPoints.map((item, index) => (
          <Box key={index} className="box-wrapper-1-secondlayer-workshop" display="flex">
            <Box component="img" src={Tick}></Box>
            <Typography>{item.text}</Typography>
          </Box>
        ))}
      </Box>

      <Typography className="header-text-2-secondlayer-workshop">
        {data.heading2.plainText}{" "}
        <span className="header-text-1-secondlayer-workshop">
          {data.heading2.highlightedText}
        </span>
      </Typography>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        paddingTop="70px"
        paddingBottom="70px"
      >
        <Box position="relative" height="50vh">
          {data.audience.map((item, index) => (
            <Typography key={index} className={`text-${index + 1}-secondlayer-workshop`}>
              {item}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SecondLayer;
