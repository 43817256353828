import { motion } from "framer-motion";
import "./redlive.css";
import img from "./Mic.png"
function Redlive() {
    return (
        <div className="red">
          <motion.div
            className="round"
            initial={{ opacity: 0, scale: 0 }} // Starts fully hidden and small
            animate={{
              opacity: [0, 0.8, 0.5,0.8,0], // Fades in to full opacity, then fades out to 0.5
              scale: [0, 1.5, 0.8,1.5,0], // Grows to full size, then shrinks to 50%
            }}
            exit={{
              opacity: 0, // Fades out
              scale: 0, // Shrinks to 0
            }}
            transition={{
              duration: 2, // Slower, 6 seconds total per cycle
              ease: "easeInOut", // Smooth easing in and out
              repeat: Infinity, // Infinite loop
              repeatType: "loop", // Continuous looping
              repeatDelay: 0.5, // Adds a 1-second pause between cycles
            }}
          />
          <img className="Mic"src={img}/>
        </div>
      );
    }

export default Redlive;