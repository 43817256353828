import "./Payment.scss";
import { useState } from "react";

function PaymentForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validate(formData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      console.log(formData);
    }
  };

  const validate = (data) => {
    let newErrors = {};
    if (!data.name) newErrors.name = "Name is required.";
    if (!data.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!data.phone) newErrors.phone = "Phone number is required.";
    return newErrors;
  };

  return (
    <div className="paymentForm">
      <form onSubmit={handleSubmit} className="form">
        <label className="form-label">
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-input"
          />
          {errors.name && <p className="form-error">{errors.name}</p>}
        </label>
        <label className="form-label">
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
          />
          {errors.email && <p className="form-error">{errors.email}</p>}
        </label>
        <label className="form-label">
          Phone:
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="form-input"
          />
          {errors.phone && <p className="form-error">{errors.phone}</p>}
        </label>
        <input type="submit" value="Make Payment" className="form-submit" />
      </form>
    </div>
  );
}

export default PaymentForm;
