import { useState, useEffect, useRef } from "react";
import { Box, Button } from "@mui/material";
import "./ExploreSection.scss";
import scrollIcon from "../../../assets/images/scroll1.png";
import card1 from "../../../assets/images/explore/landingSliderImage1.png";
import card2 from "../../../assets/images/explore/landingSliderImage2.png";
import card3 from "../../../assets/images/explore/landingSliderImg3.png";
import card4 from "../../../assets/images/explore/landingSliderImage4.png";
import card5 from "../../../assets/images/explore/landingSliderImage1.png";
import card6 from "../../../assets/images/explore/landingSliderImage2.png";
import card9 from "../../../assets/images/explore/card7.png";
import card10 from "../../../assets/images/explore/card8.png";
import card11 from "../../../assets/images/explore/card9.png";
import dropArrow from "../../../assets/images/navbar/back.gif";
import internvita from "../../../assets/images/explore/internvita.jfif";
import { useLocation } from "react-router-dom";
import Companies from "../companies/Companies";
// import CourseCard from "../exploreSection/courseCard/CourseCard.jsx";

const ExploreSection = () => {
  const location = useLocation();
  const bannerContent = [
    {
      tag1: "Become",
      tag2: "a successful ",
      boldTag: "data engineer",
      upcoming: true,
      date: "",
      image: card1,
      Link: "",
    },
    {
      tag1: "Master",
      tag2: "",
      boldTag: "AWS and Devops",
      upcoming: true,
      date: "02/09/2024",
      image: card9,
      Link: "https://us06web.zoom.us/meeting/register/tZMuf-qpqjorHdS9_mK10Dw2c3Bl3jWc_gd1",
    },
    {
      tag1: "",
      tag2: "25 Day Frontend Course",
      boldTag: "25 Day Frontend Course",
      upcoming: true,
      date: "02/09/2024",
      image: card11,
      Link: "https://us06web.zoom.us/meeting/register/tZcqcumoqjwrHN1HQzp96UWaUvQckDu0WKDQ",
    },
    {
      tag1: "",
      tag2: "25 Day Python Course",
      boldTag: "25 Day Python Course",
      upcoming: true,
      date: "09/09/2024",
      image: card10,
      Link: "https://us06web.zoom.us/meeting/register/tZElc-mtrDMuHtwGTQ7Dh1iuU_RS26YYb_rX",
    },
    {
      tag1: "Become",
      tag2: "",
      boldTag: "a Successful Data Scientist",
      upcoming: true,
      date: "",
      image: card2,
      Link: "https://coursevita.com/courses/dataScience",
    },
    {
      tag1: "Become",
      tag2: "",
      boldTag: "a successful data Analyst",
      upcoming: true,
      date: "",
      image: card3,
      Link: "https://coursevita.com/courses/dataAnalytics",
    },
    {
      tag1: "Become Expert",
      tag2: "",
      boldTag: "in ML Ops",
      upcoming: true,
      date: "",
      image:
        "https://coursevita-main-site.s3.eu-north-1.amazonaws.com/courseImages/MlOps.webp",
      Link: "https://coursevita.com/courses/mlOpsTraining",
    },
    {
      tag1: "Become",
      tag2: "",
      boldTag: "a successful front-end developer",
      upcoming: true,
      date: "02/09/2024",
      image:
        "https://coursevita-main-site.s3.eu-north-1.amazonaws.com/courseImages/fullStackDevelopment.webp",
      Link: "https://us06web.zoom.us/meeting/register/tZcqcumoqjwrHN1HQzp96UWaUvQckDu0WKDQ",
    },
    {
      tag1: "Become",
      tag2: "",
      boldTag: "a successful data visualization expert",
      upcoming: true,
      date: "",
      image: card4,
      Link: "https://coursevita.com/courses/dataVisualization",
    },
    {
      tag1: "Python",
      tag2: "",
      boldTag: "for Data Analytics",
      upcoming: true,
      date: "24th july 2024",
      image: card5,
      Link: "https://upskill.coursevita.com/python-data-analytics",
    },
    // {
    //   tag1: "Open your wings",
    //   tag2: "Join ",
    //   boldTag: "Internvita",
    //   upcoming: true,
    //   date: "18th July, 2024",
    //   image: internvita,
    //   Link: "https://abrupt-class-af8.notion.site/Internvita-Project-List-1ffd698d12ac43ee9c4493c5584a181e",
    // },

    {
      tag1: "",
      tag2: "Join ",
      boldTag: "Find Your Career",
      upcoming: true,
      date: "",
      image: card6,
      Link: "https://coursevita.com/findyourcareer",
    },
  ];
  var cloneContent = [
    ...bannerContent,
    ...bannerContent,
    ...bannerContent,
    ...bannerContent,
  ];
  const handleStartLearning = () => {
    sessionStorage.removeItem("scrollToCourses");
    const coursesSection = document.getElementById("courses");
    if (coursesSection) {
      coursesSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const shouldScroll = sessionStorage.getItem("scrollToCourses") === "true";
    if (shouldScroll && location.state?.scrollToCourses) {
      sessionStorage.removeItem("scrollToCourses");
      window.scrollTo(0, 0);
      setTimeout(() => {
        handleStartLearning();
      }, 500);
    }
  }, [location]);

  const getRandomColor = () => {
    const colors = ["#e0d2fd"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const [hoveredBox, setHoveredBox] = useState({ x: null, y: null });
  const [dimensions, setDimensions] = useState({ rows: 17, cols: 0 });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const boxSize = 35;
      const cols = Math.floor(width / boxSize);
      setDimensions({ rows: 17, cols });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const highlightNeighbors = (x, y) => {
    const neighbors = [
      [x, y],
      [x - 1, y],
      [x - 1, y + 1],
      [x, y - 1],
    ];

    neighbors.forEach(([nx, ny]) => {
      const box = document.getElementById(`${nx}-${ny}`);
      if (box) {
        box.style.backgroundColor = getRandomColor();
      }
    });
  };

  const clearColors = () => {
    const boxes = document.querySelectorAll(".box");
    boxes.forEach((box) => {
      box.style.backgroundColor = "";
    });
  };

  const handleMouseOver = (x, y) => {
    setHoveredBox({ x, y });
    clearColors();
    highlightNeighbors(x, y);
  };

  const handleMouseLeave = () => {
    clearColors();
  };

  const { rows, cols } = dimensions;

  // const cardClassName = `courseCard ${stopAnimation ? 'stop-animation' : ''}`;

  const targetRef = useRef(null);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleClickOutside = (event) => {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      setIsPaused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [isPaused, setIsPaused] = useState(false);
  const handleTouchStart = (e) => {
    setIsPaused(true);
    startX.current = e.touches[0].clientX;
    scrollLeft.current = targetRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    setIsPaused(true);
    const x = e.touches[0].clientX;
    const walk = (x - startX.current) * 2;
    targetRef.current.scrollLeft = scrollLeft.current - walk;
  };

  return (
    <Box className="exploreSection">
      <Box className="overlayContainer">
        <Box className="text1" sx={{ fontSize: { xs: "26px", md: "48px" } }}>
          Its never too late to upskill.
          
        </Box>
        <Box
          className="text2"
          sx={{
            display: { xs: "block", md: "flex" },
            fontSize: { xs: "20px", md: "48px" },
          }}
        >
          <span
            className="slidingText"
            sx={{ fontSize: { xs: "32px", md: "64px" } }}
          >
            <span className="Story">Data Science</span>
            <span className="Story">Data Analytics</span>
            <span className="Story">Software Development</span>
            <span className="Story">Cloud Computing</span>
          </span>{" "}
          <span className="mentored">mentored by top industry experts</span>
        </Box>
        <Box className="btnOverlay">
          <Button
            variant="contained"
            className="startLearningBtn"
            endIcon={
              <img className="scrollIcon" src={scrollIcon} alt="Scroll Icon" />
            }
            onClick={handleStartLearning}
          >
            Start learning
          </Button>
        </Box>
        <Companies />
      </Box>

      {/* Courses list */}
      {/* <Box
        ref={targetRef}
        className="coursesSlider"
        sx={{ overflowX: { xs: "auto", md: "hidden" }, display: "flex" }}
      >
        <Box
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          className={`innerSlider ${isPaused ? "paused" : ""}`}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "row",
              md: "column",
            },
            animation: isPaused ? "none" : "slide 20s infinite linear",
          }}
        >
          {cloneContent?.map((item, index) => (
            <CourseCard key={index} content={item} />
          ))}
        </Box>
      </Box> */}

      <Box className="App">
        {[...Array(rows)].map((_, i) => (
          <Box key={i} className="row">
            {[...Array(cols)].map((_, j) => (
              <Box
                key={`${i}-${j}`}
                id={`${i}-${j}`}
                className={`box animate ${
                  hoveredBox.x === i && hoveredBox.y === j ? "hovered" : ""
                }`}
                onMouseOver={() => handleMouseOver(i, j)}
                onMouseLeave={handleMouseLeave}
              >
                {j % 2 === 0 && i % 2 === 0 && (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  ></svg>
                )}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ExploreSection;
//
