import "./join.scss";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import a1 from "../../../assets/images/mockGroupDiscussion/av1.png";
import a2 from "../../../assets/images/mockGroupDiscussion/av2.png";
import a3 from "../../../assets/images/mockGroupDiscussion/av3.png";
import calendar from "../../../assets/images/mockGroupDiscussion/calendar.svg";
import clock from "../../../assets/images/mockGroupDiscussion/clock.svg";
import redDot from "../../../assets/images/mockGroupDiscussion/redDot.svg";
import greenDot from "../../../assets/images/mockGroupDiscussion/greenDot.svg";
import jImg1 from "../../../assets/images/mockGroupDiscussion/jImg1.png";
import jImg2 from "../../../assets/images/mockGroupDiscussion/jImg2.png";
import jImg3 from "../../../assets/images/mockGroupDiscussion/jImg3.png";
import jImg4 from "../../../assets/images/mockGroupDiscussion/jImg4.png";
import circle1 from "../../../assets/images/mockGroupDiscussion/circle1.svg";
import circle2 from "../../../assets/images/mockGroupDiscussion/circle2.svg";
import circle3 from "../../../assets/images/mockGroupDiscussion/circle3.svg";
import jImgM1 from "../../../assets/images/mockGroupDiscussion/jImgM1.png";
import jImgM2 from "../../../assets/images/mockGroupDiscussion/jImgM2.png";
import jImgM3 from "../../../assets/images/mockGroupDiscussion/jImgM3.png";
import jImgM4 from "../../../assets/images/mockGroupDiscussion/jImgM4.png";
import { useRef, useEffect } from "react";

const { Box, Typography, Button } = require("@mui/material");

const data = [
  {
    isLive: "Live",
    title: "React For Frontend Mock Interview",
    date: "18th June, 2024",
    time: "11:00 AM",
    dot: redDot,
  },
  {
    isLive: "Live",
    title: "UX For Beginners",
    date: "18th June, 2024",
    time: "11:00 AM",
    dot: redDot,
  },
  {
    isLive: "Upcoming",
    title: "AI in Data Science",
    date: "18th June, 2024",
    time: "11:00 AM",
    dot: greenDot,
  },
];

const images = [jImgM1, jImgM2, jImgM3, jImgM4];

const Join = () => {
  const targetRef = useRef(null);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
    scrollLeft.current = targetRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    const x = e.touches[0].clientX;
    const walk = (x - startX.current) * 2;
    targetRef.current.scrollLeft = scrollLeft.current - walk;
  };

  return (
    <Box>
      {/* <Box margin="36px 0">
        <Typography className="header-text-join">
          Join <span className="header-text-join-2">Discussions</span>
        </Typography>
        <Typography padding="0px 10px" className="normal-text-join">
          Find interesting projects and get started with an execution plan
        </Typography>
        <Box className="card-wrapper-join">
          {data.map((item, index) => {
            return (
              <Box className="card-subwrapper-join" key={index}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography className="isLive-join">
                    <Box component="img" src={item.dot}></Box>
                    {item.isLive}
                  </Typography>
                  <AvatarGroup
                    total={8}
                    spacing="small"
                    className="avg-G"
                    sx={{
                      "& .MuiAvatarGroup-avatar": {
                        color: "black",
                        fontSize: "16px",
                      },
                    }}
                  >
                    <Avatar alt="a1" src={a1} className="avg-i" />
                    <Avatar alt="a2" src={a2} className="avg-i" />
                    <Avatar alt="a3" src={a3} className="avg-i" />
                  </AvatarGroup>
                </Box>
                <Typography className="title-join">{item.title}</Typography>
                <Box className="btnAndTimeWrapper">
                  <Box
                    display="flex"
                    alignItems="center"
                    flex="2 !important"
                    gap="8px"
                  >
                    <Box
                      display="flex"
                      width="auto"
                      justifyContent="center"
                      alignItems="center"
                      gap="4px"
                    >
                      <Box
                        className="calClock-join"
                        component="img"
                        src={calendar}
                      ></Box>
                      <Typography className="date-time-join">
                        {item.date}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      width="auto"
                      justifyContent="center"
                      alignItems="center"
                      gap="4px"
                    >
                      <Box
                        className="calClock-join"
                        component="img"
                        src={clock}
                      ></Box>
                      <Typography className="date-time-join">
                        {item.time}
                      </Typography>
                    </Box>
                  </Box>
                  <Button className="btn-join">Join</Button>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box> */}
      <Box className="gradient-bg-2">
        <Box className="circleW cw1" component="img" src={circle1} />
        <Box className="circleW cw2" component="img" src={circle2} />
        <Box className="circleW cw3" component="img" src={circle3} />
        <Box className="wrapper-join-first">
          <Typography className="header-text-join-3">
            <span style={{ color: "black" }}>Why</span> Group Discussions
          </Typography>
          <Typography className="normal-text-join-2">
            Because we want to help you become more confident, collaborative,
            and thoughtful by sharing and exploring different perspectives and
            ideas.
          </Typography>
        </Box>
        <Box justifyContent="end" className="wrapper-join-second">
          <Box className="image-wrapper-join">
            <Box width="180px" height="auto" component="img" src={jImg1}></Box>
            <Box width="180px" height="auto" component="img" src={jImg2}></Box>
          </Box>
          <Box className="image-wrapper-join-2">
            <Box width="180px" height="auto" component="img" src={jImg3}></Box>
            <Box width="180px" height="auto" component="img" src={jImg4}></Box>
          </Box>
          <Box ref={targetRef} className="image-slider-join">
            <Box
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              className="image-innerSlider-join"
            >
              {images.map((item) => (
                <Box
                  className="images-slider-join"
                  component="img"
                  src={item}
                ></Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Join;
