import { useEffect } from "react";
import Checkout from "./Checkout";
import Navbar from "../commonComponents/navbar/Navbar";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../commonComponents/footer/Footer";
import Helmet from "react-helmet";

const CheckoutPage = ({ coursesContent }) => {
  const { pathname } = useLocation();
  const { courseName } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const courseContent = coursesContent.find(
    (course) => course.learnmoreCourse === courseName
  );
  return (
    <>
      <Helmet>
        <title>
          {courseContent ? `Checkout - ${courseContent.course}` : "Course Page"}
        </title>
        <meta
          name="description"
          content={
            courseContent ? courseContent.courseDesc : "Course description"
          }
        />
        <meta
          name="keywords"
          content={
            courseContent
              ? courseContent.keywords.join(", ")
              : "courses, learning"
          }
        />
      </Helmet>

      <Navbar />
      <Checkout courseContent={courseContent} />
      <Footer />
    </>
  );
};

export default CheckoutPage;
