import { ThreeDots } from "react-loader-spinner"

const CVLoader = (props) => {
    return (
        <ThreeDots
            visible={props.isLoading}
            height="60"
            width="60"
            color="#7234F7"
            radius="6"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass={"cvloader"}
            />
    )
}

export default CVLoader;