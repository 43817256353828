// import { Box, Typography, Dialog, DialogContent,Button } from "@mui/material";
// import "./thirdLayer.scss";
// import React from "react";
// import dropDown from "../../../assets/images/workshop/dropDown.svg";
// import download from "../../../assets/images/workshop/Download.svg";

// const ThirdLayer = ({ data }) => {
//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center"
//       alignItems="center"
//     >
//       <Typography className="header-text-1-secondlayer-workshop">
//         <span className="header-text-2-secondlayer-workshop">Learn with </span>
//         top rated tools
//       </Typography>
//       <Box className="box-wrapper-1-thirdLayer-workshop">
//         {data.tools.map((tool, index) => (
//           <Box
//             key={index}
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             className="box-wrapper-2-thirdLayer-workshop"
//           >
//             <Box component="img" src={tool.img} alt={tool.name}></Box>
//             <Typography>{tool.name}</Typography>
//           </Box>
//         ))}
//       </Box>
//       <Typography width="700px" className="header-text-1-secondlayer-workshop">
//         {data.headerText.before}
//         <span className="header-text-2-secondlayer-workshop">
//           {data.headerText.highlight}
//         </span>
//         {data.headerText.after}
//       </Typography>
//       <Box className="box-wrapper-4-thirdlayer-workshop">
//         {data.learningModules.map((item, index) => (
//           <Button key={index} className="box-wrapper-5-thirdlayer-workshop">
//             <Typography className="text-1-thirdlayer-workshop">
//               Module {index + 1}
//             </Typography>
//             <Typography className="text-2-thirdlayer-workshop">
//               {item}
//             </Typography>
//             <Box
//               position="absolute"
//               right="10px"
//               component="img"
//               src={dropDown}
//               alt="Dropdown"
//             ></Box>
//           </Button>
//         ))}
//       </Box>
//       <Button className="btn-thirdLayer-workshop">
//         <Box component="img" src={download} alt="Download curriculum" />
//         &nbsp; Download curriculum
//       </Button>
//     </Box>
//   );
// };

// export default ThirdLayer;

// import { Box, Typography, Dialog, DialogContent, Button } from "@mui/material";
// import "./thirdLayer.scss";
// import React, { useState } from "react"; // Import useState for dialog state management
// import dropDown from "../../../assets/images/workshop/dropDown.svg";
// import download from "../../../assets/images/workshop/Download.svg";
// import Curriculum from "../../commonComponents/curriculum/Curriculum"; // Import Curriculum component

// const ThirdLayer = ({ data }) => {
//   const [dialogOpen, setDialogOpen] = useState(false); // State for controlling dialog visibility

//   const handleOpenDialog = () => {
//     setDialogOpen(true);
//   };

//   const handleCloseDialog = () => {
//     setDialogOpen(false);
//   };

//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center"
//       alignItems="center"
//     >
//       <Typography className="header-text-1-secondlayer-workshop">
//         <span className="header-text-2-secondlayer-workshop">Learn with </span>
//         top rated tools
//       </Typography>
//       <Box className="box-wrapper-1-thirdLayer-workshop">
//         {data.tools.map((tool, index) => (
//           <Box
//             key={index}
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             className="box-wrapper-2-thirdLayer-workshop"
//             sx={{ margin: '0 8px' }} // Adjust the horizontal margin as needed
//           >
//             <Box component="img" src={tool.img} alt={tool.name}></Box>
//             <Typography>{tool.name}</Typography>
//           </Box>
//         ))}
//       </Box>

//       <Typography width="700px" className="header-text-1-secondlayer-workshop">
//         {data.headerText.before}
//         <span className="header-text-2-secondlayer-workshop">
//           {data.headerText.highlight}
//         </span>
//         {data.headerText.after}
//       </Typography>
//       <Box className="box-wrapper-4-thirdlayer-workshop">
//         {data.learningModules.map((item, index) => (
//           <Button key={index} className="box-wrapper-5-thirdlayer-workshop">
//             <Typography className="text-1-thirdlayer-workshop">
//               Module {index + 1}
//             </Typography>
//             <Typography className="text-2-thirdlayer-workshop">
//               {item}
//             </Typography>
//             <Box
//               position="absolute"
//               right="10px"
//               component="img"
//               src={dropDown}
//               alt="Dropdown"
//             ></Box>
//           </Button>
//         ))}
//       </Box>
//       <Button className="btn-thirdLayer-workshop" onClick={handleOpenDialog}>
//         <Box component="img" src={download} alt="Download curriculum" />
//         &nbsp; Download curriculum
//       </Button>

//       {/* Dialog for displaying the curriculum */}
//       <Dialog
//         onClose={handleCloseDialog}
//         open={dialogOpen}
//         PaperProps={{
//           sx: {
//             width: { xs: "100%", md: "40%" },
//             m: { xs: "16px", md: "0px" },
//             borderRadius: "24px",
//             backgroundColor: "#FFF",
//             boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.08)",
//             minHeight: "380px",
//             fontFamily: '"Outfit", sans-serif !important',
//             border: "1px solid #C7C7C7",
//             pb: { xs: "10px", md: "10px" },
//           },
//         }}
//       >
//         <DialogContent sx={{ p: "0" }}>
//           <Curriculum handleCloseDialog={handleCloseDialog} courseContent={data} /> {/* Pass appropriate props */}
//         </DialogContent>
//       </Dialog>
//     </Box>
//   );
// };

// export default ThirdLayer;


import { Box, Typography, Dialog, DialogContent, Button } from "@mui/material";
import "./thirdLayer.scss";
import React, { useState } from "react"; // Import useState for dialog state management
import dropDown from "../../../assets/images/workshop/dropDown.svg";
import download from "../../../assets/images/workshop/Download.svg";
import Curriculum from "../../commonComponents/curriculum/Curriculum"; // Import Curriculum component

const ThirdLayer = ({ data }) => {
  const [dialogOpen, setDialogOpen] = useState(false); // State for controlling dialog visibility

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography className="header-text-1-secondlayer-workshop">
        <span className="header-text-2-secondlayer-workshop">Learn with </span>
        top rated tools
      </Typography>
      <Box className="box-wrapper-1-thirdLayer-workshop" display="flex" flexWrap="wrap" justifyContent="center">
        {data.tools.map((tool, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="box-wrapper-2-thirdLayer-workshop"
            sx={{ margin: '4px', padding: '8px' }} // Increased internal padding
          >
            <Box component="img" src={tool.img} alt={tool.name} />
            <Typography>{tool.name}</Typography>
          </Box>
        ))}
      </Box>

      <Typography width="700px" className="header-text-1-secondlayer-workshop">
        {data.headerText.before}
        <span className="header-text-2-secondlayer-workshop">
          {data.headerText.highlight}
        </span>
        {data.headerText.after}
      </Typography>
      <Box className="box-wrapper-4-thirdlayer-workshop">
        {data.learningModules.map((item, index) => (
          <Button key={index} className="box-wrapper-5-thirdlayer-workshop">
            <Typography className="text-1-thirdlayer-workshop">
              Module {index + 1}
            </Typography>
            <Typography className="text-2-thirdlayer-workshop">
              {item}
            </Typography>
            <Box
              position="absolute"
              right="10px"
              component="img"
              src={dropDown}
              alt="Dropdown"
            />
          </Button>
        ))}
      </Box>
      <Button className="btn-thirdLayer-workshop" onClick={handleOpenDialog}>
        <Box component="img" src={download} alt="Download curriculum" />
        &nbsp; Download curriculum
      </Button>

      {/* Dialog for displaying the curriculum */}
      <Dialog
        onClose={handleCloseDialog}
        open={dialogOpen}
        PaperProps={{
          sx: {
            width: { xs: "100%", md: "40%" },
            m: { xs: "16px", md: "0px" },
            borderRadius: "24px",
            backgroundColor: "#FFF",
            boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.08)",
            minHeight: "380px",
            fontFamily: '"Outfit", sans-serif !important',
            border: "1px solid #C7C7C7",
            pb: { xs: "10px", md: "10px" },
          },
        }}
      >
        <DialogContent sx={{ p: "0" }}>
          <Curriculum handleCloseDialog={handleCloseDialog} courseContent={data} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ThirdLayer;
