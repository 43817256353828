import React from "react";
import Navbar from "../commonComponents/navbar/Navbar";
import Footer from "../commonComponents/footer/Footer";
import TopLayer from "./topLayer/TopLayer";
import SecondLayer from "./secondLayer/SecondLayer";
import ThirdLayer from "./thirdLayer/ThirdLayer";
import LastLayer from "./lastLayer/LastLayer";
import { Box } from "@mui/material";
import completedata from '../../global/workshops';
import { useParams } from "react-router-dom";

const Workshop = () => {
  let { workshopname } = useParams(); // Getting the workshop ID from URL params
  const dataArray = completedata.filter(workshop => workshop["Workshop-name"] === workshopname); // Use the id from URL params

  // Check if dataArray has any elements
  if (dataArray.length === 0) {
    return <div>No workshop found</div>; // Handle case where workshop is not found
  }

  const data = dataArray[0]; // Access the first element

  return (
    <Box>
      <Navbar />
      <TopLayer data={data.toplayer} />
      <SecondLayer data={data.secondLayer} />
      <ThirdLayer data={data.thirdLayer} />
      <LastLayer data={data.lastLayer} />
      <Footer />
    </Box>
  );
};

export default Workshop;
