import { Box, Typography } from "@mui/material";
import AI from "../../../../assets/images/findYourCareer/AI.png";
import VR from "../../../../assets/images/findYourCareer/VR.png";
import leftIcon from "../../../../assets/images/findYourCareer/Lefticon.svg";
import "./BlogsAd.scss";
import { useNavigate } from "react-router-dom";

const BlogsAd = () => {
  const blogContent = [
    {
      title: "Powerful Prompt Engineering",
      image: VR,
    },
    {
      title: "Top UI/UX Trends Of 2024",
      image: AI,
    },
  ];
  const navigate = useNavigate();

  const handleReadMore = (blog) => {
    navigate(`/blogs/${encodeURIComponent(blog)}`);
  };

  return (
    <Box className="blogArticlesAd">
      <Box>
        <Typography className="blogHeader">Blog Articles</Typography>
        {blogContent?.map((item, index) => (
          <Box key={index} className="blogAdWrapper">
            <Box component="img" src={item?.image} />
            <Box>
              <Typography className="title">{item?.title}</Typography>
              <Typography
                className="readBlog"
                onClick={() => handleReadMore(item?.title)}
              >
                Read blog <Box component="img" src={leftIcon} />
              </Typography>
            </Box>
          </Box>
        ))}
        <Typography
          className="viewMore"
          onClick={() => {
            navigate(`/blogs`);
          }}
        >
          View more <Box component="img" src={leftIcon} />
        </Typography>
      </Box>
    </Box>
  );
};

export default BlogsAd;
