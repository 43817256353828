import { Box, Button, Typography } from "@mui/material";
import ambBox from "../../../assets/images/roles/ambBox.png";
import linkedin from "../../../assets/images/roles/linkedin.png";
import money from "../../../assets/images/roles/money.svg";
import briefcase from "../../../assets/images/roles/briefcase.svg";
import line from "../../../assets/images/roles/line.svg";
import img1 from "../../../assets/images/roles/1.svg";
import img2 from "../../../assets/images/roles/2.svg";
import "../roles/Roles.scss";

const Roles = ({ courseContent }) => {
  const data = courseContent?.jobRoles;

  const repeatedData = [...data, ...data];

  return (
    <Box
      className="rolesSection"
      sx={{ p: { xs: "70px 0 35px 0", md: "80px 0 80px 0" } }}
    >
      <Typography
        sx={{ fontSize: { xs: "20px", md: "36px" } }}
        className="headerTitle"
      >
        What <span style={{ color: "#FE7465", fontWeight: "700" }}>roles</span>{" "}
        you will be eligible for?
        
      </Typography>
      <Typography
        sx={{
          width: { md: "55%" },
          fontSize: { xs: "14px", md: "20px" },
          px: { xs: "16px" },
        }}
        className="headerSubtext"
      >
        Our course will help you equip skills for positions like...
      </Typography>
      <Box className="sliderContainer" sx={{ my: { md: "60px", xs: "30px" } }}>
        <Box className="sliderContent">
          {repeatedData?.map((data, index) => (
            <Box key={index} className="rolesCard">
              <Box
              
                src={line}
                component="img"
                sx={{ position: "absolute", top: "18px", left: "12px" }}
              />
              <Typography className="role">{data?.name}</Typography>
              <Box className="subPoints">
                <Box component="img" src={briefcase} />
                <Typography className="openings">
                  Number of openings{" "}
                  <span className="txtHighlight">{data?.openings}</span>
                </Typography>
              </Box>
              <Box className="subPoints">
                <Box component="img" src={money} />
                <Typography className="salary">
                  Average salary{" "}
                  <span className="txtHighlight">{data?.avgSalary}</span>
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Typography
        className="dsLabel"
        sx={{
          fontSize: { xs: "14px", md: "16px" },
          pb: { xs: "12px", md: "20px" },
        }}
      >
        Data sourced from{" "}
      </Typography>
      <Box className="srcWrapper">
        <Button
          className="ambBtn "  
           href={courseContent?.ambitionBoxlinks}
          target="_blank"
          sx={{ flexBasis: { xs: "50%" }, maxWidth: { sm: "192px" } }}
        >
          {" "}
          <Box component="img" src={ambBox}></Box>{" "}
        </Button>
        <Button
          href={courseContent?.linkedinJobRoles}
          target="_blank"
          className="linkBtn "
          sx={{ flexBasis: { xs: "50%" }, maxWidth: { sm: "192px" } }}
        >
          <Box component="img" src={linkedin}></Box>{" "}
        </Button>
      </Box>
      <Box
        component="img"
        src={img1}
        sx={{
          position: "absolute",
          top: "40px",
          left: "30px",
          visibility: { xs: "hidden", md: "visible" },
        }}
      />{" "}
      <Box
        component="img"
        src={img2}
        sx={{
          position: "absolute",
          bottom: "40px",
          right: "50px",
          visibility: { xs: "hidden", md: "visible" },
        }}
      />
    </Box>
  );
};

export default Roles;
