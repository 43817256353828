import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import QRCode from "qrcode";

const VerifyCertificate = () => {
  const location = useLocation();
  const { key } = useParams();
  const [certificateData, setCertificateData] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [isCertificateValid, setIsCertificateValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const cert_id = queryParams.get("id");

    if (cert_id && key) {
      const fetchCertificate = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_LINK}/verify/${key}/?id=${cert_id}`
          );

          if (!response.ok) {
            throw new Error(`Failed to fetch certificate data: ${response.statusText}`);
          }

          const data = await response.json();

          if (data.success && data.data) {
            const { programKey, certificate } = data.data;

            if (key === programKey) {
              setCertificateData(data.data);
              setIsCertificateValid(true);

              const qrLink = `https://coursevita.com/verify/${key}/?id=${certificate._id}`;
              const qrCode = await QRCode.toDataURL(qrLink, { width: 150, margin: 0 });
              setQrCodeUrl(qrCode);
            } else {
              setIsCertificateValid(false);
              setError("Invalid program key provided.");
            }
          } else {
            throw new Error("Certificate not found.");
          }
        } catch (err) {
          console.error("Error fetching certificate data:", err.message);
          setError(err.message);
          setIsCertificateValid(false);
        } finally {
          setLoading(false);
        }
      };

      fetchCertificate();
    } else {
      setError("Invalid URL parameters.");
      setIsCertificateValid(false);
      setLoading(false);
    }
  }, [location, key]);

  if (loading) {
    return <p style={{ textAlign: "center", marginTop: "20px" }}>Loading...</p>;
  }

  if (!isCertificateValid) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px", padding: "10px" }}>
        <h1>Certificate Verification</h1>
        <p style={{ color: "red", marginTop: "10px", fontSize: "1rem" }}>
          {error || "Invalid certificate ID. Please enter correct credentials."}
        </p>
      </div>
    );
  }

  const { fullName, programTitle, certificate } = certificateData;

  return (
    <div style={{ position: "relative", textAlign: "center", padding: "20px" }}>
      <h1 style={{ marginBottom: "20px", fontSize: "1.5rem" }}>Certificate Verification</h1>
      <div
        style={{
          position: "relative",
          maxWidth: "90%",
          width: "620px",
          margin: "0 auto",
          border: "2px solid #333",
          borderRadius: "10px",
          padding: "10px",
          backgroundColor: "#fff",
        }}
      >
        <div style={{ position: "relative" }}>
          {/* Certificate Image */}
          <img
            src="/images/template.svg"
            alt="Certificate Template"
            style={{
              width: "100%",
              display: "block",
              borderRadius: "10px",
            }}
          />
          {/* Overlay Text with Individual Positions and Styles */}
          <p
            style={{
              position: "absolute",
              top: "45.5%",
              left: "10%",
              fontSize: "1.2rem",
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              color: "#000000",
              margin: 0,
            }}
            className="name-text"
          >
            {fullName}
          </p>
          <p
            style={{
              position: "absolute",
              top: "58.4%",
              left: "9.9%",
              fontSize: "0.8rem",
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              color: "#000000",
              margin: 0,
            }}
            className="program-text"
          >
            {programTitle}
          </p>
          <p
            style={{
              position: "absolute",
              top: "79.8%",
              left: "24.8%",
              fontSize: "0.6rem",
              fontWeight: "normal",
              fontFamily: "Montserrat, sans-serif",
              color: "#000000",
              margin: 0,
            }}
            className="cert-id-text"
          >
            {certificate._id}
          </p>
          <p
            style={{
              position: "absolute",
              top: "86.1%",
              left: "24.8%",
              fontSize: "0.6rem",
              fontWeight: "normal",
              fontFamily: "Montserrat, sans-serif",
              color: "#000000",
              margin: 0,
            }}
            className="date-text"
          >
            {new Date(certificate.issue_date).toLocaleDateString()}
          </p>
          {/* QR Code Overlay */}
          {qrCodeUrl && (
            <img
              src={qrCodeUrl}
              alt="QR Code"
              style={{
                position: "absolute",
                top: "75%",
                left: "10%",
                width: "70px",
                height: "70px",
              }}
              className="qr-code"
            />
          )}
        </div>
      </div>

      {/* Responsive Styles */}
      <style>
        {`
          @media (max-width: 768px) {
            .name-text {
              font-size: 1.5rem !important;
            }
            .program-text {
              font-size: 0.8rem !important;
            }
            .cert-id-text {
              font-size: 0.5rem !important;
            }
            .date-text {
              font-size: 0.5rem !important;
            }
            .qr-code {
              width: 70px !important;
              height: 70px !important;
            }
          }

          @media (max-width: 480px) {
            .name-text {
              font-size: 0.6rem !important;
            }
            .program-text {
              font-size: 0.4rem !important;
            }
            .cert-id-text {
              font-size: 0.28rem !important;
            }
            .date-text {
              font-size: 0.28rem !important;
            }
            .qr-code {
              width: 35px !important;
              height: 35px !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default VerifyCertificate;