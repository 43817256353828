export const personaAssesment = [
  {
    id: 1,
    question: "How do you spend your free time?",
    options: {
      A: "Coding projects",
      B: "Creating art",
      C: "Analyzing data",
      D: "Leading groups",
      E: "Business ideas",
    },
  },
  {
    id: 2,
    question: "How do you approach new tasks?",
    options: {
      A: "Technical research",
      B: "Creative solutions",
      C: "Step-by-step analysis",
      D: "Delegating tasks",
      E: "Risk assessment",
    },
  },
  {
    id: 3,
    question: "What work environment suits you?",
    options: {
      A: "Tech-focused",
      B: "Flexible and creative",
      C: "Structured and clear",
      D: "Collaborative and leading",
      E: "Dynamic and fast-paced",
    },
  },
  {
    id: 4,
    question: "How do you solve problems at work?",
    options: {
      A: "Technical skills",
      B: "Innovative ideas",
      C: "Detailed analysis",
      D: "Leading the team",
      E: "Strategic planning",
    },
  },
  {
    id: 5,
    question: "What motivates you?",
    options: {
      A: "Mastering tech skills",
      B: "Creating new ideas",
      C: "Solving complex problems",
      D: "Leading and achieving",
      E: "Building a business",
    },
  },
  {
    id: 6,
    question: "How do you prefer to learn?",
    options: {
      A: "Hands-on practice",
      B: "Creative exploration",
      C: "Detailed reading",
      D: "Leadership workshops",
      E: "Learning by doing",
    },
  },
  {
    id: 7,
    question: "What role do you take in a team?",
    options: {
      A: "Tech expert",
      B: "Idea generator",
      C: "Detail analyst",
      D: "Team leader",
      E: "Project initiator",
    },
  },
  {
    id: 8,
    question: "How do you stay updated?",
    options: {
      A: "Tech blogs",
      B: "Creative workshops",
      C: "Industry reports",
      D: "Networking events",
      E: "Market analysis",
    },
  },
  {
    id: 9,
    question: "Which statement fits you?",
    options: {
      A: "Tech field expert",
      B: "Innovate ideas",
      C: "Solve challenges",
      D: "Inspire teams",
      E: "Start businesses",
    },
  },
];

export const technicalExpert = [
  {
    id: 1,
    question: "How do you handle debugging complex code?",
    options: {
      A: "Confidently (Advanced)",
      B: "With some difficulty (Intermediate)",
      C: "Very challenging (Beginner)",
      D: "Rarely debug (Beginner)",
      E: "I Don't (Beginner)"
    },
  },
  {
    id: 2,
    question: "Which programming task do you find most difficult?",
    options: {
      A: "System architecture (Advanced)",
      B: "Learning new languages (Intermediate)",
      C: "Writing efficient code (Intermediate)",
      D: "Integrating new technologies (Intermediate)",
      E: "Basic programming concepts (Beginner)",
    },
  },
  {
    id: 3,
    question: "How frequently do you engage in coding projects?",
    options: {
      A: "Daily (Advanced)",
      B: "Weekly (Intermediate)",
      C: "Occasionally (Beginner)",
      D: "Rarely (Beginner)",
    },
  },
  {
    id: 4,
    question: "How do you stay updated with new technologies?",
    options: {
      A: "Regularly follow tech news (Advanced)",
      B: "Attend conferences/workshops (Advanced)",
      C: "Occasionally read articles (Intermediate)",
      D: "Rarely keep up (Beginner)",
      E: "Never (Beginner)",
    },
  },
  {
    id: 5,
    question:
      "How comfortable are you with version control systems (e.g., Git)?",
    options: {
      A: "Use it daily (Advanced)",
      B: "Very comfortable (Advanced)",
      C: "Somewhat comfortable (Intermediate)",
      D: "Not comfortable (Beginner)",
      E: "Never used (Beginner)",
    },
  },
];

export const creativeInnovator = [
  {
    id: 1,
    question: "How do you come up with new ideas?",
    options: {
      A: "Regularly (Advanced)",
      B: "Sometimes need a push (Intermediate)",
      C: "I tried, but can't find a Breakthrough (Beginner)",
      D: "Rarely (Beginner)",
      E: "Never (Beginner)",
    },
  },
  {
    id: 2,
    question: "Which creative skill needs improvement?",
    options: {
      A: "Graphic design (Intermediate)",
      B: "Creative writing (Intermediate)",
      C: "UX/UI design (Intermediate)",
      D: "Video production (Beginner)",
      E: "Photography (Beginner)",
    },
  },
  {
    id: 3,
    question: "How comfortable are you with presenting your ideas?",
    options: {
      A: "Very comfortable (Advanced)",
      B: "Confident (Advanced)",
      C: "Somewhat comfortable (Intermediate)",
      D: "Not comfortable (Beginner)",
      E: "Nervous (Beginner)",
    },
  },
  {
    id: 4,
    question: "How often do you work on creative projects?",
    options: {
      A: "Daily (Advanced)",
      B: "Weekly (Intermediate)",
      C: "Occasionally (Beginner)",
      D: "Rarely (Beginner)",
      E: "Only when required (Beginner)",
    },
  },
  {
    id: 5,
    question: "Which design tools are you proficient with?",
    options: {
      A: "Adobe Creative Suite (Advanced)",
      B: "Sketch (Intermediate)",
      C: "Basic design tools (Intermediate)",
      D: "Canva (Beginner)",
      E: "Not proficient (Beginner)",
    },
  },
];

export const analyticalThinker = [
  {
    id: 1,
    question: "How do you manage large data sets?",
    options: {
      A: "Confidently (Advanced)",
      B: "With some effort (Intermediate)",
      C: "Very challenging (Beginner)",
      D: "Rarely handle them (Beginner)",
      E: "I Can't (Naive)",
    },
  },
  {
    id: 2,
    question: "Which data analysis tool is your weakest?",
    options: {
      A: "SQL (Intermediate)",
      B: "Python/R (Intermediate)",
      C: "SPSS (Intermediate)",
      D: "Excel (Beginner)",
      E: "Tableau (Beginner)",
    },
  },
  {
    id: 3,
    question: "How often do you conduct data analysis?",
    options: {
      A: "Daily (Advanced)",
      B: "Weekly (Intermediate)",
      C: "Occasionally (Beginner)",
      D: "Rarely (Beginner)",
      E: "Only when required (Beginner)",
    },
  },
  {
    id: 4,
    question: "How comfortable are you with statistical methods?",
    options: {
      A: "Very comfortable (Advanced)",
      B: "Somewhat comfortable (Intermediate)",
      C: "Need a refresher (Intermediate)",
      D: "Not comfortable (Beginner)",
      E: "Never used them (Beginner)",
    },
  },
  {
    id: 5,
    question: "How do you approach data visualization?",
    options: {
      A: "Easily create charts and graphs (Advanced)",
      B: "Use advanced tools (Advanced)",
      C: "Sometimes need help (Intermediate)",
      D: "Use basic tools (Beginner)",
      E: "Struggle with it (Beginner)",
    },
  },
];

export const teamLeader = [
  {
    id: 1,
    question: "How do you handle team conflicts?",
    options: {
      A: "Easily (Advanced)",
      B: "Need support (Intermediate)",
      C: "Sometimes tricky (Intermediate)",
      D: "Very challenging (Beginner)",
      E: "Avoid conflicts (Beginner)",
    },
  },
  {
    id: 2,
    question: "Which leadership skill needs improvement?",
    options: {
      A: "Decision making (Intermediate)",
      B: "Communication (Intermediate)",
      C: "Motivating team (Intermediate)",
      D: "Project management (Beginner)",
      E: "Strategic planning (Beginner)",
    },
  },
  {
    id: 3,
    question: "How often do you lead projects?",
    options: {
      A: "Always (Advanced)",
      B: "Sometimes (Intermediate)",
      C: "Only when assigned (Intermediate)",
      D: "Rarely (Beginner)",
      E: "Never (Beginner)",
    },
  },
  {
    id: 4,
    question: "How do you motivate your team?",
    options: {
      A: "Very effectively (Advanced)",
      B: "Sometimes challenging (Intermediate)",
      C: "Need new strategies (Intermediate)",
      D: "Struggle with it (Beginner)",
      E: "Never tried (Beginner)",
    },
  },
  {
    id: 5,
    question: "How comfortable are you with strategic planning?",
    options: {
      A: "Very comfortable (Advanced)",
      B: "Somewhat comfortable (Intermediate)",
      C: "Need more practice (Intermediate)",
      D: "Not comfortable (Beginner)",
      E: "Never done it (Beginner)",
    },
  },
];

export const entrepreneur = [
  {
    id: 1,
    question: "How do you handle business planning?",
    options: {
      A: "Confidently (Advanced)",
      B: "With some effort (Intermediate)",
      C: "Need more practice (Intermediate)",
      D: "Never done it (Beginner)",
      E: "Very challenging (Beginner)",
    },
  },
  {
    id: 2,
    question: "Which entrepreneurial skill needs improvement?",
    options: {
      A: "Risk management (Intermediate)",
      B: "Business strategy (Intermediate)",
      C: "Innovation (Intermediate)",
      D: "Financial planning (Beginner)",
      E: "Market analysis (Beginner)",
    },
  },
  {
    id: 3,
    question: "How often do you start new projects?",
    options: {
      A: "Frequently (Advanced)",
      B: "Occasionally (Intermediate)",
      C: "When needed (Intermediate)",
      D: "Rarely (Beginner)",
      E: "Never (Beginner)",
    },
  },
  {
    id: 4,
    question: "How do you handle market analysis?",
    options: {
      A: "Very effectively (Advanced)",
      B: "With some effort (Intermediate)",
      C: "Need training (Intermediate)",
      D: "Struggle with it (Beginner)",
      E: "Rarely do it (Beginner)",
    },
  },
  {
    id: 5,
    question: "How comfortable are you with financial planning?",
    options: {
      A: "Very comfortable (Advanced)",
      B: "Somewhat comfortable (Intermediate)",
      C: "Need more practice (Intermediate)",
      D: "Not comfortable (Beginner)",
      E: "Never done it (Beginner)",
    },
  },
];
