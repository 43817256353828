import React from 'react';
import { Box, Checkbox, FormControl, ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';
import downArrow from "../../../assets/images/findYourCareer/downArrow.svg"
import "./FilterDropDown.scss"

const FilterDropDown = ({ content, handleItemsSelection, selectedOptions }) => {
    const options = content?.options || [];
    const isAllSelected = options.length > 0 && selectedOptions?.length === options.length;

    const handleValue = (e) => {
        const value = e.target.value;
        let targetOptions = [];
        if (value.includes("all")) {
            targetOptions = isAllSelected ? [] : options;
        } else {
            targetOptions = value;
        }
        handleItemsSelection({ fieldId: content.fieldId, options: targetOptions });
    };

    return (
        <Box className="filterDropdown">
            <FormControl className='dropDownWrapper'>
                <Select
                    id="multiple-checkbox"
                    displayEmpty
                    multiple
                    value={selectedOptions || []}
                    onChange={handleValue}
                    MenuProps={{
                        PaperProps: {

                            sx: {
                                maxHeight: "300px !important", 
                                overflowY: 'auto',
                                borderRadius: "14px",
                                border: "1px solid #F6F6F6",
                                padding: 1,
                                '&::-webkit-scrollbar': {
                                    borderRadius: "12px",
                                    width: '7px',
                                    borderLeft: "1px solid #EBEBEB",
                                    borderRight: "1px solid #EBEBEB"
                                },

                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#9B9B9B',
                                    borderRadius: "4px",
                                }, '& .MuiTypography-root': {
                                    fontSize: '12px !important',
                                    fontFamily: "'Outfit',sans-serif !important",
                                    fontWeight:"400px"

                                },
                            },
                        },
                    }}
                    sx={{
                        '& .MuiSelect-icon': {
                            color: 'transparent',
                            backgroundImage: `url(${downArrow})`,
                        },


                    }}
                    renderValue={(selected) => {
                        return (
                            <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray', maxWidth: "220px" }}>
                                <Box component="img" src={content.icon} pr="4px" />
                                {content.label}
                            </Box>
                        );
                    }}

                >

                    {options.length > 0 && <MenuItem value="all">
                        <ListItemIcon>
                            <Checkbox checked={isAllSelected} sx={{
                                    color: '#B0B0B0',
                                    padding: "0px !important",
                                    '&.Mui-checked': {
                                        color: '#7234F7',
                                    },
                                }}/>
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                    </MenuItem>}
                    {options.map((item, index) => (
                        <MenuItem key={index} value={item}>
                            <ListItemIcon>
                                <Checkbox sx={{
                                    color: '#B0B0B0',
                                    padding: "0px !important",
                                    '&.Mui-checked': {
                                        color: '#7234F7',
                                    },
                                }}
                                checked={selectedOptions?.includes(item)}
                                />
                            </ListItemIcon>
                            <ListItemText primary={item} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>       
        </Box>
    );
};

export default FilterDropDown;