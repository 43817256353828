

import RefundPolicy from "./RefundPolicy";
import Footer from "../commonComponents/footer/Footer";
import Navbar from "../commonComponents/navbar/Navbar";
import { Helmet } from "react-helmet";
const RefundPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Refund Policy</title>
        <meta name="description" content="Refund Policy" />
        <meta name="keywords" content="refund, policy" />
      </Helmet>
      <Navbar/>
      <RefundPolicy/>
      <Footer/>
    </>
  );
};

export default RefundPolicyPage;