import neha from "./neha.jpg";
import nikanksha from "./nikansha.jpg";
import revanth from "./revanth.JPG";
import Ejaz from "./Ejaz.jpg";
import linkedin from "./linkedin.svg";
import defaultImage from "./default.jpg";
import Narsimha from "./Narsimha.png"
import ojha from "./ojha.jpeg";
import Pmm from "./PMmentor.JPG"
import mabbu from "./mohan.jpg"
const mentorImages = {
  "neha": neha,
  "nikanksha": nikanksha,
  "revanth": revanth,
  "Ejaz": Ejaz,
  "linkedin": linkedin,
  "narsimha": Narsimha,
  "ojha": ojha,
  "deepika" : Pmm,
  "default": defaultImage,
  "mohan" : mabbu
  
};

export default mentorImages;
