import React from "react";
import Navbar from "../commonComponents/navbar/Navbar.jsx";
import Footer from "../commonComponents/footer/Footer.jsx";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import MainElement from "./main/maincontent.jsx";

const ProjectElementPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>Projects</title>
        <meta name="description" content="Projects" />
        <meta name="keywords" content="projects" />
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#F3F4FD",
        }}
      >
        <Navbar />
        <MainElement />
        <Footer />
      </Box>
    </>
  );
};

export default ProjectElementPage;
