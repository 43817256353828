
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BlogPosts from "./blogPosts/BlogPosts";
import StartLearning from "./startLearning/StartLearning";
import Footer from "../commonComponents/footer/Footer";
import Navbar from "../commonComponents/navbar/Navbar";
import Helmet from "react-helmet";


const BlogsPage = (props) => {
  const { pathname } = useLocation();
  const {coursesContent, blogsContent} = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>Blogs Page</title>
        <meta name="description" content="Read our latest blog posts and start learning today." />
        <meta name="keywords" content="blogs, learning, courses, education" />
      </Helmet>
       <Navbar/>
       <BlogPosts coursesContent={coursesContent} blogsContent={blogsContent}/>
       <StartLearning/>
       <Footer/>
    </>
  );
};

export default BlogsPage;
