import "../coursesSection/CoursesSection.scss";
import { Typography, Box } from "@mui/material";
import CourseCard from "./courseCard/CourseCard";

const CoursesSection = ({coursesContent}) => {
  return (
    <>
      <Box
        className="coursesSection" id="courses"
        sx={{ p: { md: "33px 60px 71px 60px", xs: "20px 16px 20px 16px" } }} >
        <Box className="headerSection" sx={{mb:{md:"48px",xs:"30px"}}}>
          <Typography className="headText">Explore</Typography>
          <Typography className="subText">
            Our future ready <span style={{ fontWeight: "700" }}> courses !</span>
          </Typography>
        </Box>
        <Box className="levelsWrapper">
          <Box className="cardsWrapper">
            <Box className="courseCards">
              {coursesContent?.map((courseContent, index) => (
                <CourseCard key={index} courseContent={courseContent} />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CoursesSection;