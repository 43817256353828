import React, { useState } from "react";
import {
  Container,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
} from "@mui/material";

function PersonaAssessment() {
  const [answers, setAnswers] = useState({
    freeTime: "",
    newTasks: "",
    workEnvironment: "",
    problemSolving: "",
    motivation: "",
    learningPreference: "",
    teamRole: "",
    stayUpdated: "",
    statement: "",
  });

  function assignPersona(combination) {
    const countA = combination.filter(answer => answer === 'A').length;
    const countB = combination.filter(answer => answer === 'B').length;
    const countC = combination.filter(answer => answer === 'C').length;
    const countD = combination.filter(answer => answer === 'D').length;
    const countE = combination.filter(answer => answer === 'E').length;

    // Assign persona based on the highest count
    const counts = [
        { count: countA, persona: "Technical Expert" },
        { count: countB, persona: "Creative Innovator" },
        { count: countC, persona: "Analytical Thinker" },
        { count: countD, persona: "Team Leader" },
        { count: countE, persona: "Entrepreneur" }
    ];

    // Sort counts in descending order
    counts.sort((a, b) => b.count - a.count);

    // Return the persona with the highest count
    return counts[0].persona;
}

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const options = Object.values(answers); 
    const results = assignPersona(options)
    console.log(results);
  };

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Persona Assessment
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">
                How do you spend your free time?
              </FormLabel>
              <RadioGroup
                name="freeTime"
                value={answers.freeTime}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Coding projects"
                />
                <FormControlLabel
                  value="B"
                  control={<Radio />}
                  label="Creating art"
                />
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Analyzing data"
                />
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Leading groups"
                />
                <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Business ideas"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">
                How do you approach new tasks?
              </FormLabel>
              <RadioGroup
                name="newTasks"
                value={answers.newTasks}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Technical research"
                />
                <FormControlLabel
                  value="B"
                  control={<Radio />}
                  label="Creative solutions"
                />
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Step-by-step analysis"
                />
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Delegating tasks"
                />
                <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Risk assessment"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">
                What work environment suits you?
              </FormLabel>
              <RadioGroup
                name="workEnvironment"
                value={answers.workEnvironment}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Tech-focused"
                />
                <FormControlLabel
                  value="B"
                  control={<Radio />}
                  label="Flexible and creative"
                />
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Structured and clear"
                />
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Collaborative and leading"
                />
                <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Dynamic and fast-paced"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">
                How do you solve problems at work?
              </FormLabel>
              <RadioGroup
                name="problemSolving"
                value={answers.problemSolving}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Technical skills"
                />
                <FormControlLabel
                  value="B"
                  control={<Radio />}
                  label="Innovative ideas"
                />
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Detailed analysis"
                />
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Leading the team"
                />
                <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Strategic planning"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">What motivates you?</FormLabel>
              <RadioGroup
                name="motivation"
                value={answers.motivation}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Mastering tech skills"
                />
                <FormControlLabel
                  value="B"
                  control={<Radio />}
                  label="Creating new ideas"
                />
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Solving complex problems"
                />
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Leading and achieving"
                />
                <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Building a business"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">
                How do you prefer to learn?
              </FormLabel>
              <RadioGroup
                name="learningPreference"
                value={answers.learningPreference}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Hands-on practice"
                />
                <FormControlLabel
                  value="B"
                  control={<Radio />}
                  label="Creative exploration"
                />
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Detailed reading"
                />
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Leadership workshops"
                />
                <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Learning by doing"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">
                What role do you take in a team?
              </FormLabel>
              <RadioGroup
                name="teamRole"
                value={answers.teamRole}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Tech expert"
                />
                <FormControlLabel
                  value="B"
                  control={<Radio />}
                  label="Idea generator"
                />
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Detail analyst"
                />
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Team leader"
                />
                <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Project initiator"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">How do you stay updated?</FormLabel>
              <RadioGroup
                name="stayUpdated"
                value={answers.stayUpdated}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Tech blogs"
                />
                <FormControlLabel
                  value="B"
                  control={<Radio />}
                  label="Creative workshops"
                />
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Industry reports"
                />
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Networking events"
                />
                <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Market analysis"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">
                Which statement fits you?
              </FormLabel>
              <RadioGroup
                name="statement"
                value={answers.statement}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label="Tech field expert"
                />
                <FormControlLabel
                  value="B"
                  control={<Radio />}
                  label="Innovate ideas"
                />
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Solve challenges"
                />
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Inspire teams"
                />
                <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Start businesses"
                />
              </RadioGroup>
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}

export default PersonaAssessment;
