import {
    Box,
    Button,
    TextField,
    Typography,
  } from "@mui/material";
  import Cancel from "../../../assets/images/coursePreview/Cancel.svg";
  import React, { useRef, useState } from "react";
  import "./JoinWaitlist.scss";
  import axios from "axios";
  import ReCAPTCHA from "react-google-recaptcha";
  
  function WaitlistForm(props) {
    const { handleCloseDialog, courseContent } = props;
    const recaptchaRef = useRef();
  
    const [name, setName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [mobileNum, setMobileNum] = useState("");
    const [message, setMessage] = useState("");
  
    const validation = () => {
      const mobileNumRegex = /^\d{10}$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!name) {
        setMessage("Please enter Full name");
      } else if (!emailId || !emailRegex.test(emailId)) {
        setMessage("Please enter valid Email Id");
      } else if (!mobileNum || mobileNum.length !== 10 || !mobileNumRegex.test(mobileNum)) {
        setMessage("Please enter valid mobile number");
      } else {
        setMessage("");
        handleJoinWaitlist();
      }
    };
  
    const handleJoinWaitlist = async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_LINK}/api/joinWaitlist`,
          {
            fullName: name,
            emailId: emailId,
            number: mobileNum,
            course: courseContent.course,
            captchaToken: recaptchaRef.current.getValue(),
          }
        );
        setName("");
        setEmailId("");
        setMobileNum("");
        handleCloseDialog();
      } catch (error) {
        console.error("Error joining waitlist:", error.response?.data?.message || error.message);
        setMessage(error.response?.data.message);
      }
    };
  
    return (
      <Box sx={{ width: "100%" }} className="waitlistFormWrapper">
        <Box
          className="waitlistFormContent"
          sx={{
            borderRadius: { md: "25px 25px 0px 0px", xs: "12px" },
          }}
        >
          <Box
            component="img"
            src={Cancel}
            sx={{ p: "16px 16px 0px", cursor: "pointer" }}
            onClick={handleCloseDialog}
          />
          <Box
            className="contentform"
            sx={{ p: { xs: "0 16px", md: "0 48px 32px" }, mt: "-10px" }}
          >
            <Typography className="waitlistHeader" sx={{ mb: "4px" }}>
              Join the waitlist today!
            </Typography>
            <Typography className="waitlistSubHeader">
              Please fill out the form to join the waitlist.
            </Typography>
  
            <Box className="formInput">
              <Typography className="formInputHeader">Full Name*</Typography>
              <TextField
                id="outlined-basic"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                className="formText"
              />
            </Box>
  
            <Box className="formInput">
              <Typography className="formInputHeader">Email ID*</Typography>
              <TextField
                id="outlined-basic"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                variant="outlined"
                fullWidth
                className="formText"
              />
            </Box>
            <Box className="formInput">
              <Typography className="formInputHeader">Mobile Number*</Typography>
              <TextField
                id="outlined-basic"
                value={mobileNum}
                onChange={(e) => setMobileNum(e.target.value)}
                variant="outlined"
                className="formText"
              />
            </Box>
            {message && (
              <Typography className="freeTagline" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
  
            <Box paddingTop="10px">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                ref={recaptchaRef}
              />
            </Box>
  
            <Button
              variant="contained"
              className="contactButton"
              onClick={validation}
            >
              <span style={{ color: "white" }}>Join Waitlist</span>
            </Button>
            <Typography className="tagline">
              By filling this form, you agree to our{" "}
              <a
                className="terms"
                href="/terms"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/terms";
                }}
              >
                Terms and conditions
              </a>
              {" "}and you agree to receive communication from us.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
  
  export default WaitlistForm;
  