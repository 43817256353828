import { Box } from "@mui/material";
import search from "../../../assets/images/findYourCareer/search.svg"
import "./SearchBar.scss"
const SearchBar = (props) => {
    const element = document.getElementById("courseSearchBar");
    element?.addEventListener("clear-all", () => {
        element.value = "";
    });

    return (
        <Box className="inputWrapper" sx={{p:{xs:"16px", md:"8px 20px"}, height:{xs:"37px", sm:"49px"}}}>
            <input autoComplete="off" id="courseSearchBar" placeholder={props.placeholder} onKeyDown={props.handleKeyDown} value={props.input} onFocus={props.onFocus} onChange={(e)=>props.handleChange(e.target.value)} />
            <Box component="img" style={{cursor: "pointer"}} src={search} onClick={props.handleSearch} />
        </Box>
    )
}

export default SearchBar;