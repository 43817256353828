import Tableau from "./Tableau.svg";
import PowerBI from "./PowerBI.svg";
import Matplotlib from "./Matplotlib.svg";
import Seaborn from "./Seaborn.svg";
import SQL from "./SQL.svg";
import Python from "./Python.svg";
import Pandas from "./Pandas.svg";
import NumPy from "./NumPy.svg";
import Scikitlearn from "./ScikitLearn.svg";
import TensorFlow from "./TensorFlow.svg";
import powerquery from "./powerquery.svg";
import excel from "./excel.svg";
import lookerStudio from "./lookerstudio.svg";
import HTML from "./HTML.svg";
import CSS from "./CSS.svg";
import JavaScript from "./JavaScript.svg";
import reactImage from "./reactImage.svg";
import nodejs from "./nodejs.svg";
import mongoDb from "./mongoDb.svg";
import git from "./git.svg";
import mlFlow from "./mlflow.svg";
import kubeflow from "./Kubeflow-01.svg";
import kubernates from "./kubernetes-icon.svg";
import docker from "./Docker.svg";
import apacheAirflow from "./apacheAirflow-removebg-preview.png";
import seldon from "./seldon-removebg-preview.png";
import dvc from "./dvc-removebg-preview.png";
import prometheus from "./prometheus-removebg-preview.png";
import grafana from "./Grafana-removebg-preview.png";
import tensorflowExtended from "./tensorFLowExtended-removebg-preview.png";
import AWSEC2 from "./AWSEC2.svg";
import AWSLambda from "./Lambda.svg";
import AWSS3 from "./AWSS3.svg";
import AWSCloudFormation from "./CloudFormation.svg";
import Ansible from "./ansible.svg";
import Jenkins from "./jenkins.svg";
import Chef from "./chef.svg";
import AWSECS from "./AWSEC2.svg";
import GoogleAnalytics from "./Google-Analytics-Logo.png";
import SEMrush from "./semrush logo.png";
import FacebookAds from "./facebook logo.png";
import GoogleAds from "./Google-Ads.png";
import Keras from "./Keras.svg";
import Sagemaker from "./Sagemaker.svg";
import jupypter from "./jupyternotebook.png";
import jup from "./Juppyy.png"
import aws_codedeploy from "./aws_codedeploy.svg";
import MSExcel from "./MS Excel.png"
import Chatgpt from "./Chatpgt.jpg"
import Gemini from "./Gemini.png"
import AWSIAM from "./AWSIAM.jpg"
import AWSVPC from "./AWSVPC.webp"
import Terraform from "./Terraform.png"
import AWSRDS from "./AWS RDS.png"
import AWSEBS from "./AWS EBS.webp"
const ToolsImages = {
  Tableau: Tableau,
  "Power BI": PowerBI,
  Matplotlib: Matplotlib,
  Seaborn: Seaborn,
  SQL: SQL,
  Python: Python,
  Pandas: Pandas,
  NumPy: NumPy,
  "Scikit-learn": Scikitlearn,
  TensorFlow: TensorFlow,
  "Looker Studio": lookerStudio,
  "Power Query": powerquery,
  Excel: excel,
  HTML: HTML,
  CSS: CSS,
  JavaScript: JavaScript,
  React: reactImage,
  "Node.js": nodejs,
  MongoDB: mongoDb,
  Git: git,
  Docker: docker,
  Kubernetes: kubernates,
  Kubeflow: kubeflow,
  MLflow: mlFlow,
  "TensorFlow Extended": tensorflowExtended,
  "Apache Airflow": apacheAirflow,
  Seldon: seldon,
  DVC: dvc,
  Prometheus: prometheus,
  Grafana: grafana,
  "AWS EC2": AWSEC2,
  "AWS S3": AWSS3,
  "Cloud Formation": AWSCloudFormation,
  Ansible: Ansible,
  Chef: Chef,
  Jenkins: Jenkins,
  AWSECS: AWSECS,
  "AWS Lambda": AWSLambda,
  "AWS codedeploy": aws_codedeploy,
  "Google Analytics": GoogleAnalytics,
  SEMrush: SEMrush,
  "Facebook Ads": FacebookAds,
  "Google Ads": GoogleAds,
  Keras: Keras,
  "Jupyter": jup,
  "AWS SageMaker": Sagemaker,
  "MS Excel" : MSExcel,
  "ChatGPT" : Chatgpt,
  "Gemini" : Gemini,
  "AWS IAM" : AWSIAM,
  "AWS VPC" : AWSVPC,
  "Terraform": Terraform,
  "AWS RDS" : AWSRDS,
  "AWS EBS" : AWSEBS
};

export default ToolsImages;
